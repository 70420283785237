.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__s7ZdL {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__cukEz {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__pzulR {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox__fciS1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__aYlTk {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__buMjl {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg___5X8Rf {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__gqwJx {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  height: 1em;
  flex-shrink: 0;
}
.svg__ftp1Y {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg___1Ir55 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__tYNp {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg___9Gby {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__mn1Ij {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__pzGlv {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox__a2MNk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__a2MNk > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__a2MNk > :global(.__wab_flex-container) > *,
.freeBox__a2MNk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__a2MNk > :global(.__wab_flex-container) > picture > img,
.freeBox__a2MNk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.setupDetailsText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.accountDetailsText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__e1DBa {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___5Fz4R {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__gh8Qq {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__xkkz6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.text__hXv5Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 573px;
  font-size: 22px;
  margin-top: 40px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  margin-right: 0px;
  text-decoration-line: none;
  min-width: 0;
}
.text__ov1Ja {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 573px;
  font-size: 17px;
  margin-top: 40px;
  font-family: "Inter", sans-serif;
  color: #090909;
  line-height: 2;
  font-weight: 500;
  margin-right: 0px;
  padding-bottom: 0px;
  min-width: 0;
}
.ol {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-left: 30px;
  margin-bottom: 20px;
}
.text__pdmtf {
  position: relative;
  width: auto;
  height: auto;
  max-width: 573px;
  font-size: 16px;
  margin-top: 40px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 600;
  margin-right: 0px;
  margin-bottom: 20px;
}
.controllerStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
  margin: 0px 0px 20px;
}
.controllerStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.controllerStack > :global(.__wab_flex-container) > *,
.controllerStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.controllerStack > :global(.__wab_flex-container) > picture > img,
.controllerStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.controllerCard:global(.__wab_instance) {
  margin-top: calc(0px + 20px) !important;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.addNew {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 187px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 0px;
  cursor: pointer;
  margin-bottom: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.addNew > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 13px);
  width: calc(100% + 13px);
}
.addNew > :global(.__wab_flex-container) > *,
.addNew > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.addNew > :global(.__wab_flex-container) > picture > img,
.addNew
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 13px;
}
.root .addNew:hover {
  background: #3555ffd4;
}
.root .addNew:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text___3Hfm2 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
  margin-right: 0px;
}
.text__oKhpu {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.addControllerStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  overflow: visible;
  margin-top: -35px;
  margin-bottom: 0px;
  min-width: 0;
  padding: 0px 0px 0px 10px;
}
.freeBox__geGWi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__xlYok {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text__m2ErW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__gitG6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text__dzY90 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__bE6W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__bfxfl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.selectControllerType:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__wZtPo:global(.__wab_instance) {
  position: relative;
}
.text__pQyNr {
  padding-bottom: 0px;
}
.option__xLmMw:global(.__wab_instance) {
  position: relative;
}
.option__gx0Lk:global(.__wab_instance) {
  position: relative;
}
.deleteStack {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 800px;
  left: auto;
  top: 18px;
  right: 50px;
  color: #3555ff;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.freeBox__uuPsE {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__dsWlk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.jobTitle {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__fbv01 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__r2LqB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.phoneNumber {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__j7UL5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__qGvbC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.email {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__gww7G {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__tl81 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.countrySelect:global(.__wab_instance) {
  max-width: 100%;
  width: 462px;
  margin-top: 0px;
  height: 41px;
  flex-shrink: 0;
}
.option__pwgrc:global(.__wab_instance) {
  position: relative;
}
.text___9FjUr {
  padding-bottom: 0px;
}
.option__rtq4P:global(.__wab_instance) {
  position: relative;
}
.option__srAj7:global(.__wab_instance) {
  position: relative;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__xbJre:global(.__wab_instance) {
  position: relative;
}
.option__fcax9:global(.__wab_instance) {
  position: relative;
}
.option__rfsu7:global(.__wab_instance) {
  position: relative;
}
.option__aXq89:global(.__wab_instance) {
  position: relative;
}
.option__fzfpX:global(.__wab_instance) {
  position: relative;
}
.option__mSaA:global(.__wab_instance) {
  position: relative;
}
.option___8Q0F8:global(.__wab_instance) {
  position: relative;
}
.option__lQTm:global(.__wab_instance) {
  position: relative;
}
.option__d5JLc:global(.__wab_instance) {
  position: relative;
}
.option__iQsZe:global(.__wab_instance) {
  position: relative;
}
.option__iGR5:global(.__wab_instance) {
  position: relative;
}
.option__atAc1:global(.__wab_instance) {
  position: relative;
}
.option__fGeXi:global(.__wab_instance) {
  position: relative;
}
.option__nfXkH:global(.__wab_instance) {
  position: relative;
}
.option__t0Slj:global(.__wab_instance) {
  position: relative;
}
.option__yU1B:global(.__wab_instance) {
  position: relative;
}
.option__cQiPv:global(.__wab_instance) {
  position: relative;
}
.option__tmZo:global(.__wab_instance) {
  position: relative;
}
.option___4CfU:global(.__wab_instance) {
  position: relative;
}
.option__sdzQf:global(.__wab_instance) {
  position: relative;
}
.option__ldH6V:global(.__wab_instance) {
  position: relative;
}
.option__jDxrb:global(.__wab_instance) {
  position: relative;
}
.option__kef8W:global(.__wab_instance) {
  position: relative;
}
.option__amhG7:global(.__wab_instance) {
  position: relative;
}
.option__hSaal:global(.__wab_instance) {
  position: relative;
}
.option__xJbhH:global(.__wab_instance) {
  position: relative;
}
.option__ljRtD:global(.__wab_instance) {
  position: relative;
}
.option__aisjJ:global(.__wab_instance) {
  position: relative;
}
.option__cuymN:global(.__wab_instance) {
  position: relative;
}
.option__yhWy0:global(.__wab_instance) {
  position: relative;
}
.option__tuYmx:global(.__wab_instance) {
  position: relative;
}
.option__ri5Kb:global(.__wab_instance) {
  position: relative;
}
.option___3HUbH:global(.__wab_instance) {
  position: relative;
}
.option__mh4Al:global(.__wab_instance) {
  position: relative;
}
.option__ncpL6:global(.__wab_instance) {
  position: relative;
}
.option__biRvR:global(.__wab_instance) {
  position: relative;
}
.option__k4Clw:global(.__wab_instance) {
  position: relative;
}
.option__rc8BK:global(.__wab_instance) {
  position: relative;
}
.option__jtXwg:global(.__wab_instance) {
  position: relative;
}
.option__aUml7:global(.__wab_instance) {
  position: relative;
}
.option__oboYo:global(.__wab_instance) {
  position: relative;
}
.option__tpEmm:global(.__wab_instance) {
  position: relative;
}
.option__mOt3L:global(.__wab_instance) {
  position: relative;
}
.option__mezaA:global(.__wab_instance) {
  position: relative;
}
.option__giXdg:global(.__wab_instance) {
  position: relative;
}
.option__i8Zkf:global(.__wab_instance) {
  position: relative;
}
.option__ppWis:global(.__wab_instance) {
  position: relative;
}
.option__terwk:global(.__wab_instance) {
  position: relative;
}
.option__wuU0O:global(.__wab_instance) {
  position: relative;
}
.option__sHbWy:global(.__wab_instance) {
  position: relative;
}
.option__x8Wh7:global(.__wab_instance) {
  position: relative;
}
.option___1T2Of:global(.__wab_instance) {
  position: relative;
}
.option__hJjW3:global(.__wab_instance) {
  position: relative;
}
.option__dFxF:global(.__wab_instance) {
  position: relative;
}
.option__aOxKu:global(.__wab_instance) {
  position: relative;
}
.option__nTHei:global(.__wab_instance) {
  position: relative;
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__ry9Sl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___2Np2Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ownership {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__vcWhl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__glSs4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.ssnStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___9FzDs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.passportStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__uMFcz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.passport {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.controllerStackError {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  color: #ff0000;
}
@media (max-width: 1370px) {
  .controllerStackError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.ageError {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ageError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.ssnError {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ssnError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.submitButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 187px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 51px;
  cursor: pointer;
  margin-bottom: 54px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .submitButton:hover {
  background: #3555ffd4;
}
.root .submitButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__jp247 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.text__todaj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 573px;
  font-size: 22px;
  margin-top: 50px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  margin-right: 0px;
  text-decoration-line: none;
  min-width: 0;
}
.certificationText {
  position: relative;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  max-width: 573px;
}
.slotTargetCertificationText {
  font-size: 17px;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.text__zI6P8 {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  margin: 33px 0px 5px 4px;
}
.signature {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 261px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  font-family: "Inter", sans-serif;
  background: #b51b1b00;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.text__uG2BI {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  margin: 33px 0px 5px 4px;
}
.currentDate {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 261px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.errorText {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  margin-top: 35px;
  margin-bottom: -30px;
  margin-left: 0px;
  padding: 0px;
}
@media (max-width: 1370px) {
  .errorText {
    position: relative;
    align-self: flex-start;
    margin-top: 35px;
    margin-bottom: -30px;
    margin-left: 0px;
  }
}
.errorSlot1 {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1370px) {
  .errorSlot1 {
    width: auto;
    height: auto;
    max-width: 800px;
  }
}
.slotTargetErrorSlot {
  font-size: 15px;
  color: #ff0000;
}
@media (max-width: 1370px) {
  .slotTargetErrorSlot {
    font-size: 13.5px;
  }
}
.text__m2UMv {
  margin-top: 0px;
  color: #ff0000;
  font-size: 15px;
}
.continueButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  margin: 49px 0px 50px;
}
