.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #f6f7fb;
  min-width: 0;
  min-height: 0;
  padding: 0px;
  margin: 0%;
}
.vertStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 1%);
  height: calc(100% + 1%);
}
.vertStack > :global(.__wab_flex-container) > *,
.vertStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.vertStack > :global(.__wab_flex-container) > picture > img,
.vertStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 1%;
}
.billingDetailsStack {
  box-shadow: inset 0px 0px 1px 1px #cecfd8;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 701px;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  margin-top: calc(80px + 1%) !important;
  margin-right: 0%;
  margin-bottom: 0px;
  margin-left: 0%;
  max-width: none;
  border-radius: 8px;
}
.billingDetailsStack > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.billingDetailsStack > :global(.__wab_flex-container) > *,
.billingDetailsStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.billingDetailsStack > :global(.__wab_flex-container) > picture > img,
.billingDetailsStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .billingDetailsStack {
    left: auto;
    top: auto;
    width: 48vw;
    margin-top: calc(80px + 1%) !important;
  }
}
.freeBox__fxOVk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 78px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__fxOVk {
    height: 5vw;
    flex-shrink: 0;
  }
}
.title9 {
  display: block;
  font-size: 21px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title9 {
    font-size: 1.43vw;
  }
}
.freeBox___2CnZp {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 162px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  margin-top: calc(0px + 0px) !important;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border-top: 0px solid #d3d3d8;
  border-bottom: 0px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox___2CnZp {
    padding-top: 0.6vw;
    margin-top: calc(0.6vw + 0px) !important;
    height: 11vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .freeBox___2CnZp {
    margin-top: calc(0.6vw + 0px) !important;
  }
}
.freeBox__gWtxd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 204px;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 0px;
  margin-bottom: 0%;
  margin-left: 60px;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__gWtxd {
    top: auto;
    left: auto;
    margin-left: 4.1vw;
    width: 14vw;
    flex-shrink: 0;
  }
}
.title13 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.5;
  color: #222222;
  width: 204px;
  height: auto;
  margin-bottom: 16px;
}
@media (max-width: 1440px) {
  .title13 {
    font-size: 1.35vw;
    margin-bottom: 1.1vw;
    width: 100%;
    min-width: 0;
  }
}
.freeBox___9XwYy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 204px;
  height: auto;
}
@media (max-width: 1440px) {
  .freeBox___9XwYy {
    width: 100%;
    min-width: 0;
  }
}
.slotTargetBankName {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: #000000;
  line-height: 1.5;
  letter-spacing: 0.3px;
}
@media (max-width: 1440px) {
  .slotTargetBankName {
    font-size: 1.35vw;
  }
}
.linkBank {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 43px;
  max-width: 100%;
  background: #14377f;
  cursor: pointer;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 222px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .linkBank {
    width: 12vw;
    height: 3vw;
    margin-left: 14.5vw;
    flex-shrink: 0;
  }
}
.root .linkBank:hover {
  background: #1b459ed4;
}
.root .linkBank:active {
  background: #1b459ed4;
  border: 1px solid #000000;
}
.text___9MbGn {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
}
@media (max-width: 1440px) {
  .text___9MbGn {
    font-size: 1.15vw;
  }
}
.inviteUsersStack {
  box-shadow: inset 0px 0px 1px 1px #c7c7c7;
  background: #ffffff;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 701px;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  margin-top: calc(75px + 1%) !important;
  border-radius: 8px;
  padding: 0px;
  border: 1px solid #f4f4f4;
}
@media (max-width: 1440px) {
  .inviteUsersStack {
    width: 48vw;
    margin-top: calc(75px + 1%) !important;
  }
}
@media (max-width: 1370px) {
  .inviteUsersStack {
    width: 48vw;
    margin-top: calc(75px + 1%) !important;
  }
}
.freeBox__zUsDj {
  display: flex;
  position: relative;
  width: 100%;
  height: 75px;
  max-width: 100%;
  margin-bottom: 22px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #dcdcdc;
}
@media (max-width: 1370px) {
  .freeBox__zUsDj {
    height: 5.5vw;
    flex-shrink: 0;
  }
}
.text__gYp0R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
}
.inviteUsersHeading {
  text-align: center;
  font-size: 21px;
  font-weight: 700;
}
@media (max-width: 1440px) {
  .inviteUsersHeading {
    font-size: 1.45vw;
  }
}
.freeBox___8Xvx5 {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 20px 8px 0px;
  border-bottom: 0px solid #cbc9c9;
}
.inviteUsersBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 43px;
  max-width: 100%;
  background: #14377f;
  cursor: pointer;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 340px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .inviteUsersBtn {
    margin-left: 23.7vw;
    width: 12vw;
    height: 3vw;
    left: auto;
    top: auto;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .inviteUsersBtn {
    margin-left: 21vw;
  }
}
.root .inviteUsersBtn:hover {
  background: #1b459ed4;
  left: auto;
  top: auto;
}
.root .inviteUsersBtn:active {
  background: #1b459ed4;
  left: auto;
  top: auto;
  border: 1px solid #000000;
}
.text__n2K6O {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding-top: 0px;
}
@media (max-width: 1440px) {
  .text__n2K6O {
    font-size: 1.15vw;
  }
}
.text__gUo {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 60px;
  color: #222222;
  font-weight: 600;
  font-size: 19px;
}
.h6__ly4Iv {
  width: auto;
  font-size: 19px;
  color: #222222;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .h6__ly4Iv {
    font-size: 1.35vw;
  }
}
.freeBox__aZJvG {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 0px 15px;
}
.userListStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 0px;
}
.inviteUserCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.text__wyH33 {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
@media (max-width: 1370px) {
  .text__wyH33 {
    font-size: 1.04vw;
  }
}
.paymentOptionsContainer {
  box-shadow: inset 0px 0px 1px 1px #c7c7c7;
  background: #ffffff;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 701px;
  height: auto;
  max-width: 100%;
  margin-bottom: 80px;
  margin-top: calc(75px + 1%) !important;
  border-radius: 8px;
  padding: 0px;
  border: 1px solid #f4f4f4;
}
@media (max-width: 1440px) {
  .paymentOptionsContainer {
    width: 48vw;
    margin-top: calc(75px + 1%) !important;
  }
}
@media (max-width: 1370px) {
  .paymentOptionsContainer {
    width: 48vw;
    margin-top: calc(75px + 1%) !important;
  }
}
.freeBox__ofuIr {
  display: flex;
  position: relative;
  width: 100%;
  height: 75px;
  max-width: 100%;
  margin-bottom: 22px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #dcdcdc;
}
@media (max-width: 1370px) {
  .freeBox__ofuIr {
    height: 5.5vw;
    flex-shrink: 0;
  }
}
.text__i61Ji {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
}
.inviteUsersHeading2 {
  text-align: center;
  font-size: 21px;
  font-weight: 700;
}
@media (max-width: 1440px) {
  .inviteUsersHeading2 {
    font-size: 1.45vw;
  }
}
.freeBox__qgyVm {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 20px 8px 0px;
  border-bottom: 0px solid #cbc9c9;
}
.freeBox__lvdd {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox___89NAn {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__jvx0L {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.h6__r20L {
  width: auto;
  font-size: 19px;
  color: #222222;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .h6__r20L {
    font-size: 1.35vw;
  }
}
.numberInput:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-left: 14px;
}
.freeBox__aMe86 {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  min-width: 0;
  padding: 8px;
}
.h6___0G6If {
  width: auto;
  font-size: 19px;
  color: #222222;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .h6___0G6If {
    font-size: 1.35vw;
  }
}
.numberInput2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-left: 14px;
}
.freeBox__kN2JO {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  min-width: 0;
  padding: 8px;
}
.button:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lqxWl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__n9LBu {
  height: auto;
}
.svg__n7P2E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__o0BBn {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 60px;
  color: #222222;
  font-weight: 600;
  font-size: 19px;
}
