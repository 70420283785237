.card-modal{
    width: 565.5px;
    max-width: 565px !important;
}

@media screen and (max-width: 480px) {
    .card-modal{
        width: 96.69vw;
        max-width: 96.69vw !important;
    }
  }


      .select {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 16px;
        /* margin-left:23px;
        margin-bottom:38px; */
        gap: 10px;
        width: 100%;
        height: 45px;

/* W Background */

    background: #FFFFFF;
    border: 1px solid #cccccc00;
    border-radius: 8px 8px 0px 0px;

/* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    

        padding-top:0.5rem;

       

        font-family: "Inter";
        font-size: 14.8px;
        font-weight: 500;
        color: #565656;
        /** for the dropdown indicator */
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1em;
    }

    .select2 {
        border-radius: 10px !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 16px;
        /* margin-left:23px;
        margin-bottom:38px; */
        gap: 10px;
        width: 100%;
        height: 41px;

/* W Background */

    background: #FFFFFF;
    border: 1px solid #cccccc00;
    border-radius: 0px 0px 8px 8px;

/* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    

    padding-top:0.5rem;

       

        font-family: "Inter";
        font-size: 14.8px;
        font-weight: 500;
        color: #565656;
        /** for the dropdown indicator */
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1em;
    }
    

.select-option {
    font-family: "Inter";
    font-size: 14.8px;
    font-weight: 500;
    color: #565656;
}

body {
    font-family: sans-serif;
}

label[for=iframesCardExpiration], label[for=iframesCardCvv] {
    display: block;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 24px;
    color: #595959;
    margin-bottom: 8px;
    margin-top: 12px;
}

label[for=iframesCardNumber] {
    display: block;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 24px;
    color: #595959;
    margin-bottom: 8px;
    margin-top: 25px;
}

#ss-banner {
    background: transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-shield@2x.png?raw=true) no-repeat left center;
    height: 40px;
    background-size: 280px 34px;
    margin-bottom: 7px;
}

user agent stylesheet
div {
    display: block;
}

.form-row {
  margin-top: 10px;
}

.form-wrapper {
  display: block;
  width: 300px;
  margin: 10px auto;
}

.form-wrapper2 {
    visibility: hidden;
    height: 500px;
    width: 300px;
    margin: 10px auto;
  }

  .form-wrapper3 {
    visibility: hidden;
    height: 0px;
    width: 0px;
    margin: 0px auto;
  }

.spinnermodal2 {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: white; */
    /* z-index: 1; */
    height: 500px;
    width: 300px;
    margin: 10px auto;
}
  

.zip {

    font-family: sans-serif;
    box-sizing: border-box;
    display: block;
    height: 50px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: rgb(70, 69, 69);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    width: 100%;
    border-radius: 5px;
    font-size: 15.7px;
    font-weight: 540;
    font-family: sans-serif;
    margin: 0 .5em 0 0;
    letter-spacing: 2.5px;

}