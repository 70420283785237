.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__ox1A8 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__gLqd {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__romIh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox__uy7Jy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__gmliW {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__iZs5G {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__eg8Yb {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__mOur7 {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__aFqjp {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__fNfb8 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__wzB72 {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__ePcF {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__yy2Kn {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__vPHl7 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__cmo6R {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox__hS8FI {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__hS8FI > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__hS8FI > :global(.__wab_flex-container) > *,
.freeBox__hS8FI > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hS8FI > :global(.__wab_flex-container) > picture > img,
.freeBox__hS8FI
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__q8O7 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__al45N {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__kgA7N {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__bo8W {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___4UFhh {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px 0px 0px 20px;
}
.text__iynmg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-top: 41px;
  color: #000000;
  font-size: 25px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.addBankButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 29px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .addBankButton:hover {
  background: #3555ffd4;
}
.root .addBankButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__fn4R {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.manualAdd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 50px;
  min-width: 0;
  padding: 0px;
}
.text__zNoRg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  color: #000000;
  font-size: 25px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.text__kYjHk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 40px 0px 6px 4px;
}
.accountNum {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.text___6Qu61 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 40px 0px 6px 4px;
}
.routingNum {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.text__nLr7M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 40px 0px 6px 4px;
}
.bankName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.text__sznQk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 40px 0px 6px 4px;
}
.accountType:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__zkeYt:global(.__wab_instance) {
  position: relative;
}
.option__i3Z0V:global(.__wab_instance) {
  position: relative;
}
.option__fXll2:global(.__wab_instance) {
  position: relative;
}
.option__p0IoK:global(.__wab_instance) {
  position: relative;
}
.option__b0Qie:global(.__wab_instance) {
  position: relative;
}
.manualSubmit {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 29px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .manualSubmit:hover {
  background: #3555ffd4;
}
.root .manualSubmit:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text___1Dydu {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
