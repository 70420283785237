.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 700px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.freeBox___5Tlba {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 700px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0px;
  padding-top: 0px;
}
.closeButton {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-weight: 600;
  color: #048b68;
  font-size: 20px;
  margin-right: 0px;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 8px;
}
@media (max-width: 1370px) {
  .closeButton {
    font-size: 18px;
    height: auto;
    line-height: 1;
  }
}
.freeBox__c1JDh {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 700px;
  height: auto;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
  padding-top: 0px;
}
.closeButton2 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 690px;
  font-weight: 500;
  color: #000000;
  font-size: 19.1px;
  cursor: default;
  padding-top: 0px;
  pointer-events: none;
  text-align: center;
  line-height: 1.7;
  padding-bottom: 30px;
  margin: 2px 0px 0px;
}
@media (max-width: 1370px) {
  .closeButton2 {
    font-size: 18px;
    height: auto;
    line-height: 1;
  }
}
