.root {
  background: #3c161600;
  width: 100%;
  height: 4.5vw;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  max-width: none;
  position: relative;
  min-width: 0;
  border-radius: 0px;
  border-top: 1px solid #dddddd;
  border-bottom: 0px solid #dddddd;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 0px;
}
@media (max-width: 1440px) {
  .root {
    height: 5.5vw;
  }
}
@media (max-width: 1370px) {
  .root {
    height: 5.3vw;
  }
}
.freeBox__n132B {
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  width: 4.6vw;
  margin-right: 0px;
  padding-right: 0px;
  flex-direction: row;
  margin-left: calc(9% + 0px) !important;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .freeBox__n132B {
    margin-left: calc(10% + 0px) !important;
  }
}
.slotTargetAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 0.85vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__ff6Au {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 6.35vw;
  height: auto;
  max-width: 100%;
  margin-left: calc(6.3% + 0px) !important;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__ff6Au {
    margin-left: calc(6.4% + 0px) !important;
  }
}
.slotTargetClient {
  font-family: "Open Sans", sans-serif;
  font-size: 0.85vw;
  font-weight: 600;
  color: #4d4d4d;
  line-height: 1.5;
  letter-spacing: 0.3px;
  text-align: center;
}
@media (max-width: 1440px) {
  .slotTargetClient {
    text-align: center;
    line-height: 2;
  }
}
.statusStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 4.1vw;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-left: calc(6.3% + 0px) !important;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .statusStack {
    margin-left: calc(6.5% + 0px) !important;
  }
}
.dashboardInProgressStatusCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.freeBox__odFF {
  display: flex;
  position: relative;
  height: auto;
  margin-left: calc(7.8% + 0px) !important;
  width: 4vw;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .freeBox__odFF {
    margin-left: calc(7.9% + 0px) !important;
  }
}
.slotTargetDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.85vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
