.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 123px;
  max-width: none;
  background: #44c0ff00;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 0.7%);
  width: calc(100% + 0.7%);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 0.7%;
}
.title6 {
  display: block;
  font-size: 1.2vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  align-self: center;
  margin-left: calc(0px + 0.7%) !important;
  margin-right: 3%;
  width: auto;
  padding-right: 0px;
}
.createNewStack:global(.__wab_instance) {
  position: relative;
}
.settingsButton {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #383434;
  width: 2vw;
  height: 1.4vw;
  display: block;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-top: 0px;
  margin-right: 1vw;
  margin-bottom: 0px;
  margin-left: calc(1vw + 0.7%) !important;
  cursor: pointer;
  flex-shrink: 0;
}
.root .settingsButton:hover {
  color: #595757;
  margin-left: calc(1vw + 0.7%) !important;
}
.svg___4Qtyp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #ababab;
  width: auto;
  height: 1.9vw;
  display: block;
  align-self: center;
  left: auto;
  top: auto;
}
.name {
  display: flex;
  position: relative;
  top: auto;
  width: auto;
  height: auto;
  align-self: center;
  margin-left: calc(0px + 0.7%) !important;
  left: auto;
  justify-content: flex-end;
  flex-direction: row;
}
.slotTargetUserNameText {
  font-family: "Open Sans", sans-serif;
  font-size: 0.87vw;
  font-weight: 600;
  color: rgba(36, 38, 51, 1);
  text-align: right;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.profileStackInstance:global(.__wab_instance) {
  position: relative;
  align-self: center;
  width: auto;
  margin-left: calc(1vw + 0.7%) !important;
  margin-right: 1vw;
}
.freeBox__cY2Ob {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 123px;
  max-width: none;
  background: #44c0ff00;
  position: relative;
  min-width: 0;
  padding: 8px 8px 0px;
}
.freeBox__cY2Ob > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 1%);
  width: calc(100% + 1%);
}
.freeBox__cY2Ob > :global(.__wab_flex-container) > *,
.freeBox__cY2Ob > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cY2Ob > :global(.__wab_flex-container) > picture > img,
.freeBox__cY2Ob
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 1%;
}
.title7 {
  display: block;
  font-size: 150%;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  align-self: center;
  margin-left: calc(0px + 1%) !important;
  margin-right: 3%;
  width: auto;
  padding-right: 0px;
}
.createNew__cPgGu:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  width: 13%;
  height: 35%;
  align-self: center;
  margin-left: calc(25% + 1%) !important;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  max-width: none;
  max-height: none;
}
.svg__f5XH {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #383434;
  width: 5%;
  height: 20%;
  display: block;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: calc(0px + 1%) !important;
}
.svg__yhciD {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #ababab;
  width: auto;
  height: 30%;
  display: block;
  align-self: center;
  left: auto;
  top: auto;
}
.name2 {
  display: block;
  font-size: 87.5%;
  text-align: right;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  width: auto;
  height: auto;
  align-self: center;
  margin-left: calc(0px + 1%) !important;
  left: auto;
}
.svg__vFzmo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #383434;
  transform: translate(0px, 0.001px);
  width: 5%;
  height: 20%;
  display: block;
  top: auto;
  left: auto;
  align-self: center;
}
.freeBox__ikLx8 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 123px;
  max-width: none;
  background: #44c0ff00;
  position: relative;
  min-width: 0;
  padding: 8px 8px 0px;
}
.freeBox__ikLx8 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 1%);
  width: calc(100% + 1%);
}
.freeBox__ikLx8 > :global(.__wab_flex-container) > *,
.freeBox__ikLx8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ikLx8 > :global(.__wab_flex-container) > picture > img,
.freeBox__ikLx8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 1%;
}
.title8 {
  display: block;
  font-size: 150%;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  align-self: center;
  margin-left: calc(0px + 1%) !important;
  margin-right: 3%;
  width: auto;
  padding-right: 0px;
}
.createNew__qtMql:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  width: 13%;
  height: 35%;
  align-self: center;
  margin-left: calc(25% + 1%) !important;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  max-width: none;
  max-height: none;
}
.svg__bRh8J {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #383434;
  width: 5%;
  height: 20%;
  display: block;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: calc(0px + 1%) !important;
}
.svg__sWij4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #ababab;
  width: auto;
  height: 30%;
  display: block;
  align-self: center;
  left: auto;
  top: auto;
}
.name3 {
  display: block;
  font-size: 87.5%;
  text-align: right;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  width: auto;
  height: auto;
  align-self: center;
  margin-left: calc(0px + 1%) !important;
  left: auto;
}
.svg__mwnS {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #383434;
  transform: translate(0px, 0.001px);
  width: 5%;
  height: 20%;
  display: block;
  top: auto;
  left: auto;
  align-self: center;
}
.freeBox__pl3Iy {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 123px;
  max-width: none;
  background: #44c0ff00;
  position: relative;
  min-width: 0;
  padding: 8px 8px 0px;
}
.freeBox__pl3Iy > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 1%);
  width: calc(100% + 1%);
}
.freeBox__pl3Iy > :global(.__wab_flex-container) > *,
.freeBox__pl3Iy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pl3Iy > :global(.__wab_flex-container) > picture > img,
.freeBox__pl3Iy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 1%;
}
.title9 {
  display: block;
  font-size: 150%;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  align-self: center;
  margin-left: calc(0px + 1%) !important;
  margin-right: 3%;
  width: auto;
  padding-right: 0px;
}
.createNew__bSHr:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  width: 13%;
  height: 35%;
  align-self: center;
  margin-left: calc(25% + 1%) !important;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  max-width: none;
  max-height: none;
}
.svg__wLd3B {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #383434;
  width: 5%;
  height: 20%;
  display: block;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: calc(0px + 1%) !important;
}
.svg__rQxwo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #ababab;
  width: auto;
  height: 30%;
  display: block;
  align-self: center;
  left: auto;
  top: auto;
}
.name4 {
  display: block;
  font-size: 87.5%;
  text-align: right;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  width: auto;
  height: auto;
  align-self: center;
  margin-left: calc(0px + 1%) !important;
  left: auto;
}
.svg__nRGna {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #383434;
  transform: translate(0px, 0.001px);
  width: 5%;
  height: 20%;
  display: block;
  top: auto;
  left: auto;
  align-self: center;
}
