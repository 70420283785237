.root {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: 0px;
}
.freeBox__pkxZ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.freeBox__qR02E {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .freeBox__qR02E {
    margin-bottom: 1vw;
  }
}
.contactNameText {
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
}
@media (max-width: 1440px) {
  .contactNameText {
    font-size: 1.08vw;
    padding-left: 0.1vw;
  }
}
.deleteField {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #14377f;
  font-weight: 700;
  margin-left: 21px;
  padding-right: 0px;
  font-size: 19px;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .deleteField {
    font-size: 1.15vw;
    margin-left: 1.5vw;
  }
}
.contactName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .contactName {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.contactNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .contactNameError {
    font-size: 1vw;
    margin-left: 0vw;
  }
}
@media (max-width: 1370px) {
  .contactNameError {
    font-size: 1vw;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 6px 0px -5px 0vw;
  }
}
.freeBox__i9U5Q {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 40px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__i9U5Q {
    margin-top: 2.8vw;
    margin-left: 0vw;
  }
}
.contactPhoneText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .contactPhoneText {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.contactPhone {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .contactPhone {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.contactPhoneError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .contactPhoneError {
    font-size: 1vw;
    margin-left: 0vw;
  }
}
@media (max-width: 1370px) {
  .contactPhoneError {
    font-size: 1vw;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 6px 0px -5px 0vw;
  }
}
.freeBox__avJm6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__avJm6 {
    margin-top: 2.8vw;
    margin-left: 0px;
    margin-bottom: 0px;
  }
}
.contactEmailText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .contactEmailText {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.contactEmail {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .contactEmail {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.contactEmailError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .contactEmailError {
    font-size: 1vw;
    margin-left: 0vw;
  }
}
@media (max-width: 1370px) {
  .contactEmailError {
    font-size: 1vw;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 6px 0px -5px 0vw;
  }
}
