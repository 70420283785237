@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Open+Sans%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800&family=Mulish%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Roboto%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C700%3B0%2C900&family=Poppins%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&display=swap");

.plasmic_tokens {
  --token-YDIAfzfKi1OS: 0ch;
  --plasmic-token-unnamed-style-token: var(--token-YDIAfzfKi1OS);
  --token-SS0Q4BjSVwgS: 1px;
  --plasmic-token-unnamed-style-token-2: var(--token-SS0Q4BjSVwgS);
  --token-3xbozxDzeph2: 90%;
  --plasmic-token-unnamed-style-token-3: var(--token-3xbozxDzeph2);
  --token-ooHeA2CNPoMZ: var(--token-0Dg6rloWtBCj);
  --plasmic-token-unnamed-style-token-4: var(--token-ooHeA2CNPoMZ);
  --token-0Dg6rloWtBCj: var(--token-EYVrY3XmdWy4);
  --plasmic-token-unnamed-style-token-5: var(--token-0Dg6rloWtBCj);
  --token-EYVrY3XmdWy4: 80%;
  --plasmic-token-80-token: var(--token-EYVrY3XmdWy4);
  --token-r4oXLtilE_Yy: #ffffff;
  --plasmic-token-basic-container-background: var(--token-r4oXLtilE_Yy);
  --token-6U09tBzORWKv: #030712;
  --plasmic-token-basic-text-primary: var(--token-6U09tBzORWKv);
  --token-jlucSCmuk_Y3: Inter;
  --plasmic-token-sans-serif: var(--token-jlucSCmuk_Y3);
  --token-KUJvqD_juXaN: 1rem;
  --plasmic-token-font-md: var(--token-KUJvqD_juXaN);
  --token-AhdK5MxgHZd1: 1.25;
  --plasmic-token-line-height-md: var(--token-AhdK5MxgHZd1);
  --token-fgOINatiJmwa: 1rem;
  --plasmic-token-size-16: var(--token-fgOINatiJmwa);
  --token--Dc0hf6tL-ab: #0a0a0a80;
  --plasmic-token-basic-overlay-background: var(--token--Dc0hf6tL-ab);
  --token-8IZU2WyWRDiW: 1.5rem;
  --plasmic-token-size-24: var(--token-8IZU2WyWRDiW);
  --token-jIwaN1jurL3H: 1.25rem;
  --plasmic-token-font-lg: var(--token-jIwaN1jurL3H);
  --token-VzjP0RzB0V9K: 0.75rem;
  --plasmic-token-size-12: var(--token-VzjP0RzB0V9K);
  --token-iqrtZ1Zw3PRI: #2563eb;
  --plasmic-token-brand-brand: var(--token-iqrtZ1Zw3PRI);
  --token-9ARFNjMemt3K: 0.5rem;
  --plasmic-token-size-8: var(--token-9ARFNjMemt3K);
  --token-Pelm9MuYeuZJ: 0.375rem;
  --plasmic-token-size-6: var(--token-Pelm9MuYeuZJ);
  --token-mlrnuoh5-AJV: #374151;
  --plasmic-token-neutral-neutral: var(--token-mlrnuoh5-AJV);
  --token-OKFbidTTaogU: #dc2626;
  --plasmic-token-destructive-destructive: var(--token-OKFbidTTaogU);
  --token-x2qOt8_V3ZGe: #6b7280;
  --plasmic-token-muted-muted: var(--token-x2qOt8_V3ZGe);
  --token-Hmf4XmFmeWO-: 0.25rem;
  --plasmic-token-size-4: var(--token-Hmf4XmFmeWO-);
  --token-PI_bNnsC8VHs: 0.75rem;
  --plasmic-token-font-sm: var(--token-PI_bNnsC8VHs);
  --token-kzFAdkc4Ej8O: 1;
  --plasmic-token-line-height-sm: var(--token-kzFAdkc4Ej8O);
  --token-go_3u8jWTmgS: 1.4;
  --plasmic-token-line-height-lg: var(--token-go_3u8jWTmgS);
  --token-Nb1pwm6Jb0Sl: 0.125rem;
  --plasmic-token-size-2: var(--token-Nb1pwm6Jb0Sl);
  --token-hb00GaJjmxD9: #16a34a;
  --plasmic-token-success-success: var(--token-hb00GaJjmxD9);
  --token-IncRUybxdYu0: #facc15;
  --plasmic-token-warning-warning: var(--token-IncRUybxdYu0);
  --token-JFpN3ZDuef9k: #dbeafe;
  --plasmic-token-brand-brand-soft: var(--token-JFpN3ZDuef9k);
  --token-PXdM1MEQPufV: #e5e7eb;
  --plasmic-token-neutral-neutral-soft: var(--token-PXdM1MEQPufV);
  --token-7gawMWWFc43z: #f3f4f6;
  --plasmic-token-muted-muted-soft: var(--token-7gawMWWFc43z);
  --token-ebNK-dZpUS3w: #dcfce7;
  --plasmic-token-success-success-soft: var(--token-ebNK-dZpUS3w);
  --token-8m1MstsRxdd7: #fef9c3;
  --plasmic-token-warning-warning-soft: var(--token-8m1MstsRxdd7);
  --token-6XZWtnchU6Bh: #fee2e2;
  --plasmic-token-destructive-destructive-soft: var(--token-6XZWtnchU6Bh);
  --token-zBNh0zL2kuwn: #93c5fd;
  --plasmic-token-brand-brand-border: var(--token-zBNh0zL2kuwn);
  --token-wrZFsJEMbsXV: #d1d5db;
  --plasmic-token-neutral-neutral-border: var(--token-wrZFsJEMbsXV);
  --token-X7nEUnxWeynv: #e5e7eb;
  --plasmic-token-muted-muted-border: var(--token-X7nEUnxWeynv);
  --token-L4ThuMS4hNO8: #86efac;
  --plasmic-token-success-success-border: var(--token-L4ThuMS4hNO8);
  --token-1RgFhEoRyTy9: #fde047;
  --plasmic-token-warning-warning-border: var(--token-1RgFhEoRyTy9);
  --token-wGj5SGZsAO58: #fca5a5;
  --plasmic-token-destructive-destructive-border: var(--token-wGj5SGZsAO58);
  --token-7VF6DuYQVTnW: #0000001a;
  --plasmic-token-interaction-hovered: var(--token-7VF6DuYQVTnW);
  --token-O5SY4mzVXBQC: #ffffff40;
  --plasmic-token-interaction-disabled: var(--token-O5SY4mzVXBQC);
  --token-wm9xNlyQwU2m: #00000033;
  --plasmic-token-interaction-pressed: var(--token-wm9xNlyQwU2m);
  --token-XHuJ67M3Gx2N: 0.625rem;
  --plasmic-token-size-10: var(--token-XHuJ67M3Gx2N);
  --token-xC4UuBZzherB: #eff6ff;
  --plasmic-token-brand-brand-foreground: var(--token-xC4UuBZzherB);
  --token-RyeXhyYOxStK: #f9fafb;
  --plasmic-token-neutral-neutral-foreground: var(--token-RyeXhyYOxStK);
  --token-XCOVr-T041sv: #e5e7eb;
  --plasmic-token-muted-muted-foreground: var(--token-XCOVr-T041sv);
  --token-sj7SuaXFQTK0: #1e3a8a;
  --plasmic-token-brand-brand-soft-foreground: var(--token-sj7SuaXFQTK0);
  --token-u5thKtjeDelP: #422006;
  --plasmic-token-warning-warning-foreground: var(--token-u5thKtjeDelP);
  --token-xJ2mgi0o3Acf: #f0fdf4;
  --plasmic-token-success-success-foreground: var(--token-xJ2mgi0o3Acf);
  --token-wB-Mu3wGJq9S: #fef2f2;
  --plasmic-token-destructive-destructive-foreground: var(--token-wB-Mu3wGJq9S);
  --token-GLbcBoCggIcc: #111827;
  --plasmic-token-neutral-neutral-soft-foreground: var(--token-GLbcBoCggIcc);
  --token-ylPyp-o_ON2I: #6b7280;
  --plasmic-token-muted-muted-soft-foreground: var(--token-ylPyp-o_ON2I);
  --token-nj9hr_ZxzPQG: #14532d;
  --plasmic-token-success-success-soft-foreground: var(--token-nj9hr_ZxzPQG);
  --token-DVM1YuPOXG9D: #713f12;
  --plasmic-token-warning-warning-soft-foreground: var(--token-DVM1YuPOXG9D);
  --token-RqFXg3fXTiaq: #7f1d1d;
  --plasmic-token-destructive-destructive-soft-foreground: var(
    --token-RqFXg3fXTiaq
  );
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-jmxGQfIn6UoU_font-family: "Inter", sans-serif;
  --mixin-jmxGQfIn6UoU_font-size: 16px;
  --mixin-jmxGQfIn6UoU_font-weight: 400;
  --mixin-jmxGQfIn6UoU_font-style: normal;
  --mixin-jmxGQfIn6UoU_color: #535353;
  --mixin-jmxGQfIn6UoU_text-align: left;
  --mixin-jmxGQfIn6UoU_text-transform: none;
  --mixin-jmxGQfIn6UoU_line-height: 1.5;
  --mixin-jmxGQfIn6UoU_letter-spacing: normal;
  --mixin-jmxGQfIn6UoU_white-space: pre-wrap;
  --mixin-jmxGQfIn6UoU_user-select: text;
  --mixin-jmxGQfIn6UoU_text-decoration-line: none;
  --mixin-jmxGQfIn6UoU_text-overflow: clip;
  --mixin-snFNtHm7jnQK_font-family: "Inter", sans-serif;
  --mixin-snFNtHm7jnQK_color: #000000;
  --mixin-snFNtHm7jnQK_font-size: 72px;
  --mixin-snFNtHm7jnQK_font-weight: 900;
  --mixin-snFNtHm7jnQK_letter-spacing: -4px;
  --mixin-snFNtHm7jnQK_line-height: 1;
  --mixin-snFNtHm7jnQK_white-space: pre-wrap;
  --mixin-t1wddhn-Gcb8_font-family: "Inter", sans-serif;
  --mixin-t1wddhn-Gcb8_color: #000000;
  --mixin-t1wddhn-Gcb8_font-size: 48px;
  --mixin-t1wddhn-Gcb8_font-weight: 700;
  --mixin-t1wddhn-Gcb8_letter-spacing: -1px;
  --mixin-t1wddhn-Gcb8_line-height: 1.1;
  --mixin-t1wddhn-Gcb8_white-space: pre-wrap;
  --mixin-U9O5H56W7EZ9_color: #0070f3;
  --mixin-U9O5H56W7EZ9_white-space: pre-wrap;
  --mixin-_fKhYjQUwzsD_font-family: "Inter", sans-serif;
  --mixin-_fKhYjQUwzsD_color: #000000;
  --mixin-_fKhYjQUwzsD_font-size: 32px;
  --mixin-_fKhYjQUwzsD_font-weight: 600;
  --mixin-_fKhYjQUwzsD_letter-spacing: -0.8px;
  --mixin-_fKhYjQUwzsD_line-height: 1.2;
  --mixin-_fKhYjQUwzsD_white-space: pre-wrap;
  --mixin-mXIOoREV7zZa_font-family: "Inter", sans-serif;
  --mixin-mXIOoREV7zZa_color: #000000;
  --mixin-mXIOoREV7zZa_font-size: 24px;
  --mixin-mXIOoREV7zZa_font-weight: 600;
  --mixin-mXIOoREV7zZa_letter-spacing: -0.5px;
  --mixin-mXIOoREV7zZa_line-height: 1.3;
  --mixin-mXIOoREV7zZa_white-space: pre-wrap;
  --mixin-oR0w94PGuQo6_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-oR0w94PGuQo6_border-bottom-color: #dddddd;
  --mixin-oR0w94PGuQo6_border-bottom-style: solid;
  --mixin-oR0w94PGuQo6_border-bottom-width: 1px;
  --mixin-oR0w94PGuQo6_border-left-color: #dddddd;
  --mixin-oR0w94PGuQo6_border-left-style: solid;
  --mixin-oR0w94PGuQo6_border-left-width: 1px;
  --mixin-oR0w94PGuQo6_border-right-color: #dddddd;
  --mixin-oR0w94PGuQo6_border-right-style: solid;
  --mixin-oR0w94PGuQo6_border-right-width: 1px;
  --mixin-oR0w94PGuQo6_border-top-color: #dddddd;
  --mixin-oR0w94PGuQo6_border-top-style: solid;
  --mixin-oR0w94PGuQo6_border-top-width: 1px;
  --mixin-oR0w94PGuQo6_border-bottom-left-radius: 3px;
  --mixin-oR0w94PGuQo6_border-bottom-right-radius: 3px;
  --mixin-oR0w94PGuQo6_border-top-left-radius: 3px;
  --mixin-oR0w94PGuQo6_border-top-right-radius: 3px;
  --mixin-oR0w94PGuQo6_font-family: "Inconsolata";
  --mixin-oR0w94PGuQo6_padding-bottom: 1px;
  --mixin-oR0w94PGuQo6_padding-left: 4px;
  --mixin-oR0w94PGuQo6_padding-right: 4px;
  --mixin-oR0w94PGuQo6_padding-top: 1px;
  --mixin-oR0w94PGuQo6_white-space: pre-wrap;
  --mixin-b4QQj5ITfRjT_border-left-color: #dddddd;
  --mixin-b4QQj5ITfRjT_border-left-style: solid;
  --mixin-b4QQj5ITfRjT_border-left-width: 3px;
  --mixin-b4QQj5ITfRjT_color: #888888;
  --mixin-b4QQj5ITfRjT_padding-left: 10px;
  --mixin-b4QQj5ITfRjT_white-space: pre-wrap;
  --mixin-tx0aJ-6oxrIs_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-tx0aJ-6oxrIs_border-bottom-color: #dddddd;
  --mixin-tx0aJ-6oxrIs_border-bottom-style: solid;
  --mixin-tx0aJ-6oxrIs_border-bottom-width: 1px;
  --mixin-tx0aJ-6oxrIs_border-left-color: #dddddd;
  --mixin-tx0aJ-6oxrIs_border-left-style: solid;
  --mixin-tx0aJ-6oxrIs_border-left-width: 1px;
  --mixin-tx0aJ-6oxrIs_border-right-color: #dddddd;
  --mixin-tx0aJ-6oxrIs_border-right-style: solid;
  --mixin-tx0aJ-6oxrIs_border-right-width: 1px;
  --mixin-tx0aJ-6oxrIs_border-top-color: #dddddd;
  --mixin-tx0aJ-6oxrIs_border-top-style: solid;
  --mixin-tx0aJ-6oxrIs_border-top-width: 1px;
  --mixin-tx0aJ-6oxrIs_border-bottom-left-radius: 3px;
  --mixin-tx0aJ-6oxrIs_border-bottom-right-radius: 3px;
  --mixin-tx0aJ-6oxrIs_border-top-left-radius: 3px;
  --mixin-tx0aJ-6oxrIs_border-top-right-radius: 3px;
  --mixin-tx0aJ-6oxrIs_font-family: "Inconsolata";
  --mixin-tx0aJ-6oxrIs_padding-bottom: 3px;
  --mixin-tx0aJ-6oxrIs_padding-left: 6px;
  --mixin-tx0aJ-6oxrIs_padding-right: 6px;
  --mixin-tx0aJ-6oxrIs_padding-top: 3px;
  --mixin-tx0aJ-6oxrIs_white-space: pre-wrap;
  --mixin-fAMlkiehYfCO_display: flex;
  --mixin-fAMlkiehYfCO_flex-direction: column;
  --mixin-fAMlkiehYfCO_align-items: stretch;
  --mixin-fAMlkiehYfCO_justify-content: flex-start;
  --mixin-fAMlkiehYfCO_list-style-position: outside;
  --mixin-fAMlkiehYfCO_padding-left: 40px;
  --mixin-fAMlkiehYfCO_position: relative;
  --mixin-fAMlkiehYfCO_list-style-type: disc;
  --mixin-fAMlkiehYfCO_white-space: pre-wrap;
  --mixin-kcqGefOCNbYc_display: flex;
  --mixin-kcqGefOCNbYc_flex-direction: column;
  --mixin-kcqGefOCNbYc_align-items: stretch;
  --mixin-kcqGefOCNbYc_justify-content: flex-start;
  --mixin-kcqGefOCNbYc_list-style-position: outside;
  --mixin-kcqGefOCNbYc_padding-left: 40px;
  --mixin-kcqGefOCNbYc_position: relative;
  --mixin-kcqGefOCNbYc_list-style-type: decimal;
  --mixin-kcqGefOCNbYc_white-space: pre-wrap;
  --mixin-z8En5MqLOJGt_font-family: "Inter", sans-serif;
  --mixin-z8En5MqLOJGt_color: #000000;
  --mixin-z8En5MqLOJGt_font-size: 20px;
  --mixin-z8En5MqLOJGt_font-weight: 600;
  --mixin-z8En5MqLOJGt_letter-spacing: -0.3px;
  --mixin-z8En5MqLOJGt_line-height: 1.5;
  --mixin-z8En5MqLOJGt_white-space: pre-wrap;
  --mixin-rRcNkrRYoT1q_font-family: "Inter", sans-serif;
  --mixin-rRcNkrRYoT1q_color: #000000;
  --mixin-rRcNkrRYoT1q_font-size: 16px;
  --mixin-rRcNkrRYoT1q_font-weight: 600;
  --mixin-rRcNkrRYoT1q_line-height: 1.5;
  --mixin-rRcNkrRYoT1q_white-space: pre-wrap;
  --mixin-HsPVV2R9Mz1V_color: #3291ff;
  --mixin-HsPVV2R9Mz1V_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-pQT6qSPqLldT_white-space: pre-wrap;
  --plasmic-mixin-unnamed-mixin_white-space: var(
    --mixin-pQT6qSPqLldT_white-space
  );
  --mixin-h3QHxrHpFMZw_white-space: pre-wrap;
  --plasmic-mixin-unnamed-mixin-2_white-space: var(
    --mixin-h3QHxrHpFMZw_white-space
  );
  --mixin-ePNu4vB2j4AR_box-shadow: 0px 0px 0px 1px #00000033;
  --plasmic-mixin-unnamed-mixin-3_box-shadow: var(
    --mixin-ePNu4vB2j4AR_box-shadow
  );
  --mixin-ePNu4vB2j4AR_white-space: pre-wrap;
  --plasmic-mixin-unnamed-mixin-3_white-space: var(
    --mixin-ePNu4vB2j4AR_white-space
  );
  --mixin-up5c8884ZjJO_box-shadow: 0px 0px 0px 1px #dedfea;
  --plasmic-mixin-default-element_box-shadow: var(
    --mixin-up5c8884ZjJO_box-shadow
  );
  --mixin-up5c8884ZjJO_white-space: pre-wrap;
  --plasmic-mixin-default-element_white-space: var(
    --mixin-up5c8884ZjJO_white-space
  );
  --mixin-DtpLuX2Z3Qys_box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  --plasmic-mixin-input-mixin-1_box-shadow: var(
    --mixin-DtpLuX2Z3Qys_box-shadow
  );
  --mixin-DtpLuX2Z3Qys_white-space: pre-wrap;
  --plasmic-mixin-input-mixin-1_white-space: var(
    --mixin-DtpLuX2Z3Qys_white-space
  );
  --mixin-PUdBMgjsuqR8_white-space: pre-wrap;
  --plasmic-mixin-error-mixin_white-space: var(
    --mixin-PUdBMgjsuqR8_white-space
  );
  --mixin-MlVIEUM69ld0_white-space: pre-wrap;
  --plasmic-mixin-option-style_white-space: var(
    --mixin-MlVIEUM69ld0_white-space
  );
  --mixin-mJupxGjQkPs8_white-space: pre-wrap;
  --plasmic-mixin-unnamed-mixin-4_white-space: var(
    --mixin-mJupxGjQkPs8_white-space
  );
  --mixin-xGEOEiVhjjPc_white-space: pre-wrap;
  --plasmic-mixin-send-button_white-space: var(
    --mixin-xGEOEiVhjjPc_white-space
  );
  --mixin-rfO44_20-1S9_box-shadow: inset 0px 0px 1px 1px #c7c7c7;
  --plasmic-mixin-sheet-1_box-shadow: var(--mixin-rfO44_20-1S9_box-shadow);
  --mixin-rfO44_20-1S9_white-space: pre-wrap;
  --plasmic-mixin-sheet-1_white-space: var(--mixin-rfO44_20-1S9_white-space);
  --mixin-SY58Q_9JCsXZ_white-space: pre-wrap;
  --plasmic-mixin-sampletest_white-space: var(--mixin-SY58Q_9JCsXZ_white-space);
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-jmxGQfIn6UoU_font-family);
  font-size: var(--mixin-jmxGQfIn6UoU_font-size);
  font-weight: var(--mixin-jmxGQfIn6UoU_font-weight);
  font-style: var(--mixin-jmxGQfIn6UoU_font-style);
  color: var(--mixin-jmxGQfIn6UoU_color);
  text-align: var(--mixin-jmxGQfIn6UoU_text-align);
  text-transform: var(--mixin-jmxGQfIn6UoU_text-transform);
  line-height: var(--mixin-jmxGQfIn6UoU_line-height);
  letter-spacing: var(--mixin-jmxGQfIn6UoU_letter-spacing);
  white-space: var(--mixin-jmxGQfIn6UoU_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  font-family: var(--mixin-snFNtHm7jnQK_font-family);
  color: var(--mixin-snFNtHm7jnQK_color);
  font-size: var(--mixin-snFNtHm7jnQK_font-size);
  font-weight: var(--mixin-snFNtHm7jnQK_font-weight);
  letter-spacing: var(--mixin-snFNtHm7jnQK_letter-spacing);
  line-height: var(--mixin-snFNtHm7jnQK_line-height);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  font-family: var(--mixin-t1wddhn-Gcb8_font-family);
  color: var(--mixin-t1wddhn-Gcb8_color);
  font-size: var(--mixin-t1wddhn-Gcb8_font-size);
  font-weight: var(--mixin-t1wddhn-Gcb8_font-weight);
  letter-spacing: var(--mixin-t1wddhn-Gcb8_letter-spacing);
  line-height: var(--mixin-t1wddhn-Gcb8_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-U9O5H56W7EZ9_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  font-family: var(--mixin-_fKhYjQUwzsD_font-family);
  color: var(--mixin-_fKhYjQUwzsD_color);
  font-size: var(--mixin-_fKhYjQUwzsD_font-size);
  font-weight: var(--mixin-_fKhYjQUwzsD_font-weight);
  letter-spacing: var(--mixin-_fKhYjQUwzsD_letter-spacing);
  line-height: var(--mixin-_fKhYjQUwzsD_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-family: var(--mixin-mXIOoREV7zZa_font-family);
  color: var(--mixin-mXIOoREV7zZa_color);
  font-size: var(--mixin-mXIOoREV7zZa_font-size);
  font-weight: var(--mixin-mXIOoREV7zZa_font-weight);
  letter-spacing: var(--mixin-mXIOoREV7zZa_letter-spacing);
  line-height: var(--mixin-mXIOoREV7zZa_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-oR0w94PGuQo6_font-family);
  border-radius: var(--mixin-oR0w94PGuQo6_border-top-left-radius)
    var(--mixin-oR0w94PGuQo6_border-top-right-radius)
    var(--mixin-oR0w94PGuQo6_border-bottom-right-radius)
    var(--mixin-oR0w94PGuQo6_border-bottom-left-radius);
  padding: var(--mixin-oR0w94PGuQo6_padding-top)
    var(--mixin-oR0w94PGuQo6_padding-right)
    var(--mixin-oR0w94PGuQo6_padding-bottom)
    var(--mixin-oR0w94PGuQo6_padding-left);
  border-top: var(--mixin-oR0w94PGuQo6_border-top-width)
    var(--mixin-oR0w94PGuQo6_border-top-style)
    var(--mixin-oR0w94PGuQo6_border-top-color);
  border-right: var(--mixin-oR0w94PGuQo6_border-right-width)
    var(--mixin-oR0w94PGuQo6_border-right-style)
    var(--mixin-oR0w94PGuQo6_border-right-color);
  border-bottom: var(--mixin-oR0w94PGuQo6_border-bottom-width)
    var(--mixin-oR0w94PGuQo6_border-bottom-style)
    var(--mixin-oR0w94PGuQo6_border-bottom-color);
  border-left: var(--mixin-oR0w94PGuQo6_border-left-width)
    var(--mixin-oR0w94PGuQo6_border-left-style)
    var(--mixin-oR0w94PGuQo6_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-b4QQj5ITfRjT_color);
  padding-left: var(--mixin-b4QQj5ITfRjT_padding-left);
  border-left: var(--mixin-b4QQj5ITfRjT_border-left-width)
    var(--mixin-b4QQj5ITfRjT_border-left-style)
    var(--mixin-b4QQj5ITfRjT_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-tx0aJ-6oxrIs_font-family);
  border-radius: var(--mixin-tx0aJ-6oxrIs_border-top-left-radius)
    var(--mixin-tx0aJ-6oxrIs_border-top-right-radius)
    var(--mixin-tx0aJ-6oxrIs_border-bottom-right-radius)
    var(--mixin-tx0aJ-6oxrIs_border-bottom-left-radius);
  padding: var(--mixin-tx0aJ-6oxrIs_padding-top)
    var(--mixin-tx0aJ-6oxrIs_padding-right)
    var(--mixin-tx0aJ-6oxrIs_padding-bottom)
    var(--mixin-tx0aJ-6oxrIs_padding-left);
  border-top: var(--mixin-tx0aJ-6oxrIs_border-top-width)
    var(--mixin-tx0aJ-6oxrIs_border-top-style)
    var(--mixin-tx0aJ-6oxrIs_border-top-color);
  border-right: var(--mixin-tx0aJ-6oxrIs_border-right-width)
    var(--mixin-tx0aJ-6oxrIs_border-right-style)
    var(--mixin-tx0aJ-6oxrIs_border-right-color);
  border-bottom: var(--mixin-tx0aJ-6oxrIs_border-bottom-width)
    var(--mixin-tx0aJ-6oxrIs_border-bottom-style)
    var(--mixin-tx0aJ-6oxrIs_border-bottom-color);
  border-left: var(--mixin-tx0aJ-6oxrIs_border-left-width)
    var(--mixin-tx0aJ-6oxrIs_border-left-style)
    var(--mixin-tx0aJ-6oxrIs_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-fAMlkiehYfCO_display);
  flex-direction: var(--mixin-fAMlkiehYfCO_flex-direction);
  align-items: var(--mixin-fAMlkiehYfCO_align-items);
  justify-content: var(--mixin-fAMlkiehYfCO_justify-content);
  list-style-position: var(--mixin-fAMlkiehYfCO_list-style-position);
  padding-left: var(--mixin-fAMlkiehYfCO_padding-left);
  position: var(--mixin-fAMlkiehYfCO_position);
  list-style-type: var(--mixin-fAMlkiehYfCO_list-style-type);
  flex-column-gap: var(--mixin-fAMlkiehYfCO_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-kcqGefOCNbYc_display);
  flex-direction: var(--mixin-kcqGefOCNbYc_flex-direction);
  align-items: var(--mixin-kcqGefOCNbYc_align-items);
  justify-content: var(--mixin-kcqGefOCNbYc_justify-content);
  list-style-position: var(--mixin-kcqGefOCNbYc_list-style-position);
  padding-left: var(--mixin-kcqGefOCNbYc_padding-left);
  position: var(--mixin-kcqGefOCNbYc_position);
  list-style-type: var(--mixin-kcqGefOCNbYc_list-style-type);
  flex-column-gap: var(--mixin-kcqGefOCNbYc_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-family: var(--mixin-z8En5MqLOJGt_font-family);
  color: var(--mixin-z8En5MqLOJGt_color);
  font-size: var(--mixin-z8En5MqLOJGt_font-size);
  font-weight: var(--mixin-z8En5MqLOJGt_font-weight);
  letter-spacing: var(--mixin-z8En5MqLOJGt_letter-spacing);
  line-height: var(--mixin-z8En5MqLOJGt_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-family: var(--mixin-rRcNkrRYoT1q_font-family);
  color: var(--mixin-rRcNkrRYoT1q_color);
  font-size: var(--mixin-rRcNkrRYoT1q_font-size);
  font-weight: var(--mixin-rRcNkrRYoT1q_font-weight);
  line-height: var(--mixin-rRcNkrRYoT1q_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-HsPVV2R9Mz1V_color);
}
