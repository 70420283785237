.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 999;
  align-self: flex-start;
  height: 100%;
  max-width: none;
  flex-shrink: 0;
  min-height: 0;
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox__m4Fb1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  align-self: flex-start;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 8px 8px 0px;
}
@media (max-width: 1440px) {
  .freeBox__m4Fb1 {
    height: 100%;
    min-height: 0;
  }
}
.freeBox__eS27G {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 842px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  left: auto;
  top: auto;
  z-index: 1;
  padding: 0px 8px;
  margin: 50px 0px 0px;
}
@media (max-width: 1440px) {
  .freeBox__eS27G {
    width: 52vw;
    max-width: none;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 3.5vw;
  }
}
@media (max-width: 1370px) {
  .freeBox__eS27G {
    width: 53vw;
  }
}
.invoiceDetails {
  box-shadow: inset 0px 0px 1px 1px #cecfd8;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  max-width: 897px;
  min-width: 0;
  border-radius: 8px;
  margin: 0%;
}
.invoiceDetails > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.invoiceDetails > :global(.__wab_flex-container) > *,
.invoiceDetails > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.invoiceDetails > :global(.__wab_flex-container) > picture > img,
.invoiceDetails
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .invoiceDetails {
    left: auto;
    top: auto;
  }
}
.freeBox___0L36Y {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 65px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox___0L36Y {
    height: 3.66vw;
    flex-shrink: 0;
    padding: 0px;
  }
}
.title4 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title4 {
    font-size: 1.1vw;
    margin-top: 0%;
    margin-bottom: 0%;
  }
}
@media (max-width: 1370px) {
  .title4 {
    font-size: 1.1vw;
  }
}
.title3 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  margin-bottom: 0%;
}
@media (max-width: 1440px) {
  .title3 {
    font-size: 1.06vw;
    margin-left: 3.6vw;
    margin-top: calc(2.35vw + 0px) !important;
  }
}
.selectedClient {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 305px;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(30px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .selectedClient {
    width: 21.2vw;
    margin-left: 3.6vw;
    margin-top: calc(2vw + 0px) !important;
  }
}
.freeBox___8A8Hs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  max-width: 100%;
  background: #ffffff00;
  min-height: 0;
  padding: 0px;
}
.slotTargetCompanySelected {
  font-weight: 700;
}
.text__rrXUm {
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
}
.emailSelected {
  margin-top: 4%;
  display: flex;
  flex-direction: row;
}
.slotTargetEmailSelected {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 1440px) {
  .slotTargetEmailSelected {
    font-size: 0.94vw;
  }
}
.deleteSelection {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: #3555ff;
  cursor: pointer;
  margin-left: 6%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .deleteSelection {
    margin-left: 1.4vw;
    font-size: 1vw;
  }
}
.menuSelectStack:global(.__wab_instance) {
  position: absolute;
  top: 194px;
  left: 56px;
  z-index: 1;
  margin-left: 0px;
}
@media (max-width: 1440px) {
  .menuSelectStack:global(.__wab_instance) {
    width: 17.43vw;
    left: 6.87%;
    top: 21.93%;
  }
}
.searchRectangle:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(30px + 0px) !important;
  margin-left: 50px;
  width: 305px;
  margin-bottom: 0px;
  max-width: 328px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .searchRectangle:global(.__wab_instance) {
    left: auto;
    top: auto;
    margin-left: 3.6vw;
    margin-top: calc(1.7vw + 0px) !important;
    width: 17.9vw;
    height: 2.29vw;
    flex-shrink: 0;
  }
}
.title8 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 50px;
  margin-top: calc(60px + 0px) !important;
  margin-bottom: 0%;
}
@media (max-width: 1440px) {
  .title8 {
    font-size: 1.06vw;
    left: auto;
    top: auto;
    margin-left: 3.6vw;
    margin-top: calc(3.1vw + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .title8 {
    margin-top: calc(3.1vw + 0px) !important;
  }
}
.subscriptionSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 50px;
  margin-top: calc(30px + 0px) !important;
  width: 305px;
  height: 41px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .subscriptionSelect:global(.__wab_instance) {
    width: 17.9vw;
    height: 2.29vw;
    margin-top: calc(1.7vw + 0px) !important;
    margin-left: 3.6vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .text___64Dys {
    font-size: 1vw;
  }
}
.title11 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 50px;
  margin-top: calc(60px + 0px) !important;
  margin-bottom: 0%;
}
@media (max-width: 1440px) {
  .title11 {
    font-size: 1.06vw;
    left: auto;
    top: auto;
    margin-left: 3.6vw;
    margin-top: calc(3.1vw + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .title11 {
    left: auto;
    top: auto;
    margin-top: calc(3.1vw + 0px) !important;
  }
}
.dueDate {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 305px;
  height: 41px;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(30px + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .dueDate {
    margin-left: 3.6vw;
    margin-top: calc(1.7vw + 0px) !important;
    width: 17.9vw;
    height: 2.29vw;
    flex-shrink: 0;
  }
}
.title12 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 50px;
  margin-top: calc(60px + 0px) !important;
  margin-bottom: 0%;
}
@media (max-width: 1440px) {
  .title12 {
    font-size: 1.06vw;
    left: auto;
    top: auto;
    margin-left: 3.6vw;
    margin-top: calc(3.1vw + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .title12 {
    left: auto;
    top: auto;
    margin-top: calc(3.1vw + 0px) !important;
  }
}
.deliverySelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 50px;
  margin-top: calc(30px + 0px) !important;
  width: 305px;
  height: 41px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .deliverySelect:global(.__wab_instance) {
    width: 17.9vw;
    height: 2.29vw;
    margin-top: calc(1.7vw + 0px) !important;
    margin-left: 3.6vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .text__rzY5S {
    font-size: 1vw;
  }
}
.deliveryStack {
  position: relative;
  margin-left: 50px;
  margin-top: calc(20px + 0px) !important;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1440px) {
  .deliveryStack {
    margin-left: 3.6vw;
    margin-top: calc(1.3vw + 0px) !important;
  }
}
.slotTargetDeliveryMessage {
  font-size: 13.1px;
  font-weight: 500;
}
@media (max-width: 1440px) {
  .slotTargetDeliveryMessage {
    font-size: 0.77vw;
  }
}
.freeBox__kziKz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: calc(65px + 0px) !important;
  margin-left: 0%;
  background: none;
  min-width: 0;
  padding: 43px 0px 0px;
  border-top: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__kziKz {
    margin-bottom: 0vw;
    padding-top: 2.38vw;
    margin-top: calc(3.23vw + 0px) !important;
  }
}
.title2 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  margin-left: 50px;
}
@media (max-width: 1440px) {
  .title2 {
    font-size: 1.06vw;
    margin-left: 3.6vw;
  }
}
.title5 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  margin-left: 339px;
}
@media (max-width: 1440px) {
  .title5 {
    font-size: 1.06vw;
    margin: 0px 0px 0px 21.3vw;
  }
}
.title6 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  margin-left: 60px;
  padding-left: 0px;
}
@media (max-width: 1440px) {
  .title6 {
    font-size: 1.06vw;
    margin-left: 4.6vw;
  }
}
.invoiceStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: calc(40px + 0px) !important;
  margin-left: 50px;
  padding: 0px;
}
.invoiceStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.invoiceStack > :global(.__wab_flex-container) > *,
.invoiceStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.invoiceStack > :global(.__wab_flex-container) > picture > img,
.invoiceStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
@media (max-width: 1440px) {
  .invoiceStack {
    margin-left: 3.6vw;
    margin-top: calc(2.3vw + 0px) !important;
  }
}
.invoiceFields:global(.__wab_instance) {
  width: auto;
  max-width: none;
}
.freeBox__skKHy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(45px + 0px) !important;
  margin-left: 50px;
  min-width: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__skKHy {
    margin-left: 3.6vw;
    margin-top: calc(2.8vw + 0px) !important;
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
.addItem {
  display: block;
  font-size: 17px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3555ff;
  width: auto;
  height: auto;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .addItem {
    font-size: 0.94vw;
  }
}
.title9 {
  display: flex;
  width: 229px;
  height: auto;
  cursor: default;
  margin-left: 412px;
  padding-left: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .title9 {
    width: 15.9vw;
    margin-left: 25vw;
    flex-shrink: 0;
  }
}
.slotTargetBalanceDue {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #1f1f1f;
  line-height: 100%;
  letter-spacing: 0.3px;
}
@media (max-width: 1440px) {
  .slotTargetBalanceDue {
    font-size: 0.94vw;
  }
}
.title10 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  margin-left: 50px;
  margin-top: calc(70px + 0px) !important;
}
@media (max-width: 1440px) {
  .title10 {
    font-size: 1.06vw;
    margin-top: calc(4.08vw + 0px) !important;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 3.6vw;
  }
}
@media (max-width: 1370px) {
  .title10 {
    margin-top: calc(4.08vw + 0px) !important;
  }
}
.notes {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  font-size: 16.4px;
  position: relative;
  width: 395px;
  height: 108px;
  max-width: 100%;
  margin-top: calc(20px + 0px) !important;
  margin-left: 50px;
  margin-bottom: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 7px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .notes {
    margin-left: 3.6vw;
    margin-top: calc(1.4vw + 0px) !important;
    margin-bottom: 3.06vw;
    font-size: 0.97vw;
    width: 21vw;
    height: 6.12vw;
    flex-shrink: 0;
    padding: 0.5vw 0.2vw 0.2vw 0.8vw;
  }
}
.freeBox__axs72 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 0px;
}
@media (max-width: 1440px) {
  .freeBox__axs72 {
    height: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 0;
  }
}
.advancedOptionsInstance:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: 10%;
  width: 100%;
  max-width: none;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .advancedOptionsInstance:global(.__wab_instance) {
    margin-top: 9.5%;
    flex-shrink: 0;
  }
}
.advancedOptionsStack {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  width: 100%;
  height: 420px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  padding-bottom: 0px;
  margin-top: 0%;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 0px 0px 8px 8px;
}
@media (max-width: 1440px) {
  .advancedOptionsStack {
    height: 29vw;
    padding-top: 1%;
    padding-bottom: 0%;
    flex-shrink: 0;
  }
}
.freeBox__gwgBs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-right: 0px;
  margin-top: 2%;
  min-width: 0;
  padding: 8px 4px 8px 8px;
}
.freeBox__gwgBs > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.freeBox__gwgBs > :global(.__wab_flex-container) > *,
.freeBox__gwgBs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gwgBs > :global(.__wab_flex-container) > picture > img,
.freeBox__gwgBs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 50px;
}
@media (max-width: 1440px) {
  .freeBox__gwgBs {
    padding: 0.5vw 0vw 0.5vw 0.5vw;
  }
}
@media (max-width: 1440px) {
  .freeBox__gwgBs > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
@media (max-width: 1370px) {
  .freeBox__gwgBs {
    padding-right: 0.5vw;
    margin: var(--token-YDIAfzfKi1OS);
  }
}
.title26 {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(15px + 50px) !important;
  margin-bottom: 0%;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .title26 {
    font-size: 1vw;
    margin-top: calc(15px + 50px) !important;
  }
}
.achSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
  margin-left: 0%;
}
.text___3UDij {
  padding-left: 0px;
}
.cardSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
  margin-left: 0%;
}
.text__dmpXd {
  padding-left: 0px;
}
.cardSurchargeSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
  margin-left: 0%;
}
@media (max-width: 1440px) {
  .cardSurchargeSwitch:global(.__wab_instance) {
    width: auto;
  }
}
.text___5OlSb {
  padding-left: 0px;
}
.errorText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ff0000;
  font-weight: 500;
  font-size: 16px;
  margin-top: 8%;
  margin-bottom: -5%;
}
@media (max-width: 1440px) {
  .errorText {
    margin-top: 10%;
    margin-bottom: -5%;
    font-size: 1.1vw;
  }
}
.sendButton {
  width: 217px;
  height: 54px;
  background: #048b68;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 100px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .sendButton {
    width: 216px;
    height: 54px;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    background: #3555ff;
    flex-shrink: 0;
  }
}
.root .sendButton:hover {
  background: #048b68d4;
}
.root .sendButton:active {
  background: #048b68d4;
  border-width: 1px;
  border-style: solid;
}
.text___533Yc {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .text___533Yc {
    font-size: 1.15vw;
  }
}
