.root {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.rootcolor_softGreen {
  border-color: #ffffff00;
}
.trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 1px #a3a3a3;
  min-width: 0;
  min-height: 0;
  border-radius: 10px;
  padding: 8px 12px;
  border: 1px solid #dbdbd700;
}
.triggerisOpen {
  background: #e9e9e6;
}
.triggercolor_softBlue {
  background: #edf6ff;
  border-color: #ffffff00;
}
.triggercolor_softCyan {
  background: #e7f9fb;
  border-color: #ffffff00;
}
.triggercolor_softGreen {
  background: #e9f9ee;
  border-color: #ffffff00;
}
.triggercolor_softYellow {
  background: #fffbd1;
  border-color: #ffffff00;
}
.triggercolor_softOrange {
  background: #fff1e7;
  border-color: #ffffff00;
}
.triggercolor_softRed {
  background: #ffefef;
  border-color: #ffffff00;
}
.triggercolor_softPink {
  background: #feeef8;
  border-color: #ffffff00;
}
.triggercolor_softPurple {
  background: #f9f1fe;
  border-color: #ffffff00;
}
.triggercolor_softGray {
  background: #f3f3f2;
}
.triggercolor_clear {
  background: none;
}
.root .trigger:hover {
  background: #eeeeec;
}
.root .trigger:active {
  background: #e9e9e6;
}
.rootcolor_softBlue .triggercolor_softBlue:hover {
  background: #e1f0ff;
}
.rootcolor_softBlue .triggercolor_softBlue:active {
  background: #cee7fe;
}
.rootcolor_softCyan .triggercolor_softCyan:hover {
  background: #d8f3f6;
}
.rootcolor_softCyan .triggercolor_softCyan:active {
  background: #c4eaef;
}
.rootcolor_softGreen .triggercolor_softGreen:hover {
  background: #ddf3e4;
}
.rootcolor_softGreen .triggercolor_softGreen:active {
  background: #ccebd7;
}
.rootcolor_softYellow .triggercolor_softYellow:hover {
  background: #fff8bb;
}
.rootcolor_softYellow .triggercolor_softYellow:active {
  background: #fef2a4;
}
.rootcolor_softOrange .triggercolor_softOrange:hover {
  background: #ffe8d7;
}
.rootcolor_softOrange .triggercolor_softOrange:active {
  background: #ffdcc3;
}
.rootcolor_softRed .triggercolor_softRed:hover {
  background: #ffe6e2;
}
.rootcolor_softRed .triggercolor_softRed:active {
  background: #fdd8d3;
}
.rootcolor_softPink .triggercolor_softPink:hover {
  background: #fce5f3;
}
.rootcolor_softPink .triggercolor_softPink:active {
  background: #f9d8ec;
}
.rootcolor_softPurple .triggercolor_softPurple:hover {
  background: #f3e7fc;
}
.rootcolor_softPurple .triggercolor_softPurple:active {
  background: #eddbf9;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.slotTargetSelectedContent {
  white-space: pre;
}
.slotTargetSelectedContent > :global(.__wab_text),
.slotTargetSelectedContent > :global(.__wab_expr_html_text),
.slotTargetSelectedContent > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetSelectedContent > *,
.slotTargetSelectedContent > :global(.__wab_slot) > *,
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSelectedContent > picture > img,
.slotTargetSelectedContent > :global(.__wab_slot) > picture > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetSelectedContentcolor_softBlue {
  color: #006adc;
}
.slotTargetSelectedContentcolor_softCyan {
  color: #0c7792;
}
.slotTargetSelectedContentcolor_softGreen {
  color: #18794e;
}
.slotTargetSelectedContentcolor_softYellow {
  color: #946800;
}
.slotTargetSelectedContentcolor_softOrange {
  color: #bd4b00;
}
.slotTargetSelectedContentcolor_softRed {
  color: #cd2b31;
}
.slotTargetSelectedContentcolor_softPink {
  color: #cd1d8d;
}
.slotTargetSelectedContentcolor_softPurple {
  color: #793aaf;
}
.slotTargetPlaceholder {
  white-space: pre;
  color: #706f6c;
}
.slotTargetPlaceholder > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_expr_html_text),
.slotTargetPlaceholder > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetPlaceholder > *,
.slotTargetPlaceholder > :global(.__wab_slot) > *,
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetPlaceholder > picture > img,
.slotTargetPlaceholder > :global(.__wab_slot) > picture > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetPlaceholdercolor_softBlue_showPlaceholder {
  color: #006adc;
}
.slotTargetPlaceholdercolor_softCyan_showPlaceholder {
  color: #0c7792;
}
.slotTargetPlaceholdercolor_softGreen_showPlaceholder {
  color: #18794e;
}
.slotTargetPlaceholdercolor_softYellow_showPlaceholder {
  color: #946800;
}
.slotTargetPlaceholdercolor_softOrange_showPlaceholder {
  color: #bd4b00;
}
.slotTargetPlaceholdershowPlaceholder_color_softRed {
  color: #cd2b31;
}
.slotTargetPlaceholdershowPlaceholder_color_softPink {
  color: #cd1d8d;
}
.slotTargetPlaceholdercolor_softPurple_showPlaceholder {
  color: #793aaf;
}
.dropdownIcon {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: #90908c;
  align-self: flex-end;
  margin: 4px;
}
.dropdownIconcolor_softBlue {
  color: #0091ff;
}
.dropdownIconcolor_softCyan {
  color: #05a2c2;
}
.dropdownIconcolor_softGreen {
  color: #30a46c;
}
.dropdownIconcolor_softYellow {
  color: #f5d90a;
}
.dropdownIconcolor_softOrange {
  color: #f76808;
}
.dropdownIconcolor_softRed {
  color: #e5484d;
}
.dropdownIconcolor_softPink {
  color: #d6409f;
}
.dropdownIconcolor_softPurple {
  color: #8e4ec6;
}
.overlay:global(.__wab_instance) {
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: 1000;
  width: 100%;
  min-width: 0;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.option__p9U1B:global(.__wab_instance) {
  position: relative;
}
.option__pbtQw:global(.__wab_instance) {
  position: relative;
}
.option___3Jepu:global(.__wab_instance) {
  position: relative;
}
.option___0C9X:global(.__wab_instance) {
  position: relative;
}
.option__tc3Hw:global(.__wab_instance) {
  position: relative;
}
.option__kijev:global(.__wab_instance) {
  position: relative;
}
.option__vDIwJ:global(.__wab_instance) {
  position: relative;
}
.option__jJq0X:global(.__wab_instance) {
  position: relative;
}
.option__lxcyb:global(.__wab_instance) {
  position: relative;
}
.option__fx9Ej:global(.__wab_instance) {
  position: relative;
}
.option__rfgH2:global(.__wab_instance) {
  position: relative;
}
.option___35Kzu:global(.__wab_instance) {
  position: relative;
}
.option__crbZa:global(.__wab_instance) {
  position: relative;
}
.option__bI1Ch:global(.__wab_instance) {
  position: relative;
}
.option__bVl5K:global(.__wab_instance) {
  position: relative;
}
.option___4Ukx:global(.__wab_instance) {
  position: relative;
}
.option__jNzhy:global(.__wab_instance) {
  position: relative;
}
.option__j77Ob:global(.__wab_instance) {
  position: relative;
}
.option__ekJnz:global(.__wab_instance) {
  position: relative;
}
.option__nHy3I:global(.__wab_instance) {
  position: relative;
}
.option__xxipa:global(.__wab_instance) {
  position: relative;
}
.option__qpyPn:global(.__wab_instance) {
  position: relative;
}
.option__rXoqk:global(.__wab_instance) {
  position: relative;
}
.option___1FvZk:global(.__wab_instance) {
  position: relative;
}
.option__iNvLx:global(.__wab_instance) {
  position: relative;
}
.option___8GIaQ:global(.__wab_instance) {
  position: relative;
}
.option__sRfba:global(.__wab_instance) {
  position: relative;
}
.option__dbNoh:global(.__wab_instance) {
  position: relative;
}
.option___8BBm4:global(.__wab_instance) {
  position: relative;
}
.option__qu7BE:global(.__wab_instance) {
  position: relative;
}
.option__qiMSr:global(.__wab_instance) {
  position: relative;
}
.option___2Swit:global(.__wab_instance) {
  position: relative;
}
.option__eJoEi:global(.__wab_instance) {
  position: relative;
}
.option__q8Mf8:global(.__wab_instance) {
  position: relative;
}
.option__gkmeM:global(.__wab_instance) {
  position: relative;
}
.option__q96HY:global(.__wab_instance) {
  position: relative;
}
.option__dthhQ:global(.__wab_instance) {
  position: relative;
}
.option__uRmYt:global(.__wab_instance) {
  position: relative;
}
.option__iG7E:global(.__wab_instance) {
  position: relative;
}
.option__pmh3G:global(.__wab_instance) {
  position: relative;
}
.option__veqcq:global(.__wab_instance) {
  position: relative;
}
.option___8PKcF:global(.__wab_instance) {
  position: relative;
}
.option__odiDy:global(.__wab_instance) {
  position: relative;
}
.option__t8Wgz:global(.__wab_instance) {
  position: relative;
}
.option__lqZs:global(.__wab_instance) {
  position: relative;
}
.option__u16ZX:global(.__wab_instance) {
  position: relative;
}
.option__kjVsh:global(.__wab_instance) {
  position: relative;
}
.option__ev0OD:global(.__wab_instance) {
  position: relative;
}
.option__qydIu:global(.__wab_instance) {
  position: relative;
}
.option___5SDhy:global(.__wab_instance) {
  position: relative;
}
.option__ypn1T:global(.__wab_instance) {
  position: relative;
}
.option__stivt:global(.__wab_instance) {
  position: relative;
}
.option__tqQd5:global(.__wab_instance) {
  position: relative;
}
.option___1Cu0:global(.__wab_instance) {
  position: relative;
}
.option__gh7Ja:global(.__wab_instance) {
  position: relative;
}
.option__sIn55:global(.__wab_instance) {
  position: relative;
}
