.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox__ujiN {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__yZwx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___71Wqv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 16px;
  min-width: 0;
  padding: 8px 8px 0px;
}
.emailInput:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  height: 43px;
}
.freeBox__e7Ruk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__qlKZt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.selectRoleStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.select:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 35px;
  width: 250px;
  flex-shrink: 0;
}
.option__jCca:global(.__wab_instance) {
  position: relative;
}
.option__vx5Kz:global(.__wab_instance) {
  position: relative;
}
.option__fHbVy:global(.__wab_instance) {
  position: relative;
}
.option__y8Zoa:global(.__wab_instance) {
  position: relative;
}
.option__m9WYb:global(.__wab_instance) {
  position: relative;
}
.freeBox__wmHgB {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 18px;
  min-width: 0;
  padding: 8px;
}
.sendInviteBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__oD9Lt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1Jwbv {
  padding-right: 0px;
}
.svg__wX7Uf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.addRoleBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zyAdf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2BUdg {
  align-self: center;
  text-align: center;
}
.svg__eAquU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
