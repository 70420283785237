.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 10vw;
  height: auto;
  max-width: none;
  position: relative;
  background: none;
  padding: 0px;
}
.rootpostClick {
  width: auto;
  display: inline-flex;
}
.freeBox__gobZg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 10vw;
  height: auto;
  max-width: none;
  position: relative;
  background: none;
  padding: 0px;
}
.freeBoxpostClick__gobZgaZ6Pv {
  width: auto;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fffefe;
  width: 1.5vw;
  height: 1vw;
  display: block;
  margin-top: 0.25vw;
  margin-bottom: -0.5vw;
  align-self: flex-end;
  margin-right: 0.6vw;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  flex-shrink: 0;
  border-width: 0px;
}
.freeBox__tnsUb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px #00000021;
  border-radius: 0.5vw;
  padding: 0px;
}
.debtButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.debtButtonpostClick:global(.__wab_instance) {
  flex-shrink: 0;
}
.invoiceButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.invoiceButtonpostClick:global(.__wab_instance) {
  flex-shrink: 0;
}
.subscriptionButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.subscriptionButtonpostClick:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.invoiceButton2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.invoiceButton2postClick:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
