.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  min-width: 0;
  min-height: 0;
  padding: 15px 17px;
}
@media (max-width: 1440px) {
  .root {
    padding: 1.05vw 1.12vw;
  }
}
.text__kGXqG {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .text__kGXqG {
    font-size: 1.1vw;
  }
}
.name {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 100%;
  height: 40px;
  max-width: 100%;
  margin-top: 22px;
  color: #535353;
  font-family: "Open Sans", sans-serif;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border: 0px solid #d3d3d3;
}
@media (max-width: 1440px) {
  .name {
    font-size: 1.11vw;
    height: 2.6vw;
    margin-top: 1.5vw;
    flex-shrink: 0;
  }
}
.text__roQvq {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
  margin-top: 38px;
}
@media (max-width: 1440px) {
  .text__roQvq {
    font-size: 1.1vw;
    margin: 2.52vw 0px 0vw;
  }
}
.amount {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 255px;
  height: 40px;
  max-width: 100%;
  background: #ffffff;
  margin-top: 22px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .amount {
    height: 2.6vw;
    margin-top: 1.5vw;
    flex-shrink: 0;
  }
}
.text__jeTjN {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
  margin-top: 38px;
}
@media (max-width: 1440px) {
  .text__jeTjN {
    font-size: 1.1vw;
    margin-top: 2.52vw;
  }
}
.dueDate {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  max-width: 100%;
  background: #ffffff;
  margin-top: 22px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .dueDate {
    height: 2.6vw;
    margin-top: 1.5vw;
    flex-shrink: 0;
  }
}
.text__aPaSb {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
  margin-top: 38px;
}
@media (max-width: 1440px) {
  .text__aPaSb {
    font-size: 1.1vw;
    margin-top: 2.52vw;
  }
}
.description {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 14px;
  position: relative;
  width: 100%;
  height: 120px;
  max-width: 100%;
  left: auto;
  top: auto;
  background: #ffffff;
  margin-top: 22px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 8px 2px 2px 12px;
  border: 0px solid #d3d3d3;
}
@media (max-width: 1440px) {
  .description {
    font-size: 0.97vw;
    height: 8.3vw;
    margin-top: 1.5vw;
    padding-top: 9px;
    flex-shrink: 0;
  }
}
