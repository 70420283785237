.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__n0RrX {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__oipEv {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__cObrC {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox__stSpd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: transparent;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__wz20U {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  height: 1em;
  flex-shrink: 0;
}
.svg__osGes {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__yeBvm {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__qwYFw {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__iBoa {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__tN7RI {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__n7DvJ {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__mjziI {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__iQqr2 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox__cUar {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__cUar > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__cUar > :global(.__wab_flex-container) > *,
.freeBox__cUar > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cUar > :global(.__wab_flex-container) > picture > img,
.freeBox__cUar
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__fwBsJ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
@media (max-width: 1370px) {
  .text__fwBsJ {
    margin-top: calc(57px + 5px) !important;
  }
}
.text___3JqHw {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___29DCe {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__biIvx {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__v2Sem {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__hYdwp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  overflow: visible;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.text__xoSvi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 16.3px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  padding-bottom: 0px;
  min-width: 0;
  margin: 39px 0px 3px;
}
.freeBox___24Upk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__mr31W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text___4WxFb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.firstNameError {
  margin-left: 0px;
  margin-top: 8px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .firstNameError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox___5MMp3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text___9Ef7I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.lastNameError {
  margin-left: 153px;
  margin-top: 8px;
  margin-bottom: -30px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 335px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .lastNameError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox__u7ZrZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__dqnQs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.legalBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.legalNameError {
  margin-left: 0px;
  margin-top: 8px;
  margin-bottom: -30px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  padding-right: 0px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .legalNameError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox__oZAhB {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__wuswW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.displayBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.displayNameError {
  margin-left: 0px;
  margin-top: 8px;
  margin-bottom: -30px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .displayNameError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox__fnPr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__dmTb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.website {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.linkError {
  margin-left: 0px;
  margin-top: 8px;
  margin-bottom: -30px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .linkError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox__jssNz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__i1Ibj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ein {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.einError {
  margin-left: 0px;
  margin-top: 8px;
  margin-bottom: -30px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .einError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox__ac06K {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__koHn0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.textInput {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 14px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.addressError {
  margin-left: 0px;
  margin-top: 3px;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .addressError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.suite {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.cityError {
  margin-left: 0px;
  margin-top: 3px;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .cityError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__mfrDt:global(.__wab_instance) {
  position: relative;
}
.option__zDuV4:global(.__wab_instance) {
  position: relative;
}
.option__hQ7T0:global(.__wab_instance) {
  position: relative;
}
.option__vKyEf:global(.__wab_instance) {
  position: relative;
}
.option__wnHvg:global(.__wab_instance) {
  position: relative;
}
.option___9PAg:global(.__wab_instance) {
  position: relative;
}
.option___6BCqe:global(.__wab_instance) {
  position: relative;
}
.option__e1Ee7:global(.__wab_instance) {
  position: relative;
}
.option__yvbtS:global(.__wab_instance) {
  position: relative;
}
.option__t5KCk:global(.__wab_instance) {
  position: relative;
}
.option__yTku9:global(.__wab_instance) {
  position: relative;
}
.option__e3BiT:global(.__wab_instance) {
  position: relative;
}
.option__hoPp0:global(.__wab_instance) {
  position: relative;
}
.option__lxQiy:global(.__wab_instance) {
  position: relative;
}
.option__cAu:global(.__wab_instance) {
  position: relative;
}
.option__bl20X:global(.__wab_instance) {
  position: relative;
}
.option__mtDmA:global(.__wab_instance) {
  position: relative;
}
.option__zhySe:global(.__wab_instance) {
  position: relative;
}
.option__bSisz:global(.__wab_instance) {
  position: relative;
}
.option___30SqL:global(.__wab_instance) {
  position: relative;
}
.option___7E9Ta:global(.__wab_instance) {
  position: relative;
}
.option__mcu0Y:global(.__wab_instance) {
  position: relative;
}
.option__xYrJk:global(.__wab_instance) {
  position: relative;
}
.option__dLsYd:global(.__wab_instance) {
  position: relative;
}
.option__vExzU:global(.__wab_instance) {
  position: relative;
}
.option__vC6B:global(.__wab_instance) {
  position: relative;
}
.option__n6CMy:global(.__wab_instance) {
  position: relative;
}
.option___6Rng3:global(.__wab_instance) {
  position: relative;
}
.option__o2Luz:global(.__wab_instance) {
  position: relative;
}
.option__wHWs:global(.__wab_instance) {
  position: relative;
}
.option__weaae:global(.__wab_instance) {
  position: relative;
}
.option__jiejK:global(.__wab_instance) {
  position: relative;
}
.option__kX3N:global(.__wab_instance) {
  position: relative;
}
.option__py8Y6:global(.__wab_instance) {
  position: relative;
}
.option___1WuRj:global(.__wab_instance) {
  position: relative;
}
.option__pRmvC:global(.__wab_instance) {
  position: relative;
}
.option__hz7D3:global(.__wab_instance) {
  position: relative;
}
.option__eAEw4:global(.__wab_instance) {
  position: relative;
}
.option___44GrI:global(.__wab_instance) {
  position: relative;
}
.option__ttCtn:global(.__wab_instance) {
  position: relative;
}
.option__svv3W:global(.__wab_instance) {
  position: relative;
}
.option__q1Xvx:global(.__wab_instance) {
  position: relative;
}
.option__xpjOh:global(.__wab_instance) {
  position: relative;
}
.option__esK4V:global(.__wab_instance) {
  position: relative;
}
.option___5B40H:global(.__wab_instance) {
  position: relative;
}
.option__gzmJl:global(.__wab_instance) {
  position: relative;
}
.option__ucoYb:global(.__wab_instance) {
  position: relative;
}
.option__kboE1:global(.__wab_instance) {
  position: relative;
}
.option__fbMVa:global(.__wab_instance) {
  position: relative;
}
.option___6P3N:global(.__wab_instance) {
  position: relative;
}
.option__qt1HQ:global(.__wab_instance) {
  position: relative;
}
.option__qD3L:global(.__wab_instance) {
  position: relative;
}
.option__f3QUr:global(.__wab_instance) {
  position: relative;
}
.option__eKOlS:global(.__wab_instance) {
  position: relative;
}
.option__goBfr:global(.__wab_instance) {
  position: relative;
}
.option__it1Qy:global(.__wab_instance) {
  position: relative;
}
.stateError {
  margin-left: 0px;
  margin-top: 3px;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .stateError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.zipError {
  margin-left: 0px;
  margin-top: 3px;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .zipError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox__q8Nsb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__tW7WI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.selectIndustry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  margin-left: 0px;
  flex-shrink: 0;
}
.option__g043P:global(.__wab_instance) {
  position: relative;
}
.option__ewY6C:global(.__wab_instance) {
  position: relative;
}
.option__tjN0Q:global(.__wab_instance) {
  position: relative;
}
.option__zY1M7:global(.__wab_instance) {
  position: relative;
}
.option__uoViG:global(.__wab_instance) {
  position: relative;
}
.option__aifNy:global(.__wab_instance) {
  position: relative;
}
.option__tPqh7:global(.__wab_instance) {
  position: relative;
}
.option__d8Fc:global(.__wab_instance) {
  position: relative;
}
.option___00Tkr:global(.__wab_instance) {
  position: relative;
}
.option__zhthS:global(.__wab_instance) {
  position: relative;
}
.option___9Drt5:global(.__wab_instance) {
  position: relative;
}
.option___9Sro1:global(.__wab_instance) {
  position: relative;
}
.option__lPk0L:global(.__wab_instance) {
  position: relative;
}
.option__fjCfA:global(.__wab_instance) {
  position: relative;
}
.option___5EzN5:global(.__wab_instance) {
  position: relative;
}
.option__zNor:global(.__wab_instance) {
  position: relative;
}
.option__d96Yx:global(.__wab_instance) {
  position: relative;
}
.option__z7IKv:global(.__wab_instance) {
  position: relative;
}
.option__rvHz:global(.__wab_instance) {
  position: relative;
}
.option___9Zgzw:global(.__wab_instance) {
  position: relative;
}
.option__ibEdW:global(.__wab_instance) {
  position: relative;
}
.option__czQl5:global(.__wab_instance) {
  position: relative;
}
.option__iiD4G:global(.__wab_instance) {
  position: relative;
}
.option__p08Pu:global(.__wab_instance) {
  position: relative;
}
.option__y9Ee9:global(.__wab_instance) {
  position: relative;
}
.industryError {
  margin-left: 0px;
  margin-top: 3px;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .industryError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox___5OWAv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___8Xdln {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.monthlyVolume {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.volumeError {
  margin-left: 0px;
  margin-top: 3px;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .volumeError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox__wOiZm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__iJdEf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.averageTicket {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.averageTransactionError {
  margin-left: 0px;
  margin-top: 3px;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .averageTransactionError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.freeBox__gPDcu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__nMVkI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.highestTicket {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.highestEstimationError {
  margin-left: 0px;
  margin-top: 3px;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 585px;
  align-self: flex-end;
  font-size: 15px;
  min-width: 0;
}
@media (max-width: 1370px) {
  .highestEstimationError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.einlengtherror {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .einlengtherror {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.continueButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 49px;
  cursor: pointer;
  margin-bottom: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .continueButton:hover {
  background: #3555ffd4;
}
.root .continueButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__kvVla {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
