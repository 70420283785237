.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 12vw;
  height: 2.8vw;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px transparent;
  position: relative;
  cursor: pointer;
  border-radius: 0.5vw;
  padding: 0px;
}
.root:hover {
  background: #dddddd;
}
.root:active {
  box-shadow: none;
  border-width: 0.01vw;
  border-style: solid;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.7vw;
  left: auto;
  top: auto;
  color: #0400ff;
  font-family: "Poppins", sans-serif;
  margin-left: 1.1vw;
  font-weight: 500;
  text-align: center;
}
.root:hover .text {
  color: #474766;
}
