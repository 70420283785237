.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  position: relative;
  min-width: 0;
  padding: 17px 8px;
}
@media (max-width: 1440px) {
  .root {
    padding: 1vw 0.5vw;
  }
}
.title18 {
  display: flex;
  width: 22px;
  height: 27px;
  position: absolute;
  left: 19px;
  top: auto;
  margin-right: 0%;
  bottom: 27px;
  flex-direction: row;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .title18 {
    left: 2.06%;
    bottom: 22.93%;
    width: auto;
    height: auto;
  }
}
.slotTargetNumber {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #2e2e2e;
  line-height: 100%;
  letter-spacing: 0.3px;
}
@media (max-width: 1440px) {
  .slotTargetNumber {
    font-size: 0.94vw;
  }
}
.freeBox___3QAmD {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-left: 41px;
  padding: 8px;
}
@media (max-width: 1440px) {
  .freeBox___3QAmD {
    width: auto;
    margin-left: 3.2vw;
    padding: 0px;
  }
}
.title16 {
  display: block;
  font-size: 17.5px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: 37px;
  position: relative;
  left: auto;
  top: auto;
  align-self: auto;
  margin-left: 10px;
  margin-bottom: 10px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .title16 {
    font-size: 0.94vw;
    text-align: center;
    align-self: auto;
    margin-left: 0.9vw;
    margin-bottom: 0.68vw;
    height: 2.6vw;
    flex-shrink: 0;
  }
}
.rectangle17 {
  background: rgba(255, 255, 255, 1);
  box-shadow: none;
  width: 291px;
  height: 39px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 10px;
}
@media (max-width: 1440px) {
  .rectangle17 {
    width: 18vw;
    height: 2.29vw;
    flex-shrink: 0;
  }
}
.milestoneInput {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 15.4px;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  color: #535353;
  min-width: 0;
  min-height: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border: 0px solid #d3d3d3;
}
@media (max-width: 1440px) {
  .milestoneInput {
    font-size: 0.97vw;
  }
}
.freeBox__xqIe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-left: 23px;
  padding: 8px 0px;
}
@media (max-width: 1440px) {
  .freeBox__xqIe {
    height: 100%;
    width: auto;
    justify-content: center;
    align-items: flex-start;
    min-height: 0;
    padding: 0px;
    margin: 0vw 0vw 0vw 2vw;
  }
}
.title22 {
  display: block;
  font-size: 17.5px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: 37px;
  position: relative;
  left: auto;
  top: auto;
  align-self: auto;
  margin-bottom: 10px;
  margin-left: 3px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .title22 {
    font-size: 0.94vw;
    align-self: auto;
    margin-bottom: 0.68vw;
    margin-left: 0.3vw;
    height: 2.6vw;
    flex-shrink: 0;
  }
}
.dateBox {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  width: 181px;
  height: 39px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
}
@media (max-width: 1440px) {
  .dateBox {
    width: 10.8vw;
    height: 2.29vw;
    align-self: auto;
    flex-shrink: 0;
  }
}
.freeBox__cd9S {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-right: 0%;
  margin-left: 23px;
  padding: 8px;
}
@media (max-width: 1440px) {
  .freeBox__cd9S {
    width: auto;
    margin-left: 2vw;
    padding: 0px;
  }
}
.title23 {
  display: block;
  font-size: 17.5px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: 37px;
  position: relative;
  left: auto;
  top: auto;
  align-self: auto;
  margin-bottom: 10px;
  margin-left: 4px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .title23 {
    font-size: 0.94vw;
    margin-bottom: 0.68vw;
    margin-left: 0.4vw;
    height: 2.6vw;
    flex-shrink: 0;
  }
}
.depositRectangle {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.259);
  width: 170px;
  height: 39px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  padding-right: 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
}
@media (max-width: 1440px) {
  .depositRectangle {
    width: 10.03vw;
    height: 2.29vw;
    left: auto;
    top: auto;
    flex-shrink: 0;
  }
}
.deleteMilestone:global(.__wab_instance) {
  position: absolute;
  top: auto;
  left: auto;
  bottom: 34px;
  right: 10px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .deleteMilestone:global(.__wab_instance) {
    bottom: 17.42%;
    right: 1.6%;
    margin-right: 1%;
    flex-shrink: 0;
  }
}
