.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(185, 185, 185, 1);
  width: 100%;
  height: 3.75vw;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  padding-right: 0px;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 0px;
}
.root:hover {
  background: #f7f7f7;
}
.root:active {
  background: #f7f7f7;
  box-shadow: none;
  border: 1px solid #000000;
}
.freeBox__m0IbS {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: calc(3% + 0px) !important;
  margin-right: 0%;
  width: 20%;
  align-self: center;
  justify-content: center;
  flex-direction: row;
  padding-right: 0px;
  align-items: center;
}
.slotTargetClientName {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 140%;
  letter-spacing: 0.3px;
}
.clientEmail {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: calc(0% + 0px) !important;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  width: 20%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}
.slotTargetClientEmail {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__fxRjk {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: calc(0% + 0px) !important;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  width: 20%;
  justify-content: center;
  flex-direction: row;
  padding-left: 0px;
  padding-right: 0px;
  align-items: center;
}
.slotTargetDate {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__e35Lc {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: calc(0% + 0px) !important;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  width: 20%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.slotTargetStatus {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: left;
  line-height: 100%;
  letter-spacing: 0.3px;
}
