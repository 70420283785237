.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 0px;
}
.text__jbx69 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #000000;
  margin-top: calc(10px + 0px) !important;
  margin-bottom: 3%;
  margin-left: 7.5%;
  font-size: 16px;
  align-self: flex-start;
}
.companyNameInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  width: 85%;
  align-self: flex-start;
  margin-left: 7%;
  margin-top: calc(5px + 0px) !important;
  flex-shrink: 0;
}
.text___3P7G3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-left: 7.55%;
  margin-bottom: 3%;
  font-size: 16px;
  margin-top: calc(38px + 0px) !important;
  margin-right: 0px;
  min-width: 0;
}
.contactNameInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  width: 85%;
  align-self: flex-start;
  margin-left: 7%;
  margin-top: calc(5px + 0px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.text__n8SlJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 16px;
  margin-left: 7.5%;
  margin-bottom: 3%;
  margin-top: calc(38px + 0px) !important;
  margin-right: 0px;
  min-width: 0;
}
.emailInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  width: 85%;
  align-self: flex-start;
  margin-left: 7%;
  margin-top: calc(5px + 0px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.text___5UxQc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 16px;
  margin-left: 7.5%;
  margin-bottom: 3%;
  margin-top: calc(38px + 0px) !important;
  margin-right: 0px;
  min-width: 0;
}
.identifier:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  width: 85%;
  align-self: flex-start;
  margin-left: 7%;
  margin-top: calc(5px + 0px) !important;
  margin-right: 0px;
  margin-bottom: 22px;
  flex-shrink: 0;
}
