.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(#ffffff, #ffffff), url("./images/dashboardLoginDarkTheme1Svg.svg"),
    linear-gradient(#ffffff, #ffffff), #ffffff;
  min-width: 0;
  min-height: 0;
}
.dashboardLoginDarkTheme {
  background: #4f45fc;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.frame1 {
  background: rgba(255, 255, 255, 1);
  width: 540px;
  height: 473px;
  display: flex;
  overflow: hidden;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
  margin-bottom: 8%;
  flex-direction: column;
  box-shadow: 4px 4px 16px 4px #00000033;
  flex-shrink: 0;
  border-radius: 5px;
}
.frame1 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 25px);
  height: calc(100% + 25px);
}
.frame1 > :global(.__wab_flex-container) > *,
.frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1 > :global(.__wab_flex-container) > picture > img,
.frame1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 25px;
}
@media (max-width: 1440px) {
  .frame1 {
    margin: 0% 0% 13%;
  }
}
@media (max-width: 1370px) {
  .frame1 {
    width: 460px;
    height: 438px;
    flex-shrink: 0;
    margin: 0% 0% 8%;
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) > *,
  .frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .frame1 > :global(.__wab_flex-container) > picture > img,
  .frame1
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 30px;
  }
}
.text___8AqOq {
  display: block;
  font-size: 35px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(24, 24, 24, 1);
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(40px + 25px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
@media (max-width: 1370px) {
  .text___8AqOq {
    font-size: 34px;
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(40px + 30px) !important;
  }
}
.text__bGPn3 {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.5;
  color: rgba(60, 60, 60, 1);
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  margin-top: calc(6px + 25px) !important;
  padding: 0vw 4vw;
}
@media (max-width: 1370px) {
  .text__bGPn3 {
    font-size: 14px;
    left: auto;
    top: auto;
    margin-top: calc(6px + 30px) !important;
  }
}
.emailBox {
  width: 100%;
  height: 96px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-right: 40px;
  padding-top: 0px;
  margin-top: calc(0px + 25px) !important;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .emailBox {
    margin-top: calc(0px + 25px) !important;
  }
}
@media (max-width: 1370px) {
  .emailBox {
    margin-right: 70px;
    height: 81.6px;
    margin-top: calc(0px + 30px) !important;
    flex-shrink: 0;
  }
}
.rectangle {
  background: rgba(233, 233, 233, 0);
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  width: 370px;
  height: 48px;
  display: flex;
  position: absolute;
  top: auto;
  left: 109px;
  bottom: 1px;
  flex-direction: column;
  border-radius: 10px;
}
@media (max-width: 1370px) {
  .rectangle {
    width: 315px;
    height: 41px;
  }
}
.loginEmailInput:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.text___7TiLo {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(60, 60, 60, 1);
  position: absolute;
  top: 5px;
  left: 120px;
}
@media (max-width: 1370px) {
  .text___7TiLo {
    font-size: 14px;
  }
}
.invalidEmailText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-bottom: -20px;
  margin-top: calc(-5px + 25px) !important;
  align-self: flex-start;
  margin-left: 98px;
  font-size: 15px;
  left: auto;
  top: auto;
}
@media (max-width: 1440px) {
  .invalidEmailText {
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -20px;
    margin-top: calc(-5px + 25px) !important;
    align-self: flex-start;
    margin-left: 98px;
    font-size: 15px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .invalidEmailText {
    font-size: 13.5px;
    margin-left: calc(80px + 0px) !important;
    margin-top: calc(-15px + 30px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -20px;
    align-self: flex-start;
    left: auto;
    top: auto;
  }
}
.emailSuccessText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-bottom: -20px;
  margin-top: calc(-5px + 25px) !important;
  align-self: flex-start;
  margin-left: 98px;
  font-size: 15px;
  left: auto;
  top: auto;
  color: #009e09;
}
@media (max-width: 1440px) {
  .emailSuccessText {
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -20px;
    margin-top: calc(-5px + 25px) !important;
    align-self: flex-start;
    margin-left: 98px;
    font-size: 15px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .emailSuccessText {
    font-size: 13.5px;
    margin-left: calc(80px + 0px) !important;
    margin-top: calc(-15px + 30px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -20px;
    align-self: flex-start;
    left: auto;
    top: auto;
  }
}
.resetPasswordButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: calc(1.5vw + 25px) !important;
  flex-shrink: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: auto;
  display: block !important;
  top: auto;
  left: auto;
}
.img > picture > img {
  object-fit: cover;
}
