.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 999;
  align-self: flex-start;
  height: 100%;
  max-width: none;
  flex-shrink: 0;
  min-height: 0;
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  align-self: flex-start;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 8px 8px 0px;
}
@media (max-width: 1440px) {
  .vertStack {
    height: 100%;
    min-height: 0;
  }
}
.freeBox__zh7Po {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 842px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  left: auto;
  top: auto;
  z-index: 1;
  padding: 0px 8px;
  margin: 50px 0px 0px;
}
@media (max-width: 1440px) {
  .freeBox__zh7Po {
    width: 52vw;
    max-width: none;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 3.5vw;
  }
}
@media (max-width: 1370px) {
  .freeBox__zh7Po {
    width: 53vw;
  }
}
.invoiceDetails {
  box-shadow: inset 0px 0px 1px 1px #cecfd8;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  max-width: 897px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
  margin: 0%;
}
.invoiceDetails > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.invoiceDetails > :global(.__wab_flex-container) > *,
.invoiceDetails > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.invoiceDetails > :global(.__wab_flex-container) > picture > img,
.invoiceDetails
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .invoiceDetails {
    left: auto;
    top: auto;
  }
}
.freeBox__osPlQ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__osPlQ {
    height: auto;
    padding: 0px;
  }
}
.title4 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 30px;
  margin-bottom: 0%;
}
@media (max-width: 1440px) {
  .title4 {
    font-size: 1.2vw;
    margin-top: 2vw;
    margin-bottom: 0%;
  }
}
@media (max-width: 1370px) {
  .title4 {
    font-size: 1.1vw;
  }
}
.title12 {
  display: block;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 2.2;
  color: #1f1f1f;
  width: 86.32%;
  height: 100%;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 20px;
  align-self: center;
  text-align: center;
  margin-bottom: 20px;
  min-height: 0;
}
@media (max-width: 1440px) {
  .title12 {
    font-size: 0.85vw;
    text-align: center;
    line-height: 2.2;
    height: auto;
    width: 85%;
    left: auto;
    top: auto;
    margin: 1.28vw 0%;
  }
}
.freeBox__l7Oxn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__l7Oxn {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
@media (max-width: 1370px) {
  .freeBox__l7Oxn {
    margin-top: calc(2.8vw + 0px) !important;
  }
}
.text__i5UU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__i5UU {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.debtorName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .debtorName {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtorNameError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .debtorNameError {
    margin-left: 3.45vw;
    font-size: 1vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtorNameError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox___62Ure {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox___62Ure {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text___17Wbw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text___17Wbw {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.debtAmount {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .debtAmount {
    margin-left: 0px;
    margin-top: 0px;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtAmountError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .debtAmountError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtAmountError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__yj3Ng {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__yj3Ng {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__w2Bs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  padding-right: 0px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__w2Bs {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.debtAge {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .debtAge {
    margin-left: 0px;
    margin-top: 0px;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtAgeError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .debtAgeError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtAgeError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__hOBhZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__hOBhZ {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__lR5HE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__lR5HE {
    font-size: 1.08vw;
    margin-bottom: 1vw;
    padding-left: 0.1vw;
  }
}
.phoneNumber {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .phoneNumber {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtorPhoneError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .debtorPhoneError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtorPhoneError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__ksf60 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__ksf60 {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__wx7Us {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__wx7Us {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.email {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .email {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtorEmailError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .debtorEmailError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtorEmailError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__pyzFh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__pyzFh {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__vw04C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__vw04C {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.productName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .productName {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.productError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .productError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .productError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__pAPyy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__pAPyy {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__fgcJ5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__fgcJ5 {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.creditorName {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .creditorName {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.creditorNameError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .creditorNameError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .creditorNameError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox___9Pj62 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox___9Pj62 {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__mu1D1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 0px 1px;
}
@media (max-width: 1440px) {
  .text__mu1D1 {
    font-size: 1.08vw;
    margin-left: 0.1vw;
    padding-left: 0.1vw;
  }
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .address1 {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 20px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .city {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    margin-top: 1.4vw;
    flex-shrink: 0;
  }
}
.state:global(.__wab_instance):global(.__wab_instance) {
  position: relative;
  margin-top: 20px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .state:global(.__wab_instance):global(.__wab_instance) {
    width: 23.5vw;
    height: 2.85vw;
    margin-top: 1.4vw;
    flex-shrink: 0;
  }
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 20px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .zip {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    margin-top: 1.4vw;
    flex-shrink: 0;
  }
}
.freeBox__w9Co2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__w9Co2 {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__viAn0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__viAn0 {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.dob {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .dob {
    margin-left: 0px;
    margin-top: 0px;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.freeBox___7ABwR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox___7ABwR {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__g6Xiv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__g6Xiv {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.uploadFiles {
  box-shadow: inset 0px 0px 0px 1px #a5a4a4c2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 47.5px;
  max-width: 100%;
  cursor: pointer;
  background: #ffffff;
  flex-shrink: 0;
  border-radius: 5px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .uploadFiles {
    width: 11vw;
    height: 3vw;
    flex-shrink: 0;
  }
}
.root .uploadFiles:hover {
  background: #dde2fa40;
}
.text___8OsFv {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 0px;
  margin: 0px;
}
@media (max-width: 1440px) {
  .text___8OsFv {
    margin-left: 0.85vw;
    font-size: 1.06vw;
  }
}
.text__ejWpb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__ejWpb {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.freeBox__pgVjn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  min-width: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__pgVjn {
    margin-top: 0px;
    margin-left: 0px;
  }
}
.freeBox__cqEzK {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__cqEzK {
    margin-top: 0px;
    margin-left: 0px;
  }
}
.downloadFiles {
  box-shadow: inset 0px 0px 0px 1px #a5a4a4c2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 47.5px;
  max-width: 100%;
  cursor: pointer;
  background: #ffffff;
  flex-shrink: 0;
  border-radius: 5px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .downloadFiles {
    width: 11vw;
    height: 3vw;
    flex-shrink: 0;
  }
}
.root .downloadFiles:hover {
  background: #dde2fa40;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 12%;
}
.img > picture > img {
  object-fit: cover;
}
.text__srItP {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 0px;
  margin: 0px 0px 0px 8px;
}
@media (max-width: 1440px) {
  .text__srItP {
    margin-left: 0.85vw;
    font-size: 1.06vw;
  }
}
.freeBox__hGiUt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  margin-bottom: 40px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__hGiUt {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__mupDv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__mupDv {
    padding-left: 0.1vw;
    margin-bottom: 1vw;
    font-size: 1.08vw;
  }
}
.debtSituation {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  position: relative;
  width: 390px;
  height: 120px;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 7px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .debtSituation {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.97vw;
    width: 23.5vw;
    height: 8vw;
    flex-shrink: 0;
    padding: 0.5vw 0.2vw 0.2vw 0.8vw;
  }
}
.errorText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ff0000;
  font-weight: 500;
  font-size: 16px;
  margin-top: 8%;
  margin-bottom: -5%;
}
@media (max-width: 1440px) {
  .errorText {
    margin-top: 10%;
    margin-bottom: -5%;
    font-size: 1.1vw;
  }
}
.submitButton {
  width: 216px;
  height: 54px;
  background: #14377f;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 100px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .submitButton {
    width: 216px;
    height: 54px;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    background: #048b68;
    flex-shrink: 0;
  }
}
.root .submitButton:hover {
  background: #284379d4;
}
.root .submitButton:active {
  background: #284379d4;
  border-width: 1px;
  border-style: solid;
}
.text__wFfw1 {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .text__wFfw1 {
    font-size: 1.15vw;
  }
}
