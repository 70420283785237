.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.freeBox___0CEk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-bottom: 11px;
  padding: 0px;
}
.slotTargetMilestoneNumber {
  font-family: "Open Sans", sans-serif;
  font-size: 1.4vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__tYdj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-left: 5%;
  padding: 0px;
}
.freeBox__f15Mm {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 4%;
  flex-direction: row;
}
.slotTargetMilestoneTitle {
  font-family: "Open Sans", sans-serif;
  font-size: 1.05vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__s1Wd {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 4%;
  margin-top: 4%;
  flex-direction: row;
}
.slotTargetMilestoneAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__lp25R {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 4%;
  margin-top: 4%;
  margin-bottom: 4%;
  flex-direction: row;
}
.slotTargetMilestoneStatus {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
