.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 0px;
}
.topBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 16.5%;
  align-self: auto;
}
.figmaPaste {
  display: block;
  position: relative;
  width: 85%;
  height: 18%;
  margin-top: 0.5%;
  border-bottom: 1px solid #b0aeae;
}
.freeBox___2FdKd {
  display: flex;
  position: absolute;
  left: 5.34%;
  top: 46.99%;
}
.slotTargetPaymentAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 1.9vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.paymentStatusStack {
  background: #7bc66000;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  border-radius: 0px;
}
.notPaidStatusCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.freeBox__wJLy3 {
  display: flex;
  position: absolute;
  left: 5.27%;
  top: 13.66%;
}
.slotTargetPaymentType {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.optionStack {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: auto;
  height: 100%;
  max-width: 100%;
  top: 0%;
  left: auto;
  right: 0%;
  min-height: 0;
  padding: 0px;
}
.optionStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.optionStack > :global(.__wab_flex-container) > *,
.optionStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.optionStack > :global(.__wab_flex-container) > picture > img,
.optionStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.stopAutomation {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(128, 128, 128, 1);
  width: 7.8vw;
  height: 2.1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: calc(0% + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
}
.root .stopAutomation:hover {
  background: #efefef;
  margin-top: calc(0% + 0px) !important;
}
.root .stopAutomation:active {
  box-shadow: inset 0px 0px 0px 1px #000000;
  background: #efefef;
  margin-top: calc(0% + 0px) !important;
}
.text__epcVa {
  display: block;
  font-size: 0.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  padding-bottom: 0px;
}
.editSubscription {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(128, 128, 128, 1);
  width: 7.8vw;
  height: 2.1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: calc(0% + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
}
.root .editSubscription:hover {
  background: #efefef;
  margin-top: calc(0% + 0px) !important;
}
.root .editSubscription:active {
  box-shadow: inset 0px 0px 0px 1px #000000;
  background: #efefef;
  margin-top: calc(0% + 0px) !important;
}
.text__cb3Ud {
  display: block;
  font-size: 0.78vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  padding-bottom: 0px;
}
.voidPayment {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(128, 128, 128, 1);
  width: 7.8vw;
  height: 2.1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: calc(15% + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
}
.root .voidPayment:hover {
  background: #efefef;
  margin-top: calc(15% + 0px) !important;
}
.root .voidPayment:active {
  box-shadow: inset 0px 0px 0px 1px #000000;
  background: #efefef;
  margin-top: calc(15% + 0px) !important;
}
.freeBox__kG6T {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
}
.slotTargetCancelType2 {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.markAsPaid {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(128, 128, 128, 1);
  width: 7.8vw;
  height: 2.1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: calc(15% + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
}
.root .markAsPaid:hover {
  background: #efefef;
  margin-top: calc(15% + 0px) !important;
}
.root .markAsPaid:active {
  box-shadow: inset 0px 0px 0px 1px #000000;
  background: #efefef;
  margin-top: calc(15% + 0px) !important;
}
.freeBox__elUp {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
}
.slotTargetCancelType3 {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.refundButton {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(128, 128, 128, 1);
  width: 7.8vw;
  height: 2.1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: calc(15% + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
}
.root .refundButton:hover {
  background: #efefef;
  margin-top: calc(15% + 0px) !important;
}
.root .refundButton:active {
  box-shadow: inset 0px 0px 0px 1px #000000;
  background: #efefef;
  margin-top: calc(15% + 0px) !important;
}
.text___343Qw {
  display: block;
  font-size: 0.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
.receiptButton {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(128, 128, 128, 1);
  width: 7.8vw;
  height: 2.1vw;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: auto;
  cursor: pointer;
  margin-top: calc(15% + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
}
.root .receiptButton:hover {
  background: #efefef;
  left: auto;
  top: auto;
  right: auto;
  margin-top: calc(15% + 0px) !important;
}
.root .receiptButton:active {
  background: #efefef;
  box-shadow: inset 0px 0px 0px 1px #000000;
  left: auto;
  top: auto;
  right: auto;
  margin-top: calc(15% + 0px) !important;
}
.text__uTfu {
  display: block;
  font-size: 0.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
.freeBox__eZhl1 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 12.4%;
  max-width: 100%;
  background: #44c0ff00;
  padding: 0px;
}
.freeBox__sePoH {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__lzISu {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(98, 98, 98, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
}
.freeBox__gxhXo {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
  margin-top: 7%;
  flex-direction: row;
}
.slotTargetCreatedDate1 {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox___9Bqwr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1%;
  height: 80%;
  max-width: 100%;
  background: #44c0ff00;
  padding: 0px;
  border-left: 1px solid #b0aeae;
}
.freeBox__m6KKt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__rEAiM {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(98, 98, 98, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
}
.freeBox__kidF6 {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
  margin-top: 7%;
  flex-direction: row;
}
.slotTargetClientCompany {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__tPoU2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1%;
  height: 80%;
  max-width: 100%;
  background: #44c0ff00;
  padding: 0px;
  border-left: 1px solid #b0aeae;
}
.freeBox___2FOy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__saJ0 {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(98, 98, 98, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
}
.freeBox__pXAre {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
  margin-top: 7%;
  flex-direction: row;
}
.slotTargetPaymentMethod {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.text__cdZxn {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  left: 13.78%;
  top: 58.69%;
  height: auto;
}
.title {
  display: block;
  font-size: 1.4vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: absolute;
  left: 50.83%;
  top: 56.93%;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #b0aeae;
  transform: skew(-0.057295760414500616deg, 0deg);
  width: 15vw;
  height: 27px;
  display: block;
  transform-origin: top left;
  left: 12.35%;
  top: 62.5%;
  flex-shrink: 0;
}
.freeBox___6Y2L0 {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: auto;
  height: auto;
  left: 14.01%;
  top: 85.16%;
}
.slotTargetPaymentSuccessDate {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1vw;
  font-weight: 400;
  color: #3b3b3b;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.paymentLinkStack {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: none;
  top: 66.8%;
  left: 13.72%;
  flex-shrink: 1;
  cursor: default;
  padding: 0px;
}
.paymentLink {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3555ff;
  width: auto;
  height: auto;
  cursor: pointer;
}
.paymentLinkImage {
  position: relative;
  object-fit: cover;
  max-width: 4%;
  height: 4%;
  cursor: pointer;
  margin: 0px 0px 0px 8px;
}
.paymentLinkImage > picture > img {
  object-fit: cover;
}
.freeBox__aPbo4 {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: auto;
  height: auto;
  left: 13.78%;
  top: 73.34%;
}
.slotTargetCreatedDate2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1vw;
  font-weight: 400;
  color: #3b3b3b;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.text__wj9Qv {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: auto;
  height: auto;
  position: absolute;
  left: 13.84%;
  top: 79.2%;
}
.rectangle50 {
  background: rgba(255, 255, 255, 1);
  box-shadow: none;
  width: 33vw;
  height: 14vw;
  display: flex;
  position: absolute;
  left: 50.48%;
  top: 62.89%;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 5px;
}
.paymentNotes {
  box-shadow: 0px 0px 0px 1px #b1b1b1;
  font-size: 16px;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  margin-top: 0px;
  min-width: 0;
  min-height: 0;
  border-radius: 5px;
  padding: 12px 2px 2px 15px;
  border: 0px solid #d3d3d3;
}
