.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  background: #a2a2a200;
  position: sticky;
  min-width: 0;
  border-radius: 6px;
  border: 1px solid #dbdbd700;
}
.rootcolor_dark {
  background: #232320;
  border-color: #717069;
}
.startIconContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-right: 8px;
  display: none;
}
.startIconContainershowStartIcon {
  display: flex;
}
.slotTargetStartIconshowStartIcon {
  color: #90908c;
}
.slotTargetStartIconcolor_dark {
  color: #ffffff;
}
.svg__jOByE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.appId {
  width: 100%;
  left: auto;
  top: auto;
  background: #ffffff;
  color: #000000;
  height: 45px;
  margin-right: 0px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px 6px 0px 4px;
  border-width: 1px;
}
@media (max-width: 1440px) {
  .appId {
    font-size: 1vw;
  }
}
.appIdisDisabled {
  cursor: not-allowed;
}
.appIdcolor_dark {
  color: #ffffff;
}
.root .appId:focus {
  outline: none;
}
.root .appId::placeholder {
  color: #706f6c;
}
.rootcolor_dark .appIdcolor_dark::placeholder {
  color: #c8c7c1;
}
.endIconContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-left: 8px;
  display: none;
}
.endIconContainershowEndIcon {
  display: flex;
}
.slotTargetEndIconshowEndIcon {
  color: #90908c;
}
.slotTargetEndIconcolor_dark {
  color: #ffffff;
}
.svg__wy83Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
