.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 999;
  align-self: flex-start;
  height: 100%;
  max-width: none;
  flex-shrink: 0;
  min-height: 0;
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  align-self: flex-start;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 8px 8px 0px;
}
@media (max-width: 1440px) {
  .vertStack {
    height: 100%;
    min-height: 0;
  }
}
.freeBox__t5HHg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 842px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  left: auto;
  top: auto;
  z-index: 1;
  padding: 0px 8px;
  margin: 50px 0px 0px;
}
@media (max-width: 1440px) {
  .freeBox__t5HHg {
    width: 52vw;
    max-width: none;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 3.5vw;
  }
}
@media (max-width: 1370px) {
  .freeBox__t5HHg {
    width: 53vw;
  }
}
.invoiceDetails {
  box-shadow: inset 0px 0px 1px 1px #cecfd8;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  max-width: 897px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
  margin: 0px 0px 100px;
}
.invoiceDetails > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.invoiceDetails > :global(.__wab_flex-container) > *,
.invoiceDetails > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.invoiceDetails > :global(.__wab_flex-container) > picture > img,
.invoiceDetails
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .invoiceDetails {
    left: auto;
    top: auto;
  }
}
.freeBox__xfOxy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__xfOxy {
    height: auto;
    padding: 8px;
  }
}
.title4 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 45px;
}
@media (max-width: 1440px) {
  .title4 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1370px) {
  .title4 {
    font-size: 1.1vw;
  }
}
.createSettlementButton {
  width: 190px;
  height: 45px;
  background: #048b68;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 176px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .createSettlementButton {
    width: 13.2vw;
    height: 3.1vw;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: #048b68;
    left: auto;
    top: auto;
    right: auto;
    margin-left: 10vw;
    flex-shrink: 0;
  }
}
.root .createSettlementButton:hover {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
}
.root .createSettlementButton:active {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
.root .createSettlementButton:hover {
  left: auto;
  top: auto;
  right: auto;
}
.root .createSettlementButton:active {
  left: auto;
  top: auto;
  right: auto;
}
@media (max-width: 1440px) {
  .root .createSettlementButton:hover {
    left: auto;
    top: auto;
    right: auto;
  }
}
@media (max-width: 1440px) {
  .root .createSettlementButton:active {
    left: auto;
    top: auto;
    right: auto;
  }
}
.text__hu4EQ {
  display: block;
  font-size: 13.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .text__hu4EQ {
    font-size: 0.94vw;
  }
}
.createSubscriptionButton {
  width: 195px;
  height: 45px;
  background: #048b68;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 29px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .createSubscriptionButton {
    width: 13.55vw;
    height: 3.1vw;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: #048b68;
    left: auto;
    top: auto;
    right: auto;
    margin-left: 1.5vw;
    flex-shrink: 0;
  }
}
.root .createSubscriptionButton:hover {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
}
.root .createSubscriptionButton:active {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
@media (max-width: 1440px) {
  .root .createSubscriptionButton:hover {
    left: auto;
    top: auto;
    right: auto;
  }
}
@media (max-width: 1440px) {
  .root .createSubscriptionButton:active {
    left: auto;
    top: auto;
    right: auto;
  }
}
.text__d9UVm {
  display: block;
  font-size: 13.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .text__d9UVm {
    font-size: 0.94vw;
  }
}
.freeBox___7X0S {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox___7X0S {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
@media (max-width: 1370px) {
  .freeBox___7X0S {
    margin-top: calc(2.8vw + 0px) !important;
  }
}
.text__v8NEg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__v8NEg {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.debtorName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .debtorName {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtorNameError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .debtorNameError {
    margin-left: 3.45vw;
    font-size: 1vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtorNameError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__p1Wiw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__p1Wiw {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__k58M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__k58M {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.debtAmount {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .debtAmount {
    margin-left: 0px;
    margin-top: 0px;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtAmountError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .debtAmountError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtAmountError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__noMfY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__noMfY {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__oL3P7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  padding-right: 0px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__oL3P7 {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.debtAge {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .debtAge {
    margin-left: 0px;
    margin-top: 0px;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtAgeError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .debtAgeError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtAgeError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__vqzqh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__vqzqh {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__ywaAa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__ywaAa {
    font-size: 1.08vw;
    margin-bottom: 1vw;
    padding-left: 0.1vw;
  }
}
.phoneNumber {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .phoneNumber {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtorPhoneError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .debtorPhoneError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtorPhoneError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__pPNdY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__pPNdY {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__vgtB3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__vgtB3 {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.email {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .email {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.debtorEmailError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .debtorEmailError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .debtorEmailError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__tmNy3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__tmNy3 {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__qqmXc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__qqmXc {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.productName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .productName {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.productError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .productError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .productError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__vecty {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__vecty {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__sbrh8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__sbrh8 {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.creditorName {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .creditorName {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.creditorNameError {
  margin-left: 50px;
  margin-top: calc(6px + 0px) !important;
  margin-bottom: -5px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .creditorNameError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .creditorNameError {
    font-size: 1vw;
    margin-left: 3.45vw;
    margin-top: calc(6px + 0px) !important;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-right: 0px;
  }
}
.freeBox__nywvd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__nywvd {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__ydAXk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 0px 1px;
}
@media (max-width: 1440px) {
  .text__ydAXk {
    font-size: 1.08vw;
    margin-left: 0.1vw;
    padding-left: 0.1vw;
  }
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .address1 {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 20px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .city {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    margin-top: 1.4vw;
    flex-shrink: 0;
  }
}
.state:global(.__wab_instance):global(.__wab_instance) {
  position: relative;
  margin-top: 20px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .state:global(.__wab_instance):global(.__wab_instance) {
    width: 23.5vw;
    height: 2.85vw;
    margin-top: 1.4vw;
    flex-shrink: 0;
  }
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-top: 20px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .zip {
    font-size: 0.97vw;
    width: 23.5vw;
    height: 2.85vw;
    margin-top: 1.4vw;
    flex-shrink: 0;
  }
}
.freeBox__lPQk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__lPQk {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__t12G8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__t12G8 {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.dob {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .dob {
    margin-left: 0px;
    margin-top: 0px;
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.freeBox__uPkV {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__uPkV {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__atO1K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__atO1K {
    font-size: 1.08vw;
    padding-left: 0.1vw;
    margin-bottom: 1vw;
  }
}
.freeBox___3RxM {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  min-width: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox___3RxM {
    margin-top: 0px;
    margin-left: 0px;
  }
}
.freeBox__ymJub {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__ymJub {
    margin-top: 0px;
    margin-left: 0px;
  }
}
.downloadFiles {
  box-shadow: inset 0px 0px 0px 1px #a5a4a4c2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 47.5px;
  max-width: 100%;
  cursor: pointer;
  background: #ffffff;
  flex-shrink: 0;
  border-radius: 5px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .downloadFiles {
    width: 11vw;
    height: 3vw;
    flex-shrink: 0;
  }
}
.root .downloadFiles:hover {
  background: #dde2fa40;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 12%;
}
.img > picture > img {
  object-fit: cover;
}
.text__uCjlI {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 0px;
  margin: 0px 0px 0px 8px;
}
@media (max-width: 1440px) {
  .text__uCjlI {
    margin-left: 0.85vw;
    font-size: 1.06vw;
  }
}
.freeBox__szBt5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  margin-bottom: 42px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__szBt5 {
    margin-top: calc(2.8vw + 0px) !important;
    margin-left: 3.45vw;
  }
}
.text__wxl5Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 1px;
  min-width: 0;
  margin: 0px 0px 15px;
}
@media (max-width: 1440px) {
  .text__wxl5Z {
    padding-left: 0.1vw;
    margin-bottom: 1vw;
    font-size: 1.08vw;
  }
}
.debtSituation {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  position: relative;
  width: 390px;
  height: 120px;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 7px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .debtSituation {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.97vw;
    width: 23.5vw;
    height: 8vw;
    flex-shrink: 0;
    padding: 0.5vw 0.2vw 0.2vw 0.8vw;
  }
}
.freeBox__kretP {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  align-self: center;
  box-shadow: 0px 0px 1px 1px #cecfd8e6;
  margin-bottom: 100px;
  min-width: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__kretP {
    height: auto;
    padding: 8px;
  }
}
.creditorName3 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: 506px;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 45px;
}
@media (max-width: 1440px) {
  .creditorName3 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1370px) {
  .creditorName3 {
    font-size: 1.1vw;
  }
}
.step1Stack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.creditorName32 {
  display: block;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 40px;
}
@media (max-width: 1440px) {
  .creditorName32 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .creditorName32 {
    font-size: 1.1vw;
    left: auto;
    top: auto;
  }
}
.step1 {
  display: block;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}
@media (max-width: 1440px) {
  .step1 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .step1 {
    font-size: 1.1vw;
    left: auto;
    top: auto;
  }
}
.createEmail {
  width: 157px;
  height: 41px;
  background: #048b68;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .createEmail {
    width: 11vw;
    height: 3.1vw;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: #048b68;
    left: auto;
    top: auto;
    right: auto;
    margin-left: 1.5vw;
    flex-shrink: 0;
  }
}
.root .createEmail:hover {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
}
.root .createEmail:active {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
.root .createEmail:hover {
  left: auto;
  top: auto;
  right: auto;
}
.root .createEmail:active {
  left: auto;
  top: auto;
  right: auto;
}
@media (max-width: 1440px) {
  .root .createEmail:hover {
    left: auto;
    top: auto;
    right: auto;
  }
}
@media (max-width: 1440px) {
  .root .createEmail:active {
    left: auto;
    top: auto;
    right: auto;
  }
}
.text__zgbUm {
  display: block;
  font-size: 13.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .text__zgbUm {
    font-size: 0.94vw;
  }
}
.step2Stack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 40px;
  min-width: 0;
  padding: 0px;
}
.creditorName34 {
  display: block;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 40px;
}
@media (max-width: 1440px) {
  .creditorName34 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .creditorName34 {
    font-size: 1.1vw;
    left: auto;
    top: auto;
  }
}
.step2 {
  display: block;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}
@media (max-width: 1440px) {
  .step2 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .step2 {
    font-size: 1.1vw;
    left: auto;
    top: auto;
  }
}
.step3Stack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  min-width: 0;
  padding: 0px;
}
.creditorName33 {
  display: block;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 40px;
}
@media (max-width: 1440px) {
  .creditorName33 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .creditorName33 {
    font-size: 1.1vw;
    left: auto;
    top: auto;
  }
}
.step3 {
  display: block;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}
@media (max-width: 1440px) {
  .step3 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .step3 {
    font-size: 1.1vw;
    left: auto;
    top: auto;
  }
}
.enrollDebtors {
  width: 167px;
  height: 41px;
  background: #048b68;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .enrollDebtors {
    width: 11vw;
    height: 3.1vw;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: #048b68;
    left: auto;
    top: auto;
    right: auto;
    margin-left: 1.5vw;
    flex-shrink: 0;
  }
}
.root .enrollDebtors:hover {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
}
.root .enrollDebtors:active {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
.root .enrollDebtors:hover {
  left: auto;
  top: auto;
  right: auto;
}
.root .enrollDebtors:active {
  left: auto;
  top: auto;
  right: auto;
}
@media (max-width: 1440px) {
  .root .enrollDebtors:hover {
    left: auto;
    top: auto;
    right: auto;
  }
}
@media (max-width: 1440px) {
  .root .enrollDebtors:active {
    left: auto;
    top: auto;
    right: auto;
  }
}
.text__kas8I {
  display: block;
  font-size: 13.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .text__kas8I {
    font-size: 0.94vw;
  }
}
.freeBox__k6UeS {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  align-self: center;
  box-shadow: 0px 0px 1px 1px #cecfd8e6;
  margin-bottom: 100px;
  min-width: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__k6UeS {
    height: auto;
    padding: 8px;
  }
}
.creditorName2 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: 506px;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 45px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .creditorName2 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1370px) {
  .creditorName2 {
    font-size: 1.1vw;
  }
}
.sendCreditorPayout {
  width: 224px;
  height: 50px;
  background: #048b68;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .sendCreditorPayout {
    width: 13.55vw;
    height: 3.1vw;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: #048b68;
    left: auto;
    top: auto;
    right: auto;
    margin-left: 1.5vw;
    flex-shrink: 0;
  }
}
.root .sendCreditorPayout:hover {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
}
.root .sendCreditorPayout:active {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
@media (max-width: 1440px) {
  .root .sendCreditorPayout:hover {
    left: auto;
    top: auto;
    right: auto;
  }
}
@media (max-width: 1440px) {
  .root .sendCreditorPayout:active {
    left: auto;
    top: auto;
    right: auto;
  }
}
.text___9HUie {
  display: block;
  font-size: 13.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .text___9HUie {
    font-size: 0.94vw;
  }
}
.freeBox__ynOgL {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  align-self: center;
  box-shadow: 0px 0px 1px 1px #cecfd8e6;
  margin-bottom: 100px;
  min-width: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__ynOgL {
    height: auto;
    padding: 8px;
  }
}
.creditorName22 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: 506px;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 45px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .creditorName22 {
    font-size: 1.2vw;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1370px) {
  .creditorName22 {
    font-size: 1.1vw;
  }
}
.pauseAiResponses {
  width: 224px;
  height: 50px;
  background: #048b68;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .pauseAiResponses {
    width: 13.55vw;
    height: 3.1vw;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: #048b68;
    left: auto;
    top: auto;
    right: auto;
    margin-left: 1.5vw;
    flex-shrink: 0;
  }
}
.root .pauseAiResponses:hover {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
}
.root .pauseAiResponses:active {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
@media (max-width: 1440px) {
  .root .pauseAiResponses:hover {
    left: auto;
    top: auto;
    right: auto;
  }
}
@media (max-width: 1440px) {
  .root .pauseAiResponses:active {
    left: auto;
    top: auto;
    right: auto;
  }
}
.text___6UrdK {
  display: block;
  font-size: 13.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .text___6UrdK {
    font-size: 0.94vw;
  }
}
