.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #f1f3fd;
  min-width: 0;
  min-height: 0;
}
.previewBody {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__ozU8P {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.rectangle28 {
  background: #ffffff;
  width: 505px;
  height: 267px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 16px 0px #0000000d;
  flex-shrink: 0;
  border-radius: 10px;
}
.freeBox___6VyCs {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 65px;
  padding: 0px;
}
.text {
  width: auto;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 30px;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #43a047;
  width: 30px;
  height: 24.7px;
  margin-left: 6px;
  flex-shrink: 0;
}
.freeBox__fYYqK {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}
.slotTargetDate {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #000000;
}
