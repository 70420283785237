.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #f6f7fb17;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 179px;
  margin-top: 50px;
  height: 90px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
@media (max-width: 1370px) {
  .img {
    margin-top: 35px;
  }
}
@media (max-width: 414px) {
  .img {
    width: 150px;
    margin-top: 30px;
  }
}
.text__z3If1 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #181818;
  margin-top: 30px;
  font-size: 38px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 1370px) {
  .text__z3If1 {
    margin-top: 25px;
  }
}
@media (max-width: 414px) {
  .text__z3If1 {
    font-size: 28px;
    margin-top: 20px;
  }
}
.freeBox__ofkpt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 37px;
  padding: 0px;
}
@media (max-width: 414px) {
  .freeBox__ofkpt {
    width: 330px;
    margin-top: 29px;
  }
}
.text__yNhp9 {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .text__yNhp9 {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 414px) {
  .text__yNhp9 {
    font-size: 13.7px;
  }
}
.signupEmailInput:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
@media (max-width: 1370px) {
  .signupEmailInput:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .signupEmailInput:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.invalidEmailText {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  color: #ff0000;
  font-size: 15px;
  margin: 17px 0px 0px 11px;
}
@media (max-width: 414px) {
  .invalidEmailText {
    font-size: 13.7px;
  }
}
.freeBox___9RiqD {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 400px;
  margin-top: 30px;
  padding: 0px;
}
@media (max-width: 414px) {
  .freeBox___9RiqD {
    width: 330px;
  }
}
.text__y1WuN {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .text__y1WuN {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 414px) {
  .text__y1WuN {
    font-size: 13.7px;
  }
}
.signupPasswordInput:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
@media (max-width: 414px) {
  .signupPasswordInput:global(.__wab_instance) {
    width: 330px;
    flex-shrink: 0;
  }
}
.customErrorInstance:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 17px;
  margin-right: 0px;
  margin-left: 11px;
}
.text__a42CU {
  color: #ff0000;
  font-size: 15px;
  line-height: 1.6;
}
@media (max-width: 414px) {
  .text__a42CU {
    font-size: 13.7px;
  }
}
.freeBox__svpS7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 37px;
  padding: 0px;
}
.checkAgree:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 20px;
  margin-left: 0%;
  margin-top: 0%;
  margin-bottom: 21px;
  width: 24px;
  flex-shrink: 0;
}
@media (max-width: 414px) {
  .checkAgree:global(.__wab_instance) {
    margin-top: 0px;
    flex-shrink: 0;
  }
}
.text___6F81C {
  position: relative;
  width: 351px;
  height: auto;
  max-width: 800px;
  font-size: 15px;
  color: #000000;
  margin-left: 9px;
  margin-top: 0%;
  letter-spacing: normal;
  line-height: 1.6;
  flex-shrink: 0;
}
@media (max-width: 1370px) {
  .text___6F81C {
    font-size: 13.5px;
    width: 351px;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .text___6F81C {
    font-size: 12.5px;
    margin-top: -6px;
    width: 320px;
    margin-left: 3px;
    flex-shrink: 0;
  }
}
.link__yqwn9 {
  text-decoration-line: none;
  color: #14377f;
}
.link__gWYiV {
  text-decoration-line: none;
  color: #14377f;
}
.signupButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 57px;
  max-width: 100%;
  background: #14377f;
  cursor: pointer;
  position: relative;
  margin-top: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 414px) {
  .signupButton {
    width: 340px;
    height: 52px;
    margin-top: 30px;
    margin-bottom: 40px;
    flex-shrink: 0;
  }
}
.root .signupButton:hover {
  background: #284379d4;
}
.root .signupButton:active {
  background: #284379d4;
  border: 1px solid #ffffff;
}
.text__adVgB {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
@media (max-width: 414px) {
  .text__adVgB {
    font-size: 17px;
  }
}
