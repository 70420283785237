.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__eqal5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__muxD6 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__kaiFk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox__zxKph {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__x8AkQ {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__vjnCh {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__hajuQ {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__bZaVg {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__bFcsY {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__ooAfy {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__wu176 {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__r0F3 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__boaUl {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__ls3Am {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.freeBox__wp92P {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__wp92P > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__wp92P > :global(.__wab_flex-container) > *,
.freeBox__wp92P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wp92P > :global(.__wab_flex-container) > picture > img,
.freeBox__wp92P
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__g9Az6 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__kai1 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__nNm9R {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__lXLm {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox___16KIj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 1380px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.text__tTgzP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 500;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 40px 0px 20px;
}
.freeBox__w2HCn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__i0085 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text__oitrl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__etqid {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text__vkGuq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__os4Eb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__dlbY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.legalBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__vmT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___0JMxr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.displayBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox___2Sx9E {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___8Zk7I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.textInput {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__vcecr:global(.__wab_instance) {
  position: relative;
}
.option__zbzjp:global(.__wab_instance) {
  position: relative;
}
.option__cN60C:global(.__wab_instance) {
  position: relative;
}
.option__ywx1O:global(.__wab_instance) {
  position: relative;
}
.option___24ZX:global(.__wab_instance) {
  position: relative;
}
.option___1WZgd:global(.__wab_instance) {
  position: relative;
}
.option__w962:global(.__wab_instance) {
  position: relative;
}
.option__ftPb7:global(.__wab_instance) {
  position: relative;
}
.option__g3BS:global(.__wab_instance) {
  position: relative;
}
.option__dJRwK:global(.__wab_instance) {
  position: relative;
}
.option__y6Rp:global(.__wab_instance) {
  position: relative;
}
.option__gy4If:global(.__wab_instance) {
  position: relative;
}
.option__duef7:global(.__wab_instance) {
  position: relative;
}
.option___02AuR:global(.__wab_instance) {
  position: relative;
}
.option__r1BRc:global(.__wab_instance) {
  position: relative;
}
.option__oel69:global(.__wab_instance) {
  position: relative;
}
.option__rD8UQ:global(.__wab_instance) {
  position: relative;
}
.option__uazi0:global(.__wab_instance) {
  position: relative;
}
.option__u5KDs:global(.__wab_instance) {
  position: relative;
}
.option___0JoAj:global(.__wab_instance) {
  position: relative;
}
.option__zW0Ss:global(.__wab_instance) {
  position: relative;
}
.option___4Vhvu:global(.__wab_instance) {
  position: relative;
}
.option__qxOeJ:global(.__wab_instance) {
  position: relative;
}
.option__i9SaB:global(.__wab_instance) {
  position: relative;
}
.option__zob0U:global(.__wab_instance) {
  position: relative;
}
.option__kyZd4:global(.__wab_instance) {
  position: relative;
}
.option__srl5G:global(.__wab_instance) {
  position: relative;
}
.option__s2FY:global(.__wab_instance) {
  position: relative;
}
.option__meQXc:global(.__wab_instance) {
  position: relative;
}
.option__bkd0I:global(.__wab_instance) {
  position: relative;
}
.option___4LE:global(.__wab_instance) {
  position: relative;
}
.option___137Ia:global(.__wab_instance) {
  position: relative;
}
.option__lPyuZ:global(.__wab_instance) {
  position: relative;
}
.option__lgFrU:global(.__wab_instance) {
  position: relative;
}
.option__kHyeu:global(.__wab_instance) {
  position: relative;
}
.option__xh2SI:global(.__wab_instance) {
  position: relative;
}
.option__ttLe8:global(.__wab_instance) {
  position: relative;
}
.option___2Ror:global(.__wab_instance) {
  position: relative;
}
.option__jInFk:global(.__wab_instance) {
  position: relative;
}
.option___2VBXu:global(.__wab_instance) {
  position: relative;
}
.option__pWsdg:global(.__wab_instance) {
  position: relative;
}
.option__phXpC:global(.__wab_instance) {
  position: relative;
}
.option__yrnI:global(.__wab_instance) {
  position: relative;
}
.option__bd6No:global(.__wab_instance) {
  position: relative;
}
.option__wUzNz:global(.__wab_instance) {
  position: relative;
}
.option__osAv7:global(.__wab_instance) {
  position: relative;
}
.option__snyQe:global(.__wab_instance) {
  position: relative;
}
.option__fcJlL:global(.__wab_instance) {
  position: relative;
}
.option__zivcx:global(.__wab_instance) {
  position: relative;
}
.option__lliLm:global(.__wab_instance) {
  position: relative;
}
.option__thiRt:global(.__wab_instance) {
  position: relative;
}
.option__fbyNg:global(.__wab_instance) {
  position: relative;
}
.option__qrjnL:global(.__wab_instance) {
  position: relative;
}
.option__yt6Yb:global(.__wab_instance) {
  position: relative;
}
.option__bjqip:global(.__wab_instance) {
  position: relative;
}
.option__i3Zkk:global(.__wab_instance) {
  position: relative;
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__kj4K2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__rXwBo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.selectIndustry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  margin-left: 0px;
  flex-shrink: 0;
}
.option__s3Tjy:global(.__wab_instance) {
  position: relative;
}
.option___2YtL2:global(.__wab_instance) {
  position: relative;
}
.option__ksWl4:global(.__wab_instance) {
  position: relative;
}
.option__hlaOe:global(.__wab_instance) {
  position: relative;
}
.option__t2UJr:global(.__wab_instance) {
  position: relative;
}
.option__qYott:global(.__wab_instance) {
  position: relative;
}
.option__e1InP:global(.__wab_instance) {
  position: relative;
}
.option__cgevM:global(.__wab_instance) {
  position: relative;
}
.option__zzdU9:global(.__wab_instance) {
  position: relative;
}
.option__q5Gt9:global(.__wab_instance) {
  position: relative;
}
.option__ydFk6:global(.__wab_instance) {
  position: relative;
}
.option___5Sk6Z:global(.__wab_instance) {
  position: relative;
}
.option___3KtOo:global(.__wab_instance) {
  position: relative;
}
.option__mJq48:global(.__wab_instance) {
  position: relative;
}
.option__tba8B:global(.__wab_instance) {
  position: relative;
}
.option__s2SqL:global(.__wab_instance) {
  position: relative;
}
.option___9JdEv:global(.__wab_instance) {
  position: relative;
}
.option__z1MLn:global(.__wab_instance) {
  position: relative;
}
.option__pamTo:global(.__wab_instance) {
  position: relative;
}
.option__mhIkh:global(.__wab_instance) {
  position: relative;
}
.option__tIoVu:global(.__wab_instance) {
  position: relative;
}
.option__ldtoi:global(.__wab_instance) {
  position: relative;
}
.option__kWoRe:global(.__wab_instance) {
  position: relative;
}
.option___1XghI:global(.__wab_instance) {
  position: relative;
}
.option___5Pawd:global(.__wab_instance) {
  position: relative;
}
.freeBox__zNUv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__d1Yxc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.freeBox__poet9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__hkVnz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.continueButton:global(.__wab_instance) {
  position: relative;
  margin-top: 51px;
  flex-shrink: 0;
}
