.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #f6f7fb17;
  min-width: 0;
  min-height: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 179px;
  margin-top: 50px;
  height: 90px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
@media (max-width: 1370px) {
  .img {
    margin-top: 35px;
  }
}
@media (max-width: 414px) {
  .img {
    width: 150px;
    margin-top: 30px;
  }
}
.text__aTsSm {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #181818;
  font-size: 38px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 50px 0px 0px;
}
@media (max-width: 1370px) {
  .text__aTsSm {
    margin-top: 35px;
  }
}
.text__d5DIr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 567px;
  font-weight: 500;
  color: #242424;
  margin-top: 10px;
  font-size: 16.5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  line-height: 1.8;
}
.freeBox__fnM5W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 45px;
  padding: 0px;
}
@media (max-width: 1370px) {
  .freeBox__fnM5W {
    margin-top: 40px;
  }
}
.text__pdul1 {
  display: block;
  font-size: 16.5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .text__pdul1 {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
.loginEmailInput:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
.invalidEmailText {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  color: #ff0000;
  font-size: 15px;
  margin: 17px 0px 0px 11px;
}
.freeBox__fkwfJ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 400px;
  margin-top: 37px;
  padding: 0px;
}
.freeBox__ggHgl {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-left: 7px;
  min-width: 0;
  padding: 0px;
}
@media (max-width: 1370px) {
  .freeBox__ggHgl {
    top: auto;
    left: auto;
  }
}
.text___7Eg1 {
  display: block;
  font-size: 16.5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
}
@media (max-width: 1370px) {
  .text___7Eg1 {
    font-size: 14px;
  }
}
.forgotPassword {
  display: block;
  font-size: 13.5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: #14377f;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 145px;
  cursor: pointer;
}
@media (max-width: 1370px) {
  .forgotPassword {
    font-size: 13px;
    left: auto;
    top: auto;
  }
}
.loginPasswordInput:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
.incorrectLoginText {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  color: #ff0000;
  font-size: 15px;
  margin: 17px 0px 0px 11px;
}
.customErrorInstance:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 17px;
  margin-right: 0px;
  margin-left: 11px;
}
.text__taKlq {
  color: #ff0000;
  font-size: 15px;
  line-height: 1.6;
}
.loginButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 53px;
  max-width: 100%;
  background: #14377f;
  cursor: pointer;
  position: relative;
  margin-top: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .loginButton:hover {
  background: #284379d4;
}
.root .loginButton:active {
  background: #284379d4;
  border: 1px solid #ffffff;
}
.text___5Zb7 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
.text__thRqx {
  display: block;
  font-size: 15px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  margin-top: 35px;
  color: #242424;
}
@media (max-width: 1440px) {
  .text__thRqx {
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .text__thRqx {
    font-size: 13.5px;
    margin-top: 30px;
    left: auto;
    top: auto;
  }
}
.signupText {
  padding-bottom: 0px;
  cursor: pointer;
  color: #14377f;
  height: 41px;
}
