.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
.freeBox__tcKWn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 8px 0px 8px 8px;
}
.topBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 16.5%;
  align-self: auto;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  margin-top: 2%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__n83Ph {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 25.84%;
  height: 100%;
  max-width: 100%;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  background: #44c0ff00;
  margin-left: 10%;
  min-height: 0;
}
.freeBox__n83Ph > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 1.5%);
  height: calc(100% + 1.5%);
}
.freeBox__n83Ph > :global(.__wab_flex-container) > *,
.freeBox__n83Ph > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__n83Ph > :global(.__wab_flex-container) > picture > img,
.freeBox__n83Ph
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 1.5%;
}
.title {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(12% + 1.5%) !important;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
  flex-direction: row;
}
.slotTargetDebtorName {
  font-family: "Open Sans", sans-serif;
  font-size: 1.8vw;
  font-weight: 700;
  color: rgba(36, 38, 51, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.title2 {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(1.65vw + 1.5%) !important;
  flex-direction: row;
}
@media (max-width: 1440px) {
  .title2 {
    margin-top: calc(1.65vw + 1.5%) !important;
  }
}
.slotTargetDebtorContact {
  font-family: "Open Sans", sans-serif;
  font-size: 1.55vw;
  font-weight: 400;
  color: rgba(36, 38, 51, 1);
  line-height: 100%;
  letter-spacing: 0.3px;
}
.text__oeb5T {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.15vw;
  margin-top: calc(2.58vw + 1.5%) !important;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
}
@media (max-width: 1440px) {
  .text__oeb5T {
    margin-top: calc(2.58vw + 1.5%) !important;
  }
}
.freeBox__cxpmu {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-top: calc(0.2vw + 1.5%) !important;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1440px) {
  .freeBox__cxpmu {
    margin-top: calc(0.2vw + 1.5%) !important;
  }
}
.slotTargetAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vw;
  font-weight: 700;
  color: #000000;
}
.text__et0Xm {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.15vw;
  margin-top: calc(2.58vw + 1.5%) !important;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
}
.slotTargetEmailsSent {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vw;
  font-weight: 700;
  color: #000000;
}
.text__dy8YB {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.15vw;
  margin-top: calc(2.58vw + 1.5%) !important;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
}
@media (max-width: 1440px) {
  .text__dy8YB {
    margin-top: calc(2.58vw + 1.5%) !important;
  }
}
.slotTargetPhoneCallsMade {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vw;
  font-weight: 700;
  color: #000000;
}
.text__mizI {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.15vw;
  margin-top: calc(2.58vw + 1.5%) !important;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
}
@media (max-width: 1440px) {
  .text__mizI {
    margin-top: calc(2.58vw + 1.5%) !important;
  }
}
.freeBox__isqqr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-top: calc(0.2vw + 1.5%) !important;
  display: flex;
  flex-direction: row;
}
.slotTargetDateCreated {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vw;
  font-weight: 700;
  color: #000000;
}
.viewInfo {
  width: 12.5vw;
  height: 2.8vw;
  background: #14377f;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(2.7vw + 1.5%) !important;
  margin-bottom: 0px;
  flex-shrink: 0;
  border-radius: 10px;
}
@media (max-width: 1440px) {
  .viewInfo {
    width: 12.5vw;
    height: 2.8vw;
    justify-content: center;
    align-items: center;
    margin-top: calc(2.7vw + 1.5%) !important;
    background: #048b68;
    left: auto;
    top: auto;
    right: auto;
    flex-shrink: 0;
  }
}
.root .viewInfo:hover {
  background: #1b459ed4;
  left: auto;
  top: auto;
  right: auto;
  margin-top: calc(2.7vw + 1.5%) !important;
}
.root .viewInfo:active {
  background: #1b459ed4;
  left: auto;
  top: auto;
  right: auto;
  margin-top: calc(2.7vw + 1.5%) !important;
  border-width: 1px;
  border-style: solid;
}
@media (max-width: 1440px) {
  .root .viewInfo:hover {
    left: auto;
    top: auto;
    right: auto;
    margin-top: calc(2.7vw + 1.5%) !important;
  }
}
@media (max-width: 1440px) {
  .root .viewInfo:active {
    left: auto;
    top: auto;
    right: auto;
    margin-top: calc(2.7vw + 1.5%) !important;
  }
}
.text___5SsTw {
  display: block;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
  text-align: center;
}
@media (max-width: 1440px) {
  .text___5SsTw {
    font-size: 0.9vw;
  }
}
.freeBox___75R8X {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  margin-left: 0%;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 0px 0px 0px 9%;
}
.title9 {
  display: block;
  font-size: 1.4vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
}
.rectangle50 {
  background: rgba(255, 255, 255, 1);
  box-shadow: none;
  width: 70%;
  height: 11vw;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  padding-right: 0px;
  margin-top: 3%;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 5px;
}
.paymentNotes {
  box-shadow: 0px 0px 0px 1px #b1b1b1;
  font-size: 16px;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  margin-top: 0px;
  min-width: 0;
  min-height: 0;
  border-radius: 5px;
  padding: 12px 2px 2px 15px;
  border: 0px solid #d3d3d3;
}
.freeBox__wuy5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  height: 5%;
  max-width: 100%;
  background: #44c0ff00;
  padding: 0px;
  margin: 5% 0px 0px;
}
.title12 {
  display: block;
  font-size: 1.4vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  align-self: center;
  margin-left: 1.5%;
}
.freeBox__jdrMn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-top: 4%;
  padding: 0px;
}
.title13 {
  display: block;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 11%;
}
.title14 {
  display: block;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13.5%;
}
.title15 {
  display: block;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 15.5%;
}
.title16 {
  display: block;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 17.5%;
}
.paymentsStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 70%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  padding: 3% 0px 50px;
}
.paymentsStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.paymentsStack > :global(.__wab_flex-container) > *,
.paymentsStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.paymentsStack > :global(.__wab_flex-container) > picture > img,
.paymentsStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.clientPaymentsCard__qOn1S:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .clientPaymentsCard__qOn1S:global(.__wab_instance) {
    margin-top: calc(0% + 20px) !important;
    flex-shrink: 0;
  }
}
.text___3Xe5Y {
  text-align: center;
}
@media (max-width: 1440px) {
  .text___3Xe5Y {
    padding-left: 0px;
  }
}
.clientPaymentsCard__x0QZc:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  flex-shrink: 0;
}
