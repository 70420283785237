.spinner {
    /* width: auto; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F7FB;
  }
  
.displaytext1 {
    margin-right: 30px;
    font: "ariel";
    font-size: 26px;
    font-weight: 50
}


.displaytext3 {
  margin-right: 30px;
  font: "ariel";
  font-size: 26px;
  font-weight: 50
}


  .half-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #757575;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.5s linear 0s infinite;
  }

  .completed {
    font-size: 5rem;
    color: #03fc4e;
  
    animation: bigger 1s linear;
  }
  
  @keyframes bigger {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(2);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }