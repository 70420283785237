.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.root > * {
  grid-column: 4;
}
@media (max-width: 1440px) {
  .root > * {
    grid-column: 4;
  }
}
.freeBox__lXoZq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 1020px;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 0px 8px 0px 0px;
}
.sidebar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 100%;
  flex-shrink: 0;
  min-height: 0;
}
.freeBox___9Sry9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #f2f2f2;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__nhJNs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 1200px;
  height: auto;
  max-width: 100%;
  margin-top: 51px;
  border-radius: 8px;
  padding: 8px;
  border: 0px solid #cfcfcf;
}
.freeBox__icpjw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 19px;
  min-width: 0;
  padding: 8px;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h4 {
  text-align: left;
  font-size: 30px;
}
.addRoleBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__mrGmL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vpBKk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.rolesListStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 38px;
  min-width: 0;
  padding: 8px 0px;
}
.rolesCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
