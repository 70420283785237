.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #3555ff;
  min-width: 0;
  min-height: 0;
}
.freeBox___9Vjo3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.companyName {
  display: block;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: 367px;
  height: 58px;
  margin-bottom: 2%;
  text-align: center;
  padding-top: 0px;
  flex-shrink: 0;
}
.rectangle29 {
  background: rgba(255, 255, 255, 1);
  width: 392px;
  height: 133px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  margin-bottom: 2%;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  margin: 0px;
}
.requestedEscrowDesposit30000 {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: 299px;
  position: absolute;
  left: 27px;
  top: 14px;
}
.milestone1InitialSetup {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: 299px;
  height: 29px;
  position: absolute;
  left: 27px;
  top: 49px;
}
.svg___5VkN {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.9990125124341537, 0.9990075074918353)
    rotate(-0.28676326876410735deg) skew(-0.05735167682688388deg, 0deg);
  width: 241px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 21px;
  top: 92px;
}
.viewDetails {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(95, 86, 86, 1);
  position: absolute;
  left: 27px;
  top: 103px;
  cursor: pointer;
}
.rectangle28 {
  background: rgba(255, 255, 255, 1);
  width: 395px;
  height: 358px;
  display: block;
  position: relative;
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste2 {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  min-width: 0;
  min-height: 0;
}
.mainPaymentsCta:global(.__wab_instance) {
  position: absolute;
  top: 15px;
  left: 32px;
}
.svg__h14Ob {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 29px;
  top: 94px;
}
.text__avItG {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: 193px;
  height: 15px;
  position: absolute;
  left: 109px;
  top: 109px;
}
.card:global(.__wab_instance) {
  position: absolute;
  top: 135px;
  left: 45px;
}
.wire:global(.__wab_instance) {
  position: absolute;
  top: 135px;
  left: 161px;
}
.crypto:global(.__wab_instance) {
  position: absolute;
  top: 135px;
  left: 278px;
}
.freeBox__i9Hkx {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 372px;
  height: 165px;
  max-width: 100%;
  background: rgba(68, 192, 255, 0.2);
  left: 12px;
  top: 183px;
  padding: 8px;
}
.figmaPaste3 {
  display: block;
  position: relative;
  width: 337px;
  height: 127px;
  flex-shrink: 0;
}
.text__ppIXs {
  display: block;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: 216px;
  position: absolute;
  left: 64px;
  top: 97px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.text___1Ukvo {
  display: block;
  font-size: 13px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 120%;
  color: rgba(255, 255, 255, 1);
  width: 337px;
  height: auto;
  position: absolute;
  left: 0px;
  top: 0px;
  margin-top: 7%;
}
.paymentsSideBar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
