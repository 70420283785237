.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox___7Z8On {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__pmZx7 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__qZeas {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox__xZl0I {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___1Cjh0 {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__e5Ifg {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__suT {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__poXag {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg___1Ssi {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__i9GR {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__lOdVk {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__dQbS2 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__yYls5 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__zl7M {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg___2TwR {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg___2GvXy {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.freeBox__auUkw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__auUkw > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__auUkw > :global(.__wab_flex-container) > *,
.freeBox__auUkw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__auUkw > :global(.__wab_flex-container) > picture > img,
.freeBox__auUkw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__r2AYy {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___1Ec3F {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__muGl {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___5RHem {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__ja311 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__lz5Ba {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 1153px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.freeBox__lPoy7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  margin: 40px 0px 20px;
}
.slotTargetOwnerText {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  text-align: left;
  line-height: 2;
}
.freeBox__fJeHo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__rmRh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text__mJkK2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__s33Nm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text__iA5L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__r6Tt6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__j4MVt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.countrySelect:global(.__wab_instance) {
  max-width: 100%;
  width: 462px;
  margin-top: 0px;
  height: 41px;
  flex-shrink: 0;
}
.option__rsTB:global(.__wab_instance) {
  position: relative;
}
.text__peVf {
  padding-bottom: 0px;
}
.option__y4Fnh:global(.__wab_instance) {
  position: relative;
}
.option__uAlga:global(.__wab_instance) {
  position: relative;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__mri3P:global(.__wab_instance) {
  position: relative;
}
.option___8Ajb:global(.__wab_instance) {
  position: relative;
}
.option__bPqgr:global(.__wab_instance) {
  position: relative;
}
.option__mKliz:global(.__wab_instance) {
  position: relative;
}
.option__hZix7:global(.__wab_instance) {
  position: relative;
}
.option___51QNc:global(.__wab_instance) {
  position: relative;
}
.option___2YPh7:global(.__wab_instance) {
  position: relative;
}
.option__vKxdl:global(.__wab_instance) {
  position: relative;
}
.option__nJmnz:global(.__wab_instance) {
  position: relative;
}
.option__krGwc:global(.__wab_instance) {
  position: relative;
}
.option__rDmxg:global(.__wab_instance) {
  position: relative;
}
.option__fzwA5:global(.__wab_instance) {
  position: relative;
}
.option__tho2G:global(.__wab_instance) {
  position: relative;
}
.option__vE090:global(.__wab_instance) {
  position: relative;
}
.option___7Jrbs:global(.__wab_instance) {
  position: relative;
}
.option__n6Wl2:global(.__wab_instance) {
  position: relative;
}
.option__mxpBf:global(.__wab_instance) {
  position: relative;
}
.option__i5PQ:global(.__wab_instance) {
  position: relative;
}
.option___9Qqwx:global(.__wab_instance) {
  position: relative;
}
.option___7H7CH:global(.__wab_instance) {
  position: relative;
}
.option__qCiOk:global(.__wab_instance) {
  position: relative;
}
.option__cvohj:global(.__wab_instance) {
  position: relative;
}
.option__nwcZ:global(.__wab_instance) {
  position: relative;
}
.option__qCwcK:global(.__wab_instance) {
  position: relative;
}
.option__xGk:global(.__wab_instance) {
  position: relative;
}
.option__jPDl:global(.__wab_instance) {
  position: relative;
}
.option___1JeN:global(.__wab_instance) {
  position: relative;
}
.option__jEi1:global(.__wab_instance) {
  position: relative;
}
.option__aoNbF:global(.__wab_instance) {
  position: relative;
}
.option__fqqAc:global(.__wab_instance) {
  position: relative;
}
.option__lfjVh:global(.__wab_instance) {
  position: relative;
}
.option__yVSdg:global(.__wab_instance) {
  position: relative;
}
.option__mNmlu:global(.__wab_instance) {
  position: relative;
}
.option__w2CwP:global(.__wab_instance) {
  position: relative;
}
.option__sfZwp:global(.__wab_instance) {
  position: relative;
}
.option__ibumX:global(.__wab_instance) {
  position: relative;
}
.option__dsBug:global(.__wab_instance) {
  position: relative;
}
.option__crvNo:global(.__wab_instance) {
  position: relative;
}
.option__qii5T:global(.__wab_instance) {
  position: relative;
}
.option___532Cu:global(.__wab_instance) {
  position: relative;
}
.option__ummip:global(.__wab_instance) {
  position: relative;
}
.option__t1AgC:global(.__wab_instance) {
  position: relative;
}
.option__abaxq:global(.__wab_instance) {
  position: relative;
}
.option__u9Ll:global(.__wab_instance) {
  position: relative;
}
.option__z67Q4:global(.__wab_instance) {
  position: relative;
}
.option__pWxse:global(.__wab_instance) {
  position: relative;
}
.option___8Hna7:global(.__wab_instance) {
  position: relative;
}
.option__yxhQ4:global(.__wab_instance) {
  position: relative;
}
.option__gDjbU:global(.__wab_instance) {
  position: relative;
}
.option__iQvS9:global(.__wab_instance) {
  position: relative;
}
.option__hwHya:global(.__wab_instance) {
  position: relative;
}
.option__ukwx7:global(.__wab_instance) {
  position: relative;
}
.option__jTBu7:global(.__wab_instance) {
  position: relative;
}
.option__ba0Az:global(.__wab_instance) {
  position: relative;
}
.option__pJkJy:global(.__wab_instance) {
  position: relative;
}
.option__cq3MY:global(.__wab_instance) {
  position: relative;
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__gGpbx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__zmBP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.ssnStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__tcbYc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.passportStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__uxic {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.passport {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 13.5px;
    margin-left: 80px;
    margin-top: -15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -10px;
    align-self: flex-start;
  }
}
.continueButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 49px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .continueButton:hover {
  background: #3555ffd4;
}
.root .continueButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__oUzSp {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
