.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 999;
  align-self: flex-start;
  height: 100%;
  max-width: none;
  flex-shrink: 0;
  min-height: 0;
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  align-self: flex-start;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 8px 8px 0px;
}
@media (max-width: 1440px) {
  .vertStack {
    height: 100%;
    min-height: 0;
  }
}
.freeBox__xr0DM {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 842px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  left: auto;
  top: auto;
  z-index: 1;
  padding: 0px 8px;
  margin: 100px 0px 0px;
}
@media (max-width: 1440px) {
  .freeBox__xr0DM {
    width: 53vw;
    max-width: none;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 6.5vw;
  }
}
@media (max-width: 1370px) {
  .freeBox__xr0DM {
    width: 53vw;
  }
}
.invoiceDetails {
  box-shadow: inset 0px 0px 1px 1px #cecfd8;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  max-width: 897px;
  min-width: 0;
  border-radius: 8px;
  margin: 0%;
}
.invoiceDetails > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.invoiceDetails > :global(.__wab_flex-container) > *,
.invoiceDetails > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.invoiceDetails > :global(.__wab_flex-container) > picture > img,
.invoiceDetails
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .invoiceDetails {
    left: auto;
    top: auto;
  }
}
.freeBox__wmEDj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 80px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__wmEDj {
    height: 5.55vw;
    flex-shrink: 0;
    padding: 0px;
  }
}
.title4 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .title4 {
    font-size: 1.36vw;
    margin-top: 0%;
    margin-bottom: 0%;
  }
}
@media (max-width: 1370px) {
  .title4 {
    font-size: 1.36vw;
  }
}
.freeBox___0ZmUx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 265px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 26px 13px;
}
@media (max-width: 1440px) {
  .freeBox___0ZmUx {
    height: 17.3vw;
    padding-top: 1.8vw;
    padding-bottom: 1.8vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .freeBox___0ZmUx {
    height: 18.4vw;
    padding-top: 1.8vw;
    padding-bottom: 1.8vw;
    flex-shrink: 0;
  }
}
.uploadDebtorsButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.webPortalButton:global(.__wab_instance) {
  position: relative;
  margin-left: 12px;
}
.content {
  width: 588px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
}
.content > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.content > :global(.__wab_flex-container) > *,
.content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content > :global(.__wab_flex-container) > picture > img,
.content
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.checkboxGroup {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.checkboxGroup > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.checkboxGroup > :global(.__wab_flex-container) > *,
.checkboxGroup > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checkboxGroup > :global(.__wab_flex-container) > picture > img,
.checkboxGroup
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.checkboxGroupItem3 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(234, 236, 239, 1);
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  border-radius: 12px;
  padding: 16px;
}
.checkboxGroupItem3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.checkboxGroupItem3 > :global(.__wab_flex-container) > *,
.checkboxGroupItem3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checkboxGroupItem3 > :global(.__wab_flex-container) > picture > img,
.checkboxGroupItem3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.content4 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.content4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.content4 > :global(.__wab_flex-container) > *,
.content4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content4 > :global(.__wab_flex-container) > picture > img,
.content4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.featuredIcon3 {
  background: rgba(243, 234, 255, 1);
  box-shadow: 0px 0px 0px 2px rgba(248, 244, 255, 1),
    inset 0px 0px 0px 2px rgba(248, 244, 255, 1);
  mix-blend-mode: multiply;
  width: 32px;
  height: 32px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 28px;
}
.img__osmok {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 16px;
  display: block !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
}
.img__osmok > picture > img {
  object-fit: cover;
}
.textAndSupportingText3 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textAndSubtext3 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.textAndSubtext3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.textAndSubtext3 > :global(.__wab_flex-container) > *,
.textAndSubtext3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textAndSubtext3 > :global(.__wab_flex-container) > picture > img,
.textAndSubtext3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.text3 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(52, 64, 83, 1);
  position: relative;
}
.subtext3 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(71, 84, 102, 1);
  position: relative;
}
.includesUpTo10Users20GbIndividualDataAndAccessToAllFeatures5 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(71, 84, 102, 1);
  align-self: stretch;
  position: relative;
}
.checkboxBase3 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(207, 212, 220, 1);
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 8px;
}
.checkboxGroupItem4 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(234, 236, 239, 1);
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  border-radius: 12px;
  padding: 16px;
}
.checkboxGroupItem4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.checkboxGroupItem4 > :global(.__wab_flex-container) > *,
.checkboxGroupItem4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checkboxGroupItem4 > :global(.__wab_flex-container) > picture > img,
.checkboxGroupItem4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.content5 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.content5 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.content5 > :global(.__wab_flex-container) > *,
.content5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content5 > :global(.__wab_flex-container) > picture > img,
.content5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.featuredIcon4 {
  background: rgba(243, 234, 255, 1);
  box-shadow: 0px 0px 0px 2px rgba(248, 244, 255, 1),
    inset 0px 0px 0px 2px rgba(248, 244, 255, 1);
  mix-blend-mode: multiply;
  width: 32px;
  height: 32px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 28px;
}
.img__e8Fzx {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 16px;
  display: block !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
}
.img__e8Fzx > picture > img {
  object-fit: cover;
}
.textAndSupportingText4 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textAndSubtext4 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.textAndSubtext4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.textAndSubtext4 > :global(.__wab_flex-container) > *,
.textAndSubtext4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textAndSubtext4 > :global(.__wab_flex-container) > picture > img,
.textAndSubtext4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.text4 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(52, 64, 83, 1);
  position: relative;
}
.subtext4 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(71, 84, 102, 1);
  position: relative;
}
.includesUpTo10Users20GbIndividualDataAndAccessToAllFeatures6 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(71, 84, 102, 1);
  align-self: stretch;
  position: relative;
}
.checkboxBase4 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(207, 212, 220, 1);
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 8px;
}
.checkboxGroupItem5 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(234, 236, 239, 1);
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  border-radius: 12px;
  padding: 16px;
}
.checkboxGroupItem5 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.checkboxGroupItem5 > :global(.__wab_flex-container) > *,
.checkboxGroupItem5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checkboxGroupItem5 > :global(.__wab_flex-container) > picture > img,
.checkboxGroupItem5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.content6 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.content6 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.content6 > :global(.__wab_flex-container) > *,
.content6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content6 > :global(.__wab_flex-container) > picture > img,
.content6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.featuredIcon5 {
  background: rgba(243, 234, 255, 1);
  box-shadow: 0px 0px 0px 2px rgba(248, 244, 255, 1),
    inset 0px 0px 0px 2px rgba(248, 244, 255, 1);
  mix-blend-mode: multiply;
  width: 32px;
  height: 32px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 28px;
}
.img__zvJk {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 16px;
  display: block !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
}
.img__zvJk > picture > img {
  object-fit: cover;
}
.textAndSupportingText5 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textAndSubtext5 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.textAndSubtext5 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.textAndSubtext5 > :global(.__wab_flex-container) > *,
.textAndSubtext5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textAndSubtext5 > :global(.__wab_flex-container) > picture > img,
.textAndSubtext5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.text5 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(52, 64, 83, 1);
  position: relative;
}
.subtext5 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(71, 84, 102, 1);
  position: relative;
}
.includesUpTo10Users20GbIndividualDataAndAccessToAllFeatures7 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(71, 84, 102, 1);
  align-self: stretch;
  position: relative;
}
.checkboxBase5 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(207, 212, 220, 1);
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 8px;
}
.errorText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ff0000;
  font-weight: 500;
  font-size: 16px;
  margin-top: 50px;
  margin-bottom: -20px;
}
@media (max-width: 1440px) {
  .errorText {
    margin-top: 50px;
    margin-bottom: -20px;
    font-size: 1.1vw;
  }
}
.continueButton {
  width: 216px;
  height: 54px;
  background: #14377f;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 0px;
  transition-property: all;
  transition-duration: 1s;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .continueButton {
    width: 15vw;
    height: 3.75vw;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    background: #14377f;
    left: auto;
    top: auto;
    right: auto;
    flex-shrink: 0;
  }
}
.root .continueButton:hover {
  background: #2e497ed4;
  left: auto;
  top: auto;
  right: auto;
}
.root .continueButton:active {
  background: #14377fd4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
.text {
  display: block;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .text {
    font-size: 1.15vw;
  }
}
