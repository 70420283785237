.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 999;
  align-self: flex-start;
  height: 100%;
  max-width: none;
  flex-shrink: 0;
  min-height: 0;
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox__r37Mj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 2638px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: flex-start;
  overflow: visible;
  min-width: 0;
  padding: 8px 8px 0px;
}
@media (max-width: 1440px) {
  .freeBox__r37Mj {
    height: 2492px;
  }
}
.freeBox___5QuC0 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 842px;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  left: auto;
  top: auto;
  z-index: 1;
  min-height: 0;
  padding: 0px 8px;
  margin: 50px 0px 0px;
}
@media (max-width: 1440px) {
  .freeBox___5QuC0 {
    width: 68%;
    max-width: 833px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 4%;
  }
}
.invoiceDetails {
  box-shadow: inset 0px 0px 1px 1px #dedfea;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  max-width: 897px;
  min-width: 0;
  border-radius: 8px;
  margin: 0px 0% 63px;
}
.invoiceDetails > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.invoiceDetails > :global(.__wab_flex-container) > *,
.invoiceDetails > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.invoiceDetails > :global(.__wab_flex-container) > picture > img,
.invoiceDetails
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .invoiceDetails {
    left: auto;
    top: auto;
  }
}
.freeBox__y8KTl {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 72px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
.title4 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title4 {
    font-size: 20px;
  }
}
.title3 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 50px;
  margin-top: calc(40px + 0px) !important;
  margin-bottom: 0%;
}
@media (max-width: 1440px) {
  .title3 {
    font-size: 20px;
    margin-top: calc(40px + 0px) !important;
  }
}
.searchRectangle:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(30px + 0px) !important;
  margin-left: 50px;
  width: 100%;
  margin-bottom: 0px;
  max-width: 310px;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .searchRectangle:global(.__wab_instance) {
    left: auto;
    top: auto;
    margin-top: calc(30px + 0px) !important;
    flex-shrink: 0;
  }
}
.title8 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 50px;
  margin-top: calc(50px + 0px) !important;
  margin-bottom: 0%;
}
@media (max-width: 1440px) {
  .title8 {
    font-size: 20px;
    left: auto;
    top: auto;
    margin-top: calc(50px + 0px) !important;
  }
}
.dueDate {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 310px;
  height: 40px;
  max-width: 100%;
  margin-left: 50px;
  margin-top: calc(30px + 0px) !important;
  margin-bottom: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .dueDate {
    margin-top: calc(30px + 0px) !important;
  }
}
.invoiceDetails2 {
  box-shadow: inset 0px 0px 1px 1px #dedfea;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  max-width: 897px;
  min-width: 0;
  border-radius: 8px;
  margin: 0px 0% 0%;
}
.invoiceDetails2 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.invoiceDetails2 > :global(.__wab_flex-container) > *,
.invoiceDetails2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.invoiceDetails2 > :global(.__wab_flex-container) > picture > img,
.invoiceDetails2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .invoiceDetails2 {
    left: auto;
    top: auto;
  }
}
.freeBox__boLwk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 72px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #dedfea;
}
.title5 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title5 {
    font-size: 20px;
  }
}
.freeBox__roL0Q {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: calc(35px + 0px) !important;
  margin-left: 0%;
  background: none;
  min-width: 0;
  padding: 0px;
  border-top: 0px solid #b7b7b7;
}
@media (max-width: 1440px) {
  .freeBox__roL0Q {
    margin-bottom: 1%;
    margin-top: calc(35px + 0px) !important;
  }
}
.title13 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  margin-left: 50px;
}
@media (max-width: 1440px) {
  .title13 {
    font-size: 20px;
  }
}
.title14 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  margin-left: 342px;
}
@media (max-width: 1440px) {
  .title14 {
    font-size: 20px;
  }
}
.title15 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  margin-left: 95px;
  padding-left: 0px;
}
@media (max-width: 1440px) {
  .title15 {
    font-size: 20px;
  }
}
.invoiceStack2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: calc(40px + 0px) !important;
  margin-left: 50px;
  padding: 0px;
}
.invoiceFields:global(.__wab_instance) {
  width: auto;
  max-width: none;
}
.freeBox__qxsgr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(45px + 0px) !important;
  margin-left: 50px;
  min-width: 0;
  padding: 0px;
}
.addItem2 {
  display: block;
  font-size: 17px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3555ff;
  width: auto;
  height: auto;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .addItem2 {
    font-size: 17px;
  }
}
.title16 {
  display: flex;
  width: 198px;
  height: auto;
  cursor: pointer;
  margin-left: 456px;
  padding-left: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.slotTargetBalanceDue2 {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #2e2e2e;
  line-height: 100%;
  letter-spacing: 0.3px;
}
@media (max-width: 1440px) {
  .slotTargetBalanceDue2 {
    font-size: 17px;
  }
}
.title17 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  margin-left: 50px;
  margin-top: calc(75px + 0px) !important;
}
@media (max-width: 1440px) {
  .title17 {
    font-size: 19px;
    margin-top: calc(75px + 0px) !important;
  }
}
.notes2 {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  font-size: 16.4px;
  position: relative;
  width: 395px;
  height: 108px;
  max-width: 100%;
  margin-top: calc(20px + 0px) !important;
  margin-left: 50px;
  margin-bottom: 65px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__tU51Z {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 0px;
}
@media (max-width: 1440px) {
  .freeBox__tU51Z {
    height: 20%;
  }
}
.advancedOptionsInstance:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: 100px;
  width: 100%;
  max-width: none;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .advancedOptionsInstance:global(.__wab_instance) {
    height: 20%;
  }
}
.advancedOptionsStack {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  width: 100%;
  height: 420px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  padding-bottom: 0px;
  margin-top: 0%;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 0px 0px 8px 8px;
}
@media (max-width: 1440px) {
  .advancedOptionsStack {
    height: auto;
    padding-top: 1%;
    padding-bottom: 5%;
  }
}
.freeBox___82Mh4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-right: 0px;
  margin-top: 2%;
  min-width: 0;
  padding: 8px;
}
.freeBox___82Mh4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10%);
  height: calc(100% + 10%);
}
.freeBox___82Mh4 > :global(.__wab_flex-container) > *,
.freeBox___82Mh4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___82Mh4 > :global(.__wab_flex-container) > picture > img,
.freeBox___82Mh4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10%;
}
@media (max-width: 1440px) {
  .freeBox___82Mh4 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.title26 {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(2% + 10%) !important;
  margin-bottom: 2%;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .title26 {
    font-size: 16px;
    margin-top: calc(2% + 10%) !important;
  }
}
.achSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-left: 15%;
}
.text__prrHo {
  padding-left: 0px;
}
.cardSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-left: 15%;
}
.text___1XWuc {
  padding-left: 0px;
}
.wireSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-left: 15%;
}
.text__kiXt0 {
  padding-left: 0px;
  padding-bottom: 0px;
}
.freeBox__afeSn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-right: 0px;
  margin-top: 2%;
  min-width: 0;
  padding: 8px;
}
.freeBox__afeSn > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10%);
  height: calc(100% + 10%);
}
.freeBox__afeSn > :global(.__wab_flex-container) > *,
.freeBox__afeSn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__afeSn > :global(.__wab_flex-container) > picture > img,
.freeBox__afeSn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10%;
}
.title28 {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(2% + 10%) !important;
  margin-bottom: 2%;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .title28 {
    font-size: 16px;
    margin-top: calc(2% + 10%) !important;
  }
}
.recurringInvoicesSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.text___1Vtx {
  width: auto;
}
.recurringInvoiceStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  left: auto;
  top: auto;
  margin-top: calc(-7px + 10%) !important;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__kxXYh {
  position: relative;
  width: auto;
  height: 35px;
  max-width: 800px;
  left: auto;
  top: auto;
  color: #000000;
  font-size: 18px;
  margin-top: 17px;
  margin-left: 10px;
}
.recurringNumber {
  position: relative;
  width: 46px;
  height: 35px;
  max-width: 100%;
  left: auto;
  top: auto;
  text-align: center;
  padding-left: 2px;
  font-size: 18px;
  margin-top: 14px;
  margin-left: 15px;
  flex-shrink: 0;
}
.recurringEscrowSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 15px;
  margin-top: 11px;
}
.option___1CZ3:global(.__wab_instance) {
  position: relative;
}
.text___5Di9P {
  padding-bottom: 0px;
}
.option___8EX4Y:global(.__wab_instance) {
  position: relative;
}
.option__xHHy:global(.__wab_instance) {
  position: relative;
}
.freeBox__kJgi2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-right: 0px;
  margin-top: 2%;
  min-width: 0;
  padding: 8px;
}
.freeBox__kJgi2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10%);
  height: calc(100% + 10%);
}
.freeBox__kJgi2 > :global(.__wab_flex-container) > *,
.freeBox__kJgi2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kJgi2 > :global(.__wab_flex-container) > picture > img,
.freeBox__kJgi2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10%;
}
.title27 {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #2e2e2e;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(2% + 10%) !important;
  margin-bottom: 2%;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .title27 {
    font-size: 16px;
    margin-top: calc(2% + 10%) !important;
  }
}
.paymentReminderSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.text__jk1Nf {
  width: auto;
  height: auto;
  padding-right: 0px;
}
.paymentReminderStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  left: auto;
  top: auto;
  margin-top: calc(-7px + 10%) !important;
  min-width: 0;
  padding: 0px;
}
.text___0HWm1 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: #000000;
  font-size: 18px;
  margin-top: 17px;
  margin-left: 10px;
}
.reminderNumber {
  position: relative;
  width: 46px;
  height: 35px;
  max-width: 100%;
  left: auto;
  top: auto;
  text-align: center;
  padding-left: 2px;
  font-size: 18px;
  margin-top: 14px;
  margin-left: 15px;
  flex-shrink: 0;
}
.paymentReminderSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 15px;
  margin-top: 11px;
}
.option__opp1:global(.__wab_instance) {
  position: relative;
}
.text__fDnqi {
  padding-bottom: 0px;
}
.option___0MdKk:global(.__wab_instance) {
  position: relative;
}
.option__gdWy0:global(.__wab_instance) {
  position: relative;
}
.sendButton {
  width: 216px;
  height: 54px;
  background: #3555ff;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .sendButton {
    width: 24.67%;
    height: 2.51%;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
}
.root .sendButton:hover {
  background: #3555ffd4;
}
.root .sendButton:active {
  background: #3555ffd4;
  border-width: 1px;
  border-style: solid;
}
.text__aYivt {
  display: block;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .text__aYivt {
    font-size: 1.2vw;
  }
}
