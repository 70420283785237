.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox__hv8Sb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__t8KcN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__cDnyN {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 8px;
  min-width: 0;
  padding: 0px 8px 8px;
}
.roleName:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__t6JS {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__fjuJo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.permissionSelectStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 6px;
  min-width: 0;
  padding: 8px;
}
.permissionSelect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 264px;
  flex-shrink: 0;
  display: none;
}
.permissionSelectionStack:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 40px;
  pointer-events: auto;
  margin-bottom: 16px;
  overflow: auto;
  padding-bottom: 2px;
  padding-right: 10px;
  border: 1px solid #d9d9d9;
}
.text__p3QEl {
  position: relative;
  width: 804px;
  height: 25px;
  max-width: 100%;
  margin-bottom: 0px;
  padding-right: 10px;
}
.antdOption:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__qXdst {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__kmNpb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.permissionChipsStack {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  width: 85%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-Tf_KtjkxGdsv);
  padding: 8px;
}
.permissionChip:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__liMj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.saveEditRoleBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qMjhW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hvQsZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
