.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(185, 185, 185, 1);
  width: 100%;
  height: 3.7vw;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 0%;
}
.root:hover {
  background: #f7f7f7;
}
.root:active {
  box-shadow: none;
  background: #f7f7f7;
  border-width: 1px;
  border-style: solid;
}
.freeBox__e2B9L {
  display: flex;
  width: 28%;
  position: relative;
  left: auto;
  top: auto;
  height: auto;
  margin-left: calc(2% + 0%) !important;
  justify-content: center;
  flex-direction: row;
  max-width: 33%;
}
.slotTargetTitle {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox___56X3 {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: 18.5%;
  margin-left: calc(3.5% + 0%) !important;
  justify-content: center;
  flex-direction: row;
}
@media (max-width: 1440px) {
  .freeBox___56X3 {
    margin-left: calc(3.5% + 0%) !important;
  }
}
.root:active .freeBox___56X3 {
  margin-left: calc(3.5% + 0%) !important;
}
.root:hover .freeBox___56X3 {
  margin-left: calc(3.5% + 0%) !important;
}
.slotTargetClient {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.contractStatusStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-left: calc(7% + 0%) !important;
  align-self: center;
  padding: 0px;
}
.completedSign:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  margin-left: 0%;
  flex-shrink: 0;
}
.freeBox__oKjYp {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: 14%;
  margin-left: calc(9% + 0%) !important;
  justify-content: center;
  flex-direction: row;
}
.slotTargetTotalAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 120%;
  letter-spacing: 0.3px;
}
.freeBox__vVjy6 {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: 15%;
  margin-left: calc(8.5% + 0%) !important;
  margin-right: 3.4%;
  flex-direction: row;
}
.slotTargetDate {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 120%;
  letter-spacing: 0.3px;
}
