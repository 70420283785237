.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  overflow: auto;
  min-width: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox___6OAU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 8px 0px 8px 8px;
}
.topBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 20%;
  align-self: auto;
}
.uploadingDebtors {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12%;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
  margin: 2.2% 1% 1%;
}
@media (max-width: 1600px) {
  .uploadingDebtors {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 12%;
    min-width: 0;
    padding: 0px;
  }
}
.freeBox__yFyo9 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
}
.freeBox__yFyo9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.freeBox__yFyo9 > :global(.__wab_flex-container) > *,
.freeBox__yFyo9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yFyo9 > :global(.__wab_flex-container) > picture > img,
.freeBox__yFyo9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0%;
}
@media (max-width: 1600px) {
  .freeBox__yFyo9 {
    height: 100%;
    min-height: 0;
  }
}
@media (max-width: 1600px) {
  .freeBox__yFyo9 > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.title {
  display: block;
  font-size: 1.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  align-self: center;
}
.addDebtorsButton2 {
  background: #14377f;
  width: 10vw;
  height: 2.3vw;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: calc(30% + 0%) !important;
  margin-right: 0%;
  flex-shrink: 0;
  border-radius: 10px;
  border-width: 0px;
}
.addDebtorsButton2 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 6%);
  width: calc(100% + 6%);
}
.addDebtorsButton2 > :global(.__wab_flex-container) > *,
.addDebtorsButton2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.addDebtorsButton2 > :global(.__wab_flex-container) > picture > img,
.addDebtorsButton2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6%;
}
@media (max-width: 1600px) {
  .addDebtorsButton2 {
    display: flex;
    flex-direction: row;
    margin-left: calc(30% + 0%) !important;
  }
}
@media (max-width: 1600px) {
  .addDebtorsButton2 > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 6%);
    width: calc(100% + 6%);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 1600px) {
  .addDebtorsButton2 > :global(.__wab_flex-container) > *,
  .addDebtorsButton2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .addDebtorsButton2 > :global(.__wab_flex-container) > picture > img,
  .addDebtorsButton2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 6%;
    margin-top: 0px;
  }
}
.root .addDebtorsButton2:hover {
  background: #14377fd4;
  margin-left: calc(30% + 0%) !important;
}
.root .addDebtorsButton2:active {
  background: #14377fd4;
  margin-left: calc(30% + 0%) !important;
  border-width: 1px;
}
@media (max-width: 1600px) {
  .root .addDebtorsButton2:hover {
    background: #14377fd4;
    margin-left: calc(30% + 0%) !important;
  }
}
@media (max-width: 1600px) {
  .root .addDebtorsButton2:active {
    background: #14377fd4;
    margin-left: calc(30% + 0%) !important;
    border-width: 1px;
  }
}
.text__zZbDf {
  display: block;
  font-size: 1.25vw;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  align-self: center;
  margin-bottom: 2%;
}
@media (max-width: 1600px) {
  .text__zZbDf {
    left: auto;
    top: auto;
    padding-top: 3px;
  }
}
.text__vgVp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 0.85vw;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  min-width: 0;
}
.addDebtorsButton {
  background: #14377f;
  width: 8.5vw;
  height: 2.3vw;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: calc(3% + 0%) !important;
  margin-right: 3%;
  flex-shrink: 0;
  border-radius: 10px;
  border-width: 0px;
}
.addDebtorsButton > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 6%);
  width: calc(100% + 6%);
}
.addDebtorsButton > :global(.__wab_flex-container) > *,
.addDebtorsButton > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.addDebtorsButton > :global(.__wab_flex-container) > picture > img,
.addDebtorsButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6%;
}
@media (max-width: 1600px) {
  .addDebtorsButton {
    margin-left: calc(3% + 0%) !important;
  }
}
.root .addDebtorsButton:hover {
  background: #14377fd4;
  margin-left: calc(3% + 0%) !important;
}
.root .addDebtorsButton:active {
  background: #14377fd4;
  margin-left: calc(3% + 0%) !important;
  border-width: 1px;
}
@media (max-width: 1600px) {
  .root .addDebtorsButton:hover {
    background: #14377fd4;
    margin-left: calc(3% + 0%) !important;
  }
}
@media (max-width: 1600px) {
  .root .addDebtorsButton:active {
    background: #14377fd4;
    margin-left: calc(3% + 0%) !important;
    border-width: 1px;
  }
}
.text__jq1UK {
  display: block;
  font-size: 1.25vw;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  align-self: center;
  margin-bottom: 2%;
}
.text__j3Av {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
@media (max-width: 1600px) {
  .text__j3Av {
    margin-left: calc(0px + 6%) !important;
  }
}
.searchVertStack {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3d9;
  background: #ffffff;
  width: 11vw;
  height: 41px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  margin-left: calc(0% + 0%) !important;
  justify-content: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1600px) {
  .searchVertStack {
    margin-left: calc(0% + 0%) !important;
  }
}
@media (max-width: 1440px) {
  .searchVertStack {
    width: 11vw;
    height: 2.3vw;
    margin-left: calc(0% + 0%) !important;
    flex-shrink: 0;
  }
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  height: 100%;
  max-width: none;
  background: #44c0ff00;
  margin-top: 2%;
  overflow: visible;
  min-height: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1600px) {
  .vertStack {
    width: 76%;
  }
}
.figmaPaste2 {
  display: flex;
  position: relative;
  width: 100%;
  height: 45px;
  align-self: flex-end;
  padding-bottom: 0px;
  flex-direction: row;
  padding-left: 0px;
  min-width: 0;
  flex-shrink: 0;
}
.figmaPaste2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.figmaPaste2 > :global(.__wab_flex-container) > *,
.figmaPaste2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste2 > :global(.__wab_flex-container) > picture > img,
.figmaPaste2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0%;
}
.text__lxfZm {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: calc(3% + 0%) !important;
  width: 20%;
  text-align: center;
  padding-left: 30px;
}
@media (max-width: 1600px) {
  .text__lxfZm {
    width: 20%;
    margin-left: calc(3% + 0%) !important;
    text-align: center;
  }
}
.text__n9Qrn {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: calc(0% + 0%) !important;
  width: 20%;
  text-align: center;
}
@media (max-width: 1600px) {
  .text__n9Qrn {
    width: 20%;
    margin-left: calc(0% + 0%) !important;
  }
}
.text__ahIgj {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: calc(0% + 0%) !important;
  width: 20%;
  text-align: center;
}
@media (max-width: 1600px) {
  .text__ahIgj {
    left: auto;
    top: auto;
    width: 20%;
    margin-left: calc(0% + 0%) !important;
  }
}
.text__f8SFi {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: calc(0% + 0%) !important;
  width: 20%;
  text-align: center;
}
@media (max-width: 1600px) {
  .text__f8SFi {
    width: 20%;
    margin-left: calc(0% + 0%) !important;
  }
}
.clientsListStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 2%;
  overflow: visible;
  min-width: 0;
  padding: 0px 0px 25px;
}
.clientsListStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.clientsListStack > :global(.__wab_flex-container) > *,
.clientsListStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.clientsListStack > :global(.__wab_flex-container) > picture > img,
.clientsListStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
.freeBox__xXdYp {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (max-width: 1600px) {
  .clientsListCard___2H3Oa:global(.__wab_instance) {
    margin-right: 0px;
  }
}
.text__ytanh {
  text-align: left;
}
.clientsListCard___6W4Db:global(.__wab_instance) {
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .clientsListCard___6W4Db:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1600px) {
  .text__wcEBv {
    height: auto;
  }
}
.text___32GHy {
  text-align: left;
}
@media (max-width: 1600px) {
  .text___32GHy {
    text-align: left;
  }
}
.freeBox__rDmnn {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
@media (max-width: 1600px) {
  .freeBox__rDmnn {
    display: none;
  }
}
.paginationStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  margin-left: 0%;
  margin-top: 0px;
  left: auto;
  top: auto;
  margin-right: 1%;
  padding: 1vw 0px 2vw;
}
@media (max-width: 1440px) {
  .paginationStack {
    left: auto;
    top: auto;
  }
}
.prevButton {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5vw;
  height: 2vw;
  max-width: 100%;
  background: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text__jIsq3 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  color: #000000;
}
.nextButton {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4vw;
  height: 2vw;
  max-width: 100%;
  background: #ffffff;
  margin-left: 0.5vw;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text__wj4HZ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 400;
}
