.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #b9b9b9;
  width: 100%;
  height: 3.7vw;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 0%;
}
.root:hover {
  background: #f7f7f7;
  cursor: pointer;
}
.root:active {
  background: #f7f7f7;
  box-shadow: none;
  cursor: pointer;
  border: 1px solid #000000;
}
.freeBox__rqTni {
  display: flex;
  width: 9.6%;
  position: relative;
  left: auto;
  top: auto;
  height: auto;
  margin-left: calc(3.6% + 0%) !important;
  justify-content: center;
  flex-direction: row;
}
.slotTargetAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 1.05vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.clientCompany {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: 16.7%;
  margin-left: calc(6% + 0%) !important;
  justify-content: center;
  flex-direction: row;
}
@media (max-width: 1600px) {
  .clientCompany {
    margin-left: calc(6% + 0%) !important;
  }
}
.slotTargetClientCompany {
  font-family: "Open Sans", sans-serif;
  font-size: 1.05vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.statusCardStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 4vw;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-left: calc(5.5% + 0%) !important;
  align-self: center;
  flex-shrink: 0;
  padding: 0px;
}
.paidStatusCard:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  margin-left: 0%;
  flex-shrink: 0;
}
.freeBox__rrnYl {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: 18%;
  margin-left: calc(8.1% + 0%) !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1600px) {
  .freeBox__rrnYl {
    margin-left: calc(8.1% + 0%) !important;
  }
}
.slotTargetPaymentType {
  font-family: "Open Sans", sans-serif;
  font-size: 1.05vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
@media (max-width: 1600px) {
  .slotTargetPaymentType {
    line-height: 1.5vw;
  }
}
.freeBox__j2Fbk {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: 16.5%;
  margin-left: calc(6.9% + 0%) !important;
  margin-right: 0%;
  justify-content: center;
  flex-direction: row;
}
.slotTargetDateCreated {
  font-family: "Open Sans", sans-serif;
  font-size: 1.05vw;
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
