.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  min-width: 0;
}
.freeBox__baw3W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__yhBnM {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__hucix {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__lIdck {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__l6XR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ef3V {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px;
}
.clientKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 584px;
  margin-top: 6px;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox__qVfjp {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text___6DQ06 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ybu55 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px;
}
.webHookKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 584px;
  margin-top: 6px;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox__goqOu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__iIcF {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__u3Pwg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__cdU2E {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__nSy2K {
  width: 582px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox___98Rhw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 6px;
  min-width: 0;
  padding: 0px 8px 8px;
}
.devClientKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 584px;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox__mf1Ll {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__jktoe {
  width: 584px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox__ziv1 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 10px;
  min-width: 0;
  padding: 0px 8px 8px;
}
.devWebHookKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 584px;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox__llpIj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  margin-top: 10px;
  min-width: 0;
  padding: 17px 8px 8px;
}
.saveBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 78px;
  margin-right: 8px;
  flex-shrink: 0;
}
.svg__na7Z3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__smrlX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
