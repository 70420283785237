.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
}
.title8 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 40px;
  margin-bottom: 20px;
  margin-top: 15px;
}
@media (max-width: 1440px) {
  .title8 {
    font-size: 1.06vw;
    left: auto;
    top: auto;
    margin-left: 3.6vw;
    margin-top: 3.1vw;
  }
}
@media (max-width: 1370px) {
  .title8 {
    left: auto;
    top: auto;
  }
}
.amount {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  height: 41px;
  max-width: 100%;
  margin-left: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .amount {
    width: 23.5vw;
    height: 2.85vw;
    flex-shrink: 0;
  }
}
.generateLinkButton {
  width: 190px;
  height: 50px;
  background: #048b68;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 25px;
  margin-left: 40px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .generateLinkButton {
    width: 216px;
    height: 54px;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    background: #048b68;
    left: auto;
    top: auto;
    right: auto;
    flex-shrink: 0;
  }
}
.root .generateLinkButton:hover {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
}
.root .generateLinkButton:active {
  background: #048b68d4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
.root .generateLinkButton:hover {
  left: auto;
  top: auto;
  right: auto;
}
.root .generateLinkButton:active {
  left: auto;
  top: auto;
  right: auto;
}
@media (max-width: 1440px) {
  .root .generateLinkButton:hover {
    left: auto;
    top: auto;
    right: auto;
  }
}
@media (max-width: 1440px) {
  .root .generateLinkButton:active {
    left: auto;
    top: auto;
    right: auto;
  }
}
.text {
  display: block;
  font-size: 13.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .text {
    font-size: 1.15vw;
  }
}
