.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  align-items: center;
}
.toggle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  flex-shrink: 0;
}
.track {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  transition-property: background;
  transition-duration: 0.2s;
  background: #f3f3f2;
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.2s;
  border-radius: 12px;
  border: 1px solid #ffffff00;
}
@media (max-width: 1440px) {
  .track {
    height: 1.65vw;
  }
}
.trackisChecked {
  background: #0091ff;
}
.root:hover .track {
  background: #eeeeec;
}
.root:active .track {
  background: #e9e9e6;
}
.rootisChecked:hover .trackisChecked {
  background: #0081f1;
}
.thumb {
  display: block;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: 4px;
  top: 8px;
  width: 16px;
  height: 16px;
  transition-property: all;
  transition-duration: 0.2s;
  background: #ffffff;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
  border: 1px solid #dbdbd7;
}
@media (max-width: 1440px) {
  .thumb {
    height: 1.1vw;
    width: 1.1vw;
    flex-shrink: 0;
  }
}
.thumbisChecked {
  left: 20px;
  right: auto;
  border-style: none;
}
.root:hover .thumb {
  background: #f9f9f9;
  border-color: #c8c7c1;
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: 8px;
}
.slotTargetChildren {
  white-space: pre-wrap;
  font-family: "Open Sans", sans-serif;
  color: #000000;
}
@media (max-width: 1440px) {
  .slotTargetChildren {
    font-size: 1.08vw;
  }
}
