.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 42px;
  max-width: 100%;
  position: relative;
  background: #ffffff;
  cursor: pointer;
  min-width: 0;
  border-radius: 5px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .root {
    height: 2.45vw;
  }
}
.root:hover {
  background: #505df7;
}
.text__terXn {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 24px;
  margin-bottom: 0px;
  margin-left: 10px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 1440px) {
  .text__terXn {
    font-size: 1.39vw;
  }
}
.root:hover .text__terXn {
  color: #ffffff;
}
.text__q4U5L {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  font-family: "Open Sans", sans-serif;
  color: #535353;
}
@media (max-width: 1440px) {
  .text__q4U5L {
    font-size: 0.81vw;
  }
}
.root:hover .text__q4U5L {
  color: #ffffff;
}
