.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #e5c49817;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 179px;
  margin-top: 50px;
  height: 90px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
@media (max-width: 1370px) {
  .img {
    margin-top: 35px;
  }
}
@media (max-width: 414px) {
  .img {
    width: 150px;
    margin-top: 30px;
  }
}
.text__nou3N {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #181818;
  margin-top: 32px;
  font-size: 38px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 1370px) {
  .text__nou3N {
    margin-top: 25px;
  }
}
@media (max-width: 414px) {
  .text__nou3N {
    font-size: 28px;
    margin-top: 20px;
  }
}
.freeBox__keBZa {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 48px;
  padding: 0px;
}
@media (max-width: 414px) {
  .freeBox__keBZa {
    width: 330px;
    margin-top: 29px;
  }
}
.text__t5KBi {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .text__t5KBi {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 414px) {
  .text__t5KBi {
    font-size: 13.7px;
  }
}
.password:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
@media (max-width: 414px) {
  .password:global(.__wab_instance) {
    width: 330px;
    flex-shrink: 0;
  }
}
.freeBox__wdAH {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 400px;
  margin-top: 30px;
  padding: 0px;
}
@media (max-width: 414px) {
  .freeBox__wdAH {
    width: 330px;
  }
}
.text__xg5He {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .text__xg5He {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 414px) {
  .text__xg5He {
    font-size: 13.7px;
  }
}
.confirmPassword:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
@media (max-width: 414px) {
  .confirmPassword:global(.__wab_instance) {
    width: 330px;
    flex-shrink: 0;
  }
}
.customErrorInstance:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 17px;
  margin-right: 0px;
  margin-left: 11px;
}
.text__nddhJ {
  color: #ff0000;
  font-size: 15px;
  line-height: 1.6;
}
@media (max-width: 414px) {
  .text__nddhJ {
    font-size: 13.7px;
  }
}
.submitButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 57px;
  max-width: 100%;
  background: #048b68;
  cursor: pointer;
  position: relative;
  margin-top: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 414px) {
  .submitButton {
    width: 340px;
    height: 52px;
    margin-top: 30px;
    margin-bottom: 40px;
    flex-shrink: 0;
  }
}
.root .submitButton:hover {
  background: #048b68d4;
}
.root .submitButton:active {
  background: #048b68d4;
  border: 1px solid #ffffff;
}
.text___2AMu {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
@media (max-width: 414px) {
  .text___2AMu {
    font-size: 17px;
  }
}
