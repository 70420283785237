.center {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 527px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  min-width: 0;
}
@media (max-width: 414px) {
  .center {
    width: 100%;
    justify-self: flex-start;
    min-width: 0;
  }
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 350px;
  height: 100%;
  align-self: auto;
  flex-direction: column;
  margin-left: 0px;
  margin-top: 10px;
  min-height: 0;
}
@media (max-width: 414px) {
  .figmaPaste {
    width: 329.5px;
  }
}
.text__whHkW {
  display: block;
  font-size: 14.8px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  color: #606060;
  position: relative;
  left: auto;
  top: auto;
  width: 132px;
  margin-left: 1px;
}
.cardNumber2 {
  height: 49px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0px;
  width: 350px;
  left: auto;
  top: auto;
  box-shadow: 0px 0px 0px 1px #cccccc;
  flex-shrink: 0;
  border-radius: 8px;
  margin: 10px 0px 0px;
}
@media (max-width: 414px) {
  .cardNumber2 {
    width: 329.5px;
  }
}
._1234123412341234 {
  position: relative;
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: -126px;
  flex-shrink: 0;
  min-height: 0;
}
@media (max-width: 414px) {
  ._1234123412341234 {
    width: 329.5px;
    margin-right: -119.2px;
    flex-shrink: 0;
  }
}
.cardNumber {
  position: relative;
  width: 350px;
  height: 100%;
  max-width: none;
  color: #565656;
  font-weight: 500;
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 14.8px;
  margin-top: 0px;
  min-height: 0;
  border-radius: 8px;
  padding: 0px 0px 0px 12.3px;
  border-width: 0px;
}
@media (max-width: 414px) {
  .cardNumber {
    width: 329.5px;
    padding-left: 12px;
  }
}
.center .cardNumber::placeholder {
  color: #818181;
  font-weight: 500;
}
.paymentMethodVisa {
  width: 27px;
  height: 20px;
  display: block;
  position: relative;
  align-self: auto;
  flex-shrink: 0;
}
.base {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(216, 216, 216, 1);
  width: 26.3px;
  height: 19.2px;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  pointer-events: none;
  border-radius: 4px;
}
@media (max-width: 1600px) {
  .base {
    width: 26px;
    height: 18.9px;
  }
}
@media (max-width: 414px) {
  .base {
    width: 24.8px;
    height: 17.9px;
  }
}
.svg__x0UdD {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #172b85;
  width: 18.44px;
  height: 5.8px;
  display: block;
  top: 7px;
  left: 4px;
  transform: translate(-0.015000000000000124px, -0.4720000000000004px);
  pointer-events: none;
}
@media (max-width: 1600px) {
  .svg__x0UdD {
    width: 18.14px;
    height: 5.7px;
    left: 4px;
    top: 7px;
  }
}
@media (max-width: 414px) {
  .svg__x0UdD {
    width: 17.44px;
    left: 4px;
    top: 7px;
  }
}
.icon {
  opacity: 0;
  width: 4.25px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .icon {
    width: 4.25px;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .icon {
    width: 2.78px;
    flex-shrink: 0;
  }
}
.paymentMethodMastercard {
  width: 27px;
  height: 20px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.base2 {
  background: #000000;
  box-shadow: inset 0px 0px 0px 1px #000000;
  width: 26.3px;
  height: 19.2px;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  pointer-events: none;
  border-radius: 4px;
}
@media (max-width: 1600px) {
  .base2 {
    width: 26px;
    height: 18.9px;
  }
}
@media (max-width: 414px) {
  .base2 {
    width: 24.8px;
    height: 17.9px;
  }
}
.img__eLpUf {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: none;
  width: 17.33px;
  height: 11.3px;
  display: block !important;
  top: 4px;
  left: 4.8px;
}
.img__eLpUf > picture > img {
  object-fit: cover;
}
@media (max-width: 1600px) {
  .img__eLpUf {
    left: 5px;
    top: 4px;
    width: 17.4px;
  }
}
@media (max-width: 414px) {
  .img__eLpUf {
    width: 17.4px;
    left: 4px;
    top: 4px;
  }
}
.icon2 {
  opacity: 0;
  width: 4.25px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .icon2 {
    width: 4.25px;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .icon2 {
    width: 2.78px;
    flex-shrink: 0;
  }
}
.paymentMethodAmex {
  width: 27px;
  height: 20px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.base3 {
  background: #1e71cc;
  box-shadow: inset 0px 0px 0px 1px #1e71cc;
  width: 26.3px;
  height: 19.2px;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  pointer-events: none;
  border-radius: 4px;
}
@media (max-width: 1600px) {
  .base3 {
    width: 26px;
    height: 18.9px;
  }
}
@media (max-width: 414px) {
  .base3 {
    width: 24.8px;
    height: 17.9px;
  }
}
.svg__alKme {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 21.75px;
  height: 6px;
  display: block;
  top: 7px;
  left: 2px;
  transform: translate(0.0990000000000002px, -0.4720000000000004px);
  pointer-events: none;
}
@media (max-width: 414px) {
  .svg__alKme {
    left: 2px;
    top: 7px;
  }
}
.icon3 {
  opacity: 0;
  width: 4.25px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .icon3 {
    width: 4.25px;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .icon3 {
    width: 2.78px;
    flex-shrink: 0;
  }
}
.paymentMethodDiscover {
  width: 27px;
  height: 20px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.base4 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(216, 216, 216, 1);
  width: 26.3px;
  height: 19.2px;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  pointer-events: none;
  border-radius: 4px;
}
@media (max-width: 1600px) {
  .base4 {
    width: 26px;
    height: 18.9px;
  }
}
@media (max-width: 414px) {
  .base4 {
    width: 24.86px;
    height: 17.9px;
  }
}
.img__z3Se1 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: none;
  width: 23.6px;
  height: 11.3px;
  display: block !important;
  top: 7px;
  left: 2px;
  margin-top: 0px;
  pointer-events: none;
}
.img__z3Se1 > picture > img {
  object-fit: cover;
}
@media (max-width: 414px) {
  .img__z3Se1 {
    left: 1.8px;
    top: 7px;
    width: 23.4px;
    margin-right: 0px;
  }
}
.text__qAtzM {
  display: block;
  font-size: 14.8px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  color: #606060;
  position: relative;
  left: auto;
  top: auto;
  width: 132px;
  margin-left: 1px;
  margin-top: 25px;
}
.freeBox__dGa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 48.5px;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  left: auto;
  top: auto;
  box-shadow: 0px 0px 0px 1px #cccccc;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 0px;
}
.expDate {
  width: 100%;
  height: 48.5px;
  max-width: none;
  color: #565656;
  font-weight: 500;
  margin-left: 0px;
  font-size: 14.8px;
  margin-right: 0px;
  margin-top: 0px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px 0px 0px 12.3px;
  border-width: 0px;
}
@media (max-width: 414px) {
  .expDate {
    padding-left: 12px;
  }
}
.center .expDate::placeholder {
  color: #818181;
}
.text__vNpZ {
  display: block;
  font-size: 14.8px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  color: #606060;
  position: relative;
  left: auto;
  top: auto;
  width: 132px;
  margin-left: 1px;
  margin-top: 25px;
}
.freeBox__uLxpx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 48.5px;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  left: auto;
  top: auto;
  box-shadow: 0px 0px 0px 1px #cccccc;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 0px;
}
.cvc {
  width: 100%;
  height: 48.5px;
  max-width: none;
  color: #565656;
  font-weight: 500;
  font-size: 14.8px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px 0px 0px 12.3px;
  border-width: 0px;
}
@media (max-width: 414px) {
  .cvc {
    padding-left: 12px;
  }
}
.center .cvc::placeholder {
  color: #818181;
}
.img__q0Rk {
  position: absolute;
  left: 314px;
  top: 12px;
  max-width: 7.5%;
  object-fit: cover;
  opacity: 0.5;
}
.img__q0Rk > picture > img {
  object-fit: cover;
}
.center .img__q0Rk {
  opacity: 0.7;
}
@media (max-width: 414px) {
  .img__q0Rk {
    left: 295px;
    top: 12px;
  }
}
.text__uibyA {
  display: block;
  font-size: 14.8px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  color: #606060;
  position: relative;
  left: auto;
  top: auto;
  width: 132px;
  margin-left: 1px;
  margin-top: 25px;
}
.freeBox___1Eud8 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 48.5px;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  left: auto;
  top: auto;
  box-shadow: 0px 0px 0px 1px #cccccc;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 0px;
}
.zip {
  width: 100%;
  height: 48.5px;
  max-width: none;
  color: #565656;
  font-weight: 500;
  margin-left: 0px;
  font-size: 14.8px;
  margin-right: 0px;
  margin-top: 0px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px 0px 0px 12.3px;
  border-width: 0px;
}
@media (max-width: 414px) {
  .zip {
    padding-left: 12px;
  }
}
.center .zip::placeholder {
  color: #818181;
}
.errorText {
  display: block;
  font-size: 14.8px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  color: #ff0000;
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  margin-left: 1px;
  margin-top: 20px;
  margin-bottom: -15px;
}
.payButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 471px;
  height: 49px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 35px;
  box-shadow: 0px 0px 0px 1px #3555ff;
  cursor: pointer;
  margin-bottom: 0px;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 0px;
  border: 0px solid #3555ff;
}
.center .payButton:hover {
  background: #3555ffd9;
  box-shadow: 0px 0px 0px 1px #3555ffd9;
  border-color: #3555ffd9;
}
.center .payButton:active {
  background: #3555ffd4;
  box-shadow: 0px 0px 0px 1px #ffffff;
  border: 0px solid #ffffff;
}
.text__iqM4S {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-weight: 600;
  color: #ffffff;
  font-size: 17px;
}
