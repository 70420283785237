.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(#ffffff, #ffffff), url("./images/dashboardLoginDarkTheme1Svg.svg"),
    linear-gradient(#ffffff, #ffffff), #ffffff;
  min-width: 0;
  min-height: 0;
}
.dashboardLoginDarkTheme {
  background: #059b74cc;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
@media (max-width: 414px) {
  .dashboardLoginDarkTheme {
    justify-content: center;
    align-items: center;
  }
}
.freeBox__c94Zo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  margin-bottom: 0vh;
  background: #ffffff;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__c94Zo {
    margin: 0% 0% 0vh;
  }
}
@media (max-width: 1370px) {
  .freeBox__c94Zo {
    margin: 0%;
  }
}
.frame1 {
  background: #e5c4980a;
  width: 570px;
  height: 527px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  box-shadow: 4px 4px 16px 4px #00000033;
  flex-shrink: 0;
  border-radius: 5px;
}
.frame1 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.frame1 > :global(.__wab_flex-container) > *,
.frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1 > :global(.__wab_flex-container) > picture > img,
.frame1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1370px) {
  .frame1 {
    width: 570px;
    height: 527px;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) > *,
  .frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .frame1 > :global(.__wab_flex-container) > picture > img,
  .frame1
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (max-width: 414px) {
  .frame1 {
    width: 350px;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .frame1 > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
  }
}
.text__pD9VA {
  display: block;
  font-size: 35px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.6;
  color: rgba(24, 24, 24, 1);
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(55px + 0px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  max-width: 476px;
  width: 100%;
  min-width: 0;
}
@media (max-width: 1440px) {
  .text__pD9VA {
    margin-top: calc(55px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .text__pD9VA {
    font-size: 35px;
    margin-top: calc(55px + 0px) !important;
    margin-left: calc(0px + 0px) !important;
  }
}
@media (max-width: 414px) {
  .text__pD9VA {
    font-size: 24px;
    max-width: 292px;
    width: 100%;
    margin-top: calc(0px + 0px) !important;
    margin-left: calc(0px + 0px) !important;
    min-width: 0;
  }
}
.text__qd4Xi {
  position: relative;
  width: 275px;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  color: #2f2f2f;
  font-size: 17.8px;
  text-align: center;
  line-height: 2;
  margin-top: calc(15px + 0px) !important;
  margin-bottom: 10px;
  font-weight: 500;
  display: none;
}
@media (max-width: 414px) {
  .text__qd4Xi {
    margin-top: calc(15px + 0px) !important;
    display: block;
  }
}
.freeBox__yaEyk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 370px;
  margin-top: calc(57px + 0px) !important;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
}
@media (max-width: 1440px) {
  .freeBox__yaEyk {
    margin-top: calc(57px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .freeBox__yaEyk {
    margin-top: calc(57px + 0px) !important;
  }
}
@media (max-width: 414px) {
  .freeBox__yaEyk {
    width: 290px;
    margin-top: calc(57px + 0px) !important;
    display: none;
  }
}
.slotTargetEmailText {
  font-family: "Poppins", sans-serif;
  font-size: 21px;
  color: #2f2f2f;
  text-align: center;
  line-height: 2.1;
  font-weight: 500;
}
@media (max-width: 1370px) {
  .slotTargetEmailText {
    font-size: 21px;
    color: #2f2f2f;
  }
}
@media (max-width: 414px) {
  .slotTargetEmailText {
    font-size: 18px;
  }
}
.merchantName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 17px;
  width: 372px;
  height: 55px;
  max-width: 100%;
  margin-top: calc(20px + 0px) !important;
  margin-bottom: 50px;
  text-align: left;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .merchantName {
    font-size: 17px;
    width: 372px;
    height: 55px;
    margin-top: calc(20px + 0px) !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .merchantName {
    margin-top: calc(20px + 0px) !important;
  }
}
@media (max-width: 414px) {
  .merchantName {
    margin-top: calc(20px + 0px) !important;
    display: none;
  }
}
.nextButton {
  box-shadow: 0px 4px 4px 0px #0000004d;
  background: #181818;
  width: 236px;
  height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: calc(8px + 0px) !important;
  transition-property: all;
  transition-duration: 1s;
  cursor: pointer;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-radius: 10px;
  border: 2px solid #272727f2;
}
@media (max-width: 1440px) {
  .nextButton {
    margin-top: calc(8px + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .nextButton {
    width: 236px;
    height: 57px;
    margin-top: calc(8px + 0px) !important;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: calc(0px + 0px) !important;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .nextButton {
    height: 51px;
    width: 234px;
    margin-top: calc(0px + 0px) !important;
    margin-left: calc(0px + 0px) !important;
    flex-shrink: 0;
    display: none;
  }
}
.root .nextButton:hover {
  background: #272727cc;
  margin-top: calc(8px + 0px) !important;
  border-color: #27272780;
}
.root .nextButton:active {
  background: #272727cc;
  margin-top: calc(8px + 0px) !important;
  border-width: 0px;
  border-color: #272727f2;
}
@media (max-width: 1370px) {
  .root .nextButton:hover {
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(8px + 0px) !important;
  }
}
@media (max-width: 414px) {
  .root .nextButton:hover {
    margin-top: calc(0px + 0px) !important;
    margin-left: calc(0px + 0px) !important;
  }
}
.text__aOi55 {
  display: block;
  font-size: 23px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  transform: translate(0.41900000000001114px, -0.28899999999998727px);
}
@media (max-width: 1370px) {
  .text__aOi55 {
    font-size: 23px;
  }
}
@media (max-width: 414px) {
  .text__aOi55 {
    font-size: 19px;
  }
}
