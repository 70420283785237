.file-modal {
    width: 750px;
    max-width: 750px !important;
}

.custom-progress-bar {
    margin-top: 30px !important;
    height: 30px !important; /* Adjust the height as needed */
    width: 550px !important; /* Adjust the width as needed */
  }

  .text1 {
    font-size: 25px;
    font-weight: 500;
    margin-top: 10px;
  }