.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #28437917;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 179px;
  margin-top: 50px;
  height: 90px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
@media (max-width: 1370px) {
  .img {
    margin-top: 35px;
  }
}
@media (max-width: 414px) {
  .img {
    width: 150px;
    margin-top: 30px;
  }
}
.text__sZjFe {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #181818;
  margin-top: 30px;
  font-size: 38px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 1370px) {
  .text__sZjFe {
    margin-top: 25px;
  }
}
@media (max-width: 414px) {
  .text__sZjFe {
    font-size: 28px;
    margin-top: 20px;
  }
}
.text__rLyax {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 570px;
  font-weight: 400;
  color: #0e0e0e;
  margin-top: 32px;
  font-size: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  line-height: 1.9;
  min-width: 0;
}
@media (max-width: 414px) {
  .text__rLyax {
    font-size: 15px;
    max-width: 330px;
    width: 330px;
    margin-top: 28px;
  }
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 37px;
  padding: 0px;
}
@media (max-width: 414px) {
  .freeBox {
    width: 330px;
    margin-top: 29px;
  }
}
.text__iZgoy {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .text__iZgoy {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 414px) {
  .text__iZgoy {
    font-size: 13.7px;
  }
}
.loginEmailInput:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
@media (max-width: 1370px) {
  .loginEmailInput:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .loginEmailInput:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.invalidEmailText {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  color: #ff0000;
  font-size: 15px;
  margin: 17px 0px 0px 11px;
}
@media (max-width: 414px) {
  .invalidEmailText {
    font-size: 13.7px;
  }
}
.emailSuccessText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-bottom: 0px;
  margin-top: 17px;
  align-self: flex-start;
  margin-left: 11px;
  font-size: 15px;
  left: auto;
  top: auto;
  color: #009e09;
}
@media (max-width: 1440px) {
  .emailSuccessText {
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -20px;
    margin-top: -5px;
    align-self: flex-start;
    margin-left: 98px;
    font-size: 15px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .emailSuccessText {
    font-size: 13.5px;
    margin-left: 80px;
    margin-top: -15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -20px;
    align-self: flex-start;
    left: auto;
    top: auto;
  }
}
.resetPasswordButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 57px;
  max-width: 100%;
  background: #14377f;
  cursor: pointer;
  position: relative;
  margin-top: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 414px) {
  .resetPasswordButton {
    width: 340px;
    height: 52px;
    margin-top: 30px;
    margin-bottom: 40px;
    flex-shrink: 0;
  }
}
.root .resetPasswordButton:hover {
  background: #284379d4;
}
.root .resetPasswordButton:active {
  background: #284379d4;
  border: 1px solid #ffffff;
}
.text__lkq2P {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
@media (max-width: 414px) {
  .text__lkq2P {
    font-size: 17px;
  }
}
