.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #3555ff;
  min-width: 0;
  min-height: 0;
}
.previewBody {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__yEn9F {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.companyName {
  display: flex;
  width: 400px;
  height: 58px;
  margin-bottom: 2%;
  padding-top: 0px;
  margin-top: 0%;
  justify-content: center;
  flex-direction: row;
  flex-shrink: 0;
}
.slotTargetMerchantName {
  font-family: "Poppins", sans-serif;
  font-size: 38px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
.rectangle29 {
  background: rgba(255, 255, 255, 1);
  width: 398px;
  height: auto;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  margin-bottom: 32px;
  margin-top: 0px;
  border-radius: 10px;
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  min-width: 0;
  margin: 0px;
}
.freeBox__jwTp6 {
  display: flex;
  position: relative;
  width: 361px;
  left: auto;
  top: auto;
  height: auto;
  margin-left: 23px;
  margin-top: 19.5px;
}
.slotTargetEscrowDeposit {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.freeBox__ou2Iq {
  display: flex;
  position: relative;
  width: 355px;
  height: auto;
  left: auto;
  top: auto;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 23px;
  margin-bottom: 0px;
  margin-left: 23px;
}
.slotTargetMilestoneOrContract {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 1.6;
  letter-spacing: 0.2px;
  text-align: left;
}
.svg__kOwr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.9990125124341537, 0.9990075074918353)
    rotate(-0.28676326876410735deg) skew(-0.05735167682688388deg, 0deg);
  width: 241px;
  height: 1em;
  display: block;
  transform-origin: top left;
  left: auto;
  top: auto;
  margin-left: 22px;
  margin-top: 11px;
  flex-shrink: 0;
}
.viewDetails {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(95, 86, 86, 1);
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  margin-left: 26px;
  margin-bottom: 15px;
  min-width: 0;
}
.rectangle28 {
  background: rgba(255, 255, 255, 1);
  width: 400px;
  height: 200px;
  display: block;
  position: relative;
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste2 {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.text__rJavh {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-weight: 700;
  margin-top: 26px;
  margin-bottom: 18px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.svg__uQe6M {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 241px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: auto;
  top: auto;
  flex-shrink: 0;
}
.handleBankTransfer {
  background: #000000;
  width: 270px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 0px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #000000;
}
.root .handleBankTransfer:hover {
  background: #000000d4;
  border-color: transparent;
}
.root .handleBankTransfer:active {
  background: #000000d4;
  box-shadow: 0px 0px 0px 2px #d3d3d3;
  border-color: transparent;
}
.text__yvyvm {
  display: block;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  padding-bottom: 0px;
}
.figmaPaste3 {
  display: flex;
  position: relative;
  width: 337px;
  height: 127px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.text__zUn0K {
  display: block;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  position: relative;
  left: auto;
  top: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-top: 50px;
}
.sideBarInstance {
  display: block;
  position: relative;
  width: 431px;
  height: 100%;
  left: auto;
  top: auto;
  background: #ffffff;
  flex-shrink: 0;
  min-height: 0;
}
.freeBox__kfhsq {
  display: flex;
  position: absolute;
  width: 256px;
  left: 44px;
  top: 139px;
}
.slotTargetContractName {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.freeBox__jxoxq {
  display: flex;
  position: absolute;
  width: 151px;
  left: 44px;
  top: 57px;
}
.slotTargetAmount {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.closeButton {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(112, 112, 112, 1);
  width: auto;
  position: absolute;
  left: 382px;
  top: 27px;
  cursor: pointer;
}
.text___9KswW {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(112, 112, 112, 1);
  width: 63px;
  position: absolute;
  left: 319px;
  top: 142px;
}
.milestoneNameStack {
  display: flex;
  position: absolute;
  width: 329px;
  left: 44px;
  top: 210px;
}
.slotTargetMilestoneName {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 1.8;
  letter-spacing: 0.2px;
}
.svg__nK8AJ {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 38px;
  top: 322px;
}
.viewContract {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: 194px;
  position: absolute;
  left: 128px;
  top: 367px;
}
.lowerBar {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 44px;
  top: 577px;
}
.downloadContract {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  left: 166px;
  top: 431px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
  padding: 0px;
}
.svg__nrEt3 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: translate(0.40000000000000036px, -0.08099999999999996px);
  width: 4vw;
  height: 5vw;
  display: block;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.freeBox__msdxO {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1.6vw;
  height: 1.7vw;
  max-width: 100%;
  background: #ffffff;
  left: 68.83%;
  top: 76.04%;
  flex-shrink: 0;
  border-radius: 1000px;
  padding: 0px;
  border-width: 1px;
  border-style: solid;
}
.svg__bj1Vv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: 1em;
  flex-shrink: 0;
}
