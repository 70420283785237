.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 0px;
}
.text__fV07L {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #000000;
  margin-top: calc(10px + 0px) !important;
  margin-bottom: 3%;
  margin-left: 7.5%;
  font-size: 16px;
  align-self: flex-start;
}
.subscriptionStatus {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  height: 38px;
  max-width: 100%;
  margin-left: 7%;
  margin-top: calc(5px + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .subscriptionStatus {
    margin-left: 3.6vw;
    margin-top: calc(1.7vw + 0px) !important;
    width: 17.9vw;
    height: 2.29vw;
    flex-shrink: 0;
  }
}
.text__hGsL7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-left: 7.55%;
  margin-bottom: 3%;
  font-size: 16px;
  margin-top: calc(38px + 0px) !important;
  margin-right: 0px;
  min-width: 0;
}
.cycleStack {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  height: 38px;
  max-width: 100%;
  margin-left: 7%;
  margin-top: calc(5px + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .cycleStack {
    margin-left: 3.6vw;
    margin-top: calc(1.7vw + 0px) !important;
    width: 17.9vw;
    height: 2.29vw;
    flex-shrink: 0;
  }
}
.text__bbTgo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-left: 7.55%;
  margin-bottom: 3%;
  font-size: 16px;
  margin-top: calc(38px + 0px) !important;
  margin-right: 0px;
  min-width: 0;
}
.subscriptionAmount {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  height: 38px;
  max-width: 100%;
  margin-left: 7%;
  margin-top: calc(5px + 0px) !important;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .subscriptionAmount {
    margin-left: 3.6vw;
    margin-top: calc(1.7vw + 0px) !important;
    width: 17.9vw;
    height: 2.29vw;
    flex-shrink: 0;
  }
}
