.root {
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  transition-property: background;
  transition-duration: 0.1s;
  background: #14377f;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.1s;
  border-radius: 6px;
  padding: 8px 20px;
  border-width: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.rootshowStartIcon {
  padding-left: 16px;
}
.rootshowEndIcon {
  padding-right: 16px;
}
.rootisDisabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.rootshape_rounded {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100px;
  border-radius: 999px;
}
.rootshape_round {
  border-radius: 50%;
  padding: 12px;
}
.rootshape_sharp {
  border-radius: 0px;
}
.rootsize_compact {
  padding: 6px 16px;
}
.rootsize_minimal {
  padding: 0px;
}
.rootcolor_blue {
  background: #0091ff;
}
.rootcolor_green {
  background: #30a46c;
}
.rootcolor_yellow {
  background: #f5d90a;
}
.rootcolor_red {
  background: #e54d2e;
}
.rootcolor_sand {
  background: #717069;
}
.rootcolor_white {
  background: #ffffff;
}
.rootcolor_softBlue {
  background: #edf6ff;
}
.rootcolor_softGreen {
  background: #e9f9ee;
}
.rootcolor_softYellow {
  background: #fffbd1;
}
.rootcolor_softRed {
  background: #fff0ee;
}
.rootcolor_softSand {
  background: #eeeeec;
}
.rootcolor_clear {
  background: #ffffff00;
}
.rootcolor_link {
  background: #ffffff00;
}
.rootshape_rounded_showStartIcon {
  padding-left: 16px;
}
.rootshowEndIcon_shape_rounded {
  padding-right: 16px;
}
.rootshape_round_size_compact {
  padding: 6px;
}
.root___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.root:focus-within {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.root:hover {
  background: #284379d4;
}
.root:active {
  background: #284379d4;
  border-width: 1px;
}
.rootcolor_blue:hover {
  background: #369eff;
}
.rootcolor_blue:active {
  background: #52a9ff;
}
.rootcolor_green:hover {
  background: #3cb179;
}
.rootcolor_green:active {
  background: #4cc38a;
}
.rootcolor_yellow:hover {
  background: #ffef5c;
}
.rootcolor_yellow:active {
  background: #f0c000;
}
.rootcolor_red:hover {
  background: #ec5e41;
}
.rootcolor_red:active {
  background: #f16a50;
}
.rootcolor_sand:hover {
  background: #7f7e77;
}
.rootcolor_sand:active {
  background: #a1a09a;
}
.rootcolor_white:hover {
  background: #ffef5c;
}
.rootcolor_white:active {
  background: #f0c000;
}
.rootcolor_softBlue:hover {
  background: #e1f0ff;
}
.rootcolor_softBlue:active {
  background: #cee7fe;
}
.rootcolor_softGreen:active {
  background: #ccebd7;
}
.rootcolor_softGreen:hover {
  background: #ddf3e4;
}
.rootcolor_softYellow:active {
  background: #fef2a4;
}
.rootcolor_softYellow:hover {
  background: #fff8bb;
}
.rootcolor_softRed:active {
  background: #fdd8d3;
}
.rootcolor_softRed:hover {
  background: #ffe6e2;
}
.rootcolor_softSand:hover {
  background: #e9e9e6;
}
.rootcolor_softSand:active {
  background: #e3e3e0;
}
.rootcolor_clear:hover {
  background: #e9e9e6;
}
.rootcolor_clear:active {
  background: #e3e3e0;
}
.rootcolor_link:hover {
  background: #ffffff00;
}
.rootcolor_link:active {
  background: #ffffff00;
}
.startIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.startIconContainershowStartIcon {
  display: flex;
}
.slotTargetStartIcon {
  color: #ededec;
}
.slotTargetStartIconcolor_yellow {
  color: #35290f;
}
.slotTargetStartIconcolor_white {
  color: #35290f;
}
.slotTargetStartIconcolor_softBlue {
  color: #006adc;
}
.slotTargetStartIconcolor_softGreen {
  color: #18794e;
}
.slotTargetStartIconcolor_softYellow {
  color: #946800;
}
.slotTargetStartIconcolor_softRed {
  color: #ca3214;
}
.slotTargetStartIconcolor_softSand {
  color: #706f6c;
}
.slotTargetStartIconcolor_clear {
  color: #1b1b18;
}
.slotTargetStartIconcolor_link {
  color: #0091ff;
}
.rootcolor_link:hover .slotTargetStartIconcolor_link {
  color: #0081f1;
}
.rootcolor_link:active .slotTargetStartIconcolor_link {
  color: #006adc;
}
.svg__et2EY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.root .contentContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetChildren {
  color: #ededec;
  font-weight: 500;
  white-space: pre;
}
.slotTargetChildrencolor_blue {
  color: #ffffff;
}
.slotTargetChildrencolor_green {
  color: #ffffff;
}
.slotTargetChildrencolor_yellow {
  color: #35290f;
}
.slotTargetChildrencolor_red {
  color: #ffffff;
}
.slotTargetChildrencolor_sand {
  color: #ffffff;
}
.slotTargetChildrencolor_white {
  color: #1b1b18;
}
.slotTargetChildrencolor_softBlue {
  color: #006adc;
}
.slotTargetChildrencolor_softGreen {
  color: #18794e;
}
.slotTargetChildrencolor_softYellow {
  color: #946800;
}
.slotTargetChildrencolor_softRed {
  color: #ca3214;
}
.slotTargetChildrencolor_softSand {
  color: #1b1b18;
}
.slotTargetChildrencolor_clear {
  color: #1b1b18;
}
.slotTargetChildrencolor_link {
  color: #0091ff;
}
.root:focus-within .slotTargetChildren > *,
.root:focus-within .slotTargetChildren > :global(.__wab_slot) > *,
.root:focus-within
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within .slotTargetChildren > picture > img,
.root:focus-within .slotTargetChildren > :global(.__wab_slot) > picture > img,
.root:focus-within
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.root .slotTargetChildren___focusVisibleWithin > *,
.root .slotTargetChildren___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisibleWithin > picture > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.rootcolor_link:hover .slotTargetChildrencolor_link {
  color: #0081f1;
}
.rootcolor_link:hover .slotTargetChildrencolor_link > :global(.__wab_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_expr_html_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot-string-wrapper),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: underline;
}
.rootcolor_link:active .slotTargetChildrencolor_link {
  color: #006adc;
}
.endIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.endIconContainershowEndIcon {
  display: flex;
}
.slotTargetEndIcon {
  color: #ededec;
}
.slotTargetEndIconcolor_yellow {
  color: #35290f;
}
.slotTargetEndIconcolor_white {
  color: #35290f;
}
.slotTargetEndIconcolor_softBlue {
  color: #006adc;
}
.slotTargetEndIconcolor_softGreen {
  color: #18794e;
}
.slotTargetEndIconcolor_softYellow {
  color: #946800;
}
.slotTargetEndIconcolor_softRed {
  color: #ca3214;
}
.slotTargetEndIconcolor_softSand {
  color: #706f6c;
}
.slotTargetEndIconcolor_clear {
  color: #1b1b18;
}
.slotTargetEndIconcolor_link {
  color: #0091ff;
}
.rootcolor_link:hover .slotTargetEndIconcolor_link {
  color: #0081f1;
}
.rootcolor_link:active .slotTargetEndIconcolor_link {
  color: #006adc;
}
.svg___2FAfs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
