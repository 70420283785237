.checkboxGroupItem2 {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  box-shadow: 0px 0px 0px 2px #eaecf0;
  position: relative;
  cursor: pointer;
  min-width: 0;
  min-height: 0;
  border-radius: 12px;
  padding: 16px;
}
.checkboxGroupItem2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.checkboxGroupItem2 > :global(.__wab_flex-container) > *,
.checkboxGroupItem2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checkboxGroupItem2 > :global(.__wab_flex-container) > picture > img,
.checkboxGroupItem2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
@media (max-width: 1370px) {
  .checkboxGroupItem2 {
    padding-bottom: 16px;
  }
}
.checkboxGroupItem2selected {
  background: #f5f7ff;
  box-shadow: 0px 0px 0px 2px #14377f;
}
.checkboxGroupItem2:hover {
  box-shadow: 0px 0px 0px 2px #d0d5dd;
}
.checkboxGroupItem2selected:hover {
  box-shadow: 0px 0px 0px 2px #14377f;
}
.content3 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
}
.content3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.content3 > :global(.__wab_flex-container) > *,
.content3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content3 > :global(.__wab_flex-container) > picture > img,
.content3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.featuredIcon2 {
  background: #14377f;
  box-shadow: 0px 0px 0px 2px #14377f, inset 0px 0px 0px 2px #14377f;
  mix-blend-mode: multiply;
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 28px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 52%;
  left: auto;
  top: auto;
}
.img > picture > img {
  object-fit: cover;
}
.textAndSupportingText2 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textAndSubtext2 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.textAndSubtext2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.textAndSubtext2 > :global(.__wab_flex-container) > *,
.textAndSubtext2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textAndSubtext2 > :global(.__wab_flex-container) > picture > img,
.textAndSubtext2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.text2 {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(51, 63, 83, 1);
  position: relative;
  padding-left: 0px;
}
@media (max-width: 1440px) {
  .text2 {
    font-size: 0.99vw;
  }
}
.subtext2 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(104, 64, 198, 1);
  position: relative;
}
.includesUpTo10Users20GbIndividualDataAndAccessToAllFeatures3 {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(104, 64, 198, 1);
  align-self: stretch;
  position: relative;
}
.includesUpTo10Users20GbIndividualDataAndAccessToAllFeatures4 {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 31px;
  color: rgba(102, 106, 132, 1);
  align-self: stretch;
  position: relative;
}
@media (max-width: 1440px) {
  .includesUpTo10Users20GbIndividualDataAndAccessToAllFeatures4 {
    font-size: 0.99vw;
    line-height: 1.8vw;
  }
}
@media (max-width: 1370px) {
  .includesUpTo10Users20GbIndividualDataAndAccessToAllFeatures4 {
    margin-top: calc(0px + 16px) !important;
    margin-bottom: 0px;
  }
}
.checkboxBase2 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(207, 212, 220, 1);
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 8px;
}
.checkboxBase2selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #14377f;
  box-shadow: inset 0px 0px 0px 1px #14377f;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 10px;
  height: 10px;
  display: block;
  top: 7px;
  left: 9px;
  overflow: hidden;
}
.svgselected {
  position: relative;
  left: auto;
  top: auto;
}
