.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(#ffffff, #ffffff), url("./images/dashboardLoginDarkTheme1Svg.svg"),
    linear-gradient(#ffffff, #ffffff), #ffffff;
  min-width: 0;
  min-height: 0;
}
.dashboardLoginDarkTheme {
  background: #4f45fc;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
@media (max-width: 414px) {
  .dashboardLoginDarkTheme {
    justify-content: center;
    align-items: center;
  }
}
.frame1 {
  background: rgba(255, 255, 255, 1);
  width: 570px;
  height: 498px;
  display: flex;
  overflow: hidden;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
  margin-bottom: 8%;
  flex-direction: column;
  box-shadow: 4px 4px 16px 4px #00000033;
  flex-shrink: 0;
  border-radius: 5px;
}
.frame1 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.frame1 > :global(.__wab_flex-container) > *,
.frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1 > :global(.__wab_flex-container) > picture > img,
.frame1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 50px;
}
@media (max-width: 1440px) {
  .frame1 {
    margin: 0% 0% 13%;
  }
}
@media (max-width: 1370px) {
  .frame1 {
    width: 570px;
    height: 564px;
    flex-shrink: 0;
    margin: 0% 0% 8%;
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) > *,
  .frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .frame1 > :global(.__wab_flex-container) > picture > img,
  .frame1
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 30px;
  }
}
@media (max-width: 414px) {
  .frame1 {
    width: 350px;
    height: 500px;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .frame1 > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
  }
}
.text__viyg7 {
  display: block;
  font-size: 35px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.6;
  color: rgba(24, 24, 24, 1);
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(50px + 50px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  max-width: 476px;
  width: 100%;
  min-width: 0;
}
@media (max-width: 1370px) {
  .text__viyg7 {
    font-size: 35px;
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(50px + 30px) !important;
  }
}
@media (max-width: 414px) {
  .text__viyg7 {
    font-size: 24px;
    max-width: 292px;
    width: 100%;
    margin-top: calc(0px + 30px) !important;
    margin-left: calc(0px + 0px) !important;
    min-width: 0;
  }
}
.text__iWKc {
  position: relative;
  width: 275px;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  color: #2f2f2f;
  font-size: 18px;
  text-align: center;
  line-height: 2;
  margin-top: calc(10px + 50px) !important;
  margin-bottom: 10px;
  font-weight: 500;
  display: none;
}
@media (max-width: 414px) {
  .text__iWKc {
    margin-top: calc(10px + 30px) !important;
    display: block;
  }
}
.freeBox {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 412px;
  margin-top: calc(0px + 50px) !important;
  justify-content: center;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
@media (max-width: 1370px) {
  .freeBox {
    margin-top: calc(28px + 30px) !important;
  }
}
@media (max-width: 414px) {
  .freeBox {
    width: 290px;
    margin-top: calc(28px + 30px) !important;
    display: none;
  }
}
.slotTargetEmailText {
  font-family: "Poppins", sans-serif;
  font-size: 21px;
  color: #2f2f2f;
  text-align: center;
  line-height: 2;
}
@media (max-width: 1370px) {
  .slotTargetEmailText {
    font-size: 21px;
    color: #2f2f2f;
  }
}
@media (max-width: 414px) {
  .slotTargetEmailText {
    font-size: 18px;
  }
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: auto;
  display: block !important;
  top: auto;
  left: auto;
}
.img > picture > img {
  object-fit: cover;
}
@media (max-width: 414px) {
  .img {
    display: none !important;
  }
}
