.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__bNyg7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__igDs5 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__qQhQw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox__pzMbc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___74H4I {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__uhAAp {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__pMqIl {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__pzbLj {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__ec8Yq {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__exbDp {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__uDIja {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg___9HQSd {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__qQAeu {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___7OtVe {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.freeBox__neicm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__neicm > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__neicm > :global(.__wab_flex-container) > *,
.freeBox__neicm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__neicm > :global(.__wab_flex-container) > picture > img,
.freeBox__neicm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__xg5Ia {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___54Idc {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__hcSo {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__lMnBg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__b16S6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 1051px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  margin-right: 0px;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.text__miLnJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 500;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 40px 0px 20px;
}
.freeBox__lx1TI {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox___58PP {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text__qYzNu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__iNvT8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text___1XGo7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__ckRbZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__piYax {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.textInput {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option___9Jta:global(.__wab_instance) {
  position: relative;
}
.option__xbzTc:global(.__wab_instance) {
  position: relative;
}
.option___0LQuV:global(.__wab_instance) {
  position: relative;
}
.option__tdasz:global(.__wab_instance) {
  position: relative;
}
.option___5Exfv:global(.__wab_instance) {
  position: relative;
}
.option__qqPvF:global(.__wab_instance) {
  position: relative;
}
.option__v0SVx:global(.__wab_instance) {
  position: relative;
}
.option___2VPi:global(.__wab_instance) {
  position: relative;
}
.option__jverJ:global(.__wab_instance) {
  position: relative;
}
.option__sKi1B:global(.__wab_instance) {
  position: relative;
}
.option__zxiPf:global(.__wab_instance) {
  position: relative;
}
.option__vaqH4:global(.__wab_instance) {
  position: relative;
}
.option__frOIo:global(.__wab_instance) {
  position: relative;
}
.option__bhHyi:global(.__wab_instance) {
  position: relative;
}
.option__vOxVl:global(.__wab_instance) {
  position: relative;
}
.option__pLd3X:global(.__wab_instance) {
  position: relative;
}
.option__gJl3Q:global(.__wab_instance) {
  position: relative;
}
.option__nfMmn:global(.__wab_instance) {
  position: relative;
}
.option__fMpBw:global(.__wab_instance) {
  position: relative;
}
.option__eAmst:global(.__wab_instance) {
  position: relative;
}
.option__mJ2WK:global(.__wab_instance) {
  position: relative;
}
.option__eu11Q:global(.__wab_instance) {
  position: relative;
}
.option__gw5Gh:global(.__wab_instance) {
  position: relative;
}
.option__sgPYc:global(.__wab_instance) {
  position: relative;
}
.option___8LmSt:global(.__wab_instance) {
  position: relative;
}
.option__dB61P:global(.__wab_instance) {
  position: relative;
}
.option__pV2N:global(.__wab_instance) {
  position: relative;
}
.option__aVmx6:global(.__wab_instance) {
  position: relative;
}
.option__wD8OD:global(.__wab_instance) {
  position: relative;
}
.option__aNoCy:global(.__wab_instance) {
  position: relative;
}
.option__ztX5Z:global(.__wab_instance) {
  position: relative;
}
.option__ciMi6:global(.__wab_instance) {
  position: relative;
}
.option__iDc7I:global(.__wab_instance) {
  position: relative;
}
.option__eh0Tn:global(.__wab_instance) {
  position: relative;
}
.option__mFeE:global(.__wab_instance) {
  position: relative;
}
.option__gS3N0:global(.__wab_instance) {
  position: relative;
}
.option__k8WGc:global(.__wab_instance) {
  position: relative;
}
.option__imbtu:global(.__wab_instance) {
  position: relative;
}
.option___7Ein7:global(.__wab_instance) {
  position: relative;
}
.option__dmkIh:global(.__wab_instance) {
  position: relative;
}
.option__zDQsp:global(.__wab_instance) {
  position: relative;
}
.option__rrgHq:global(.__wab_instance) {
  position: relative;
}
.option__zmRA:global(.__wab_instance) {
  position: relative;
}
.option__cdgg3:global(.__wab_instance) {
  position: relative;
}
.option__rsFnD:global(.__wab_instance) {
  position: relative;
}
.option__jdtMw:global(.__wab_instance) {
  position: relative;
}
.option__vuN9P:global(.__wab_instance) {
  position: relative;
}
.option__uzve6:global(.__wab_instance) {
  position: relative;
}
.option__fk3WN:global(.__wab_instance) {
  position: relative;
}
.option__euNtd:global(.__wab_instance) {
  position: relative;
}
.option__y7Bap:global(.__wab_instance) {
  position: relative;
}
.option__eaCZx:global(.__wab_instance) {
  position: relative;
}
.option__jkW1:global(.__wab_instance) {
  position: relative;
}
.option__gg4Rg:global(.__wab_instance) {
  position: relative;
}
.option___9O8Ng:global(.__wab_instance) {
  position: relative;
}
.option__weiCs:global(.__wab_instance) {
  position: relative;
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__zAdMh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__edIk4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.freeBox__v2O3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__zOfK2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  padding-top: 0px;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.continueButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 51px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .continueButton:hover {
  background: #3555ffd4;
}
.root .continueButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__orki {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
