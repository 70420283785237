.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__kj47Z {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__y3BzG {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__rVnFx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox__tk359 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__jauIu {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__nq7UI {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__hpwdY {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___0BnMy {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__dAVxu {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__kDeqh {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__cwZaG {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__c9H9H {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox__kcMbi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__kcMbi > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__kcMbi > :global(.__wab_flex-container) > *,
.freeBox__kcMbi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kcMbi > :global(.__wab_flex-container) > picture > img,
.freeBox__kcMbi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__rtYTs {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___9T4T {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__ohtQo {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__vZ0CZ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px 0px 0px 20px;
}
.title12 {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  color: #000000;
  font-weight: 600;
  font-size: 19px;
  line-height: 100%;
  letter-spacing: 0.3px;
  margin: 30px 0px 0px;
}
.title13 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 547px;
  color: #515151;
  font-size: 15.5px;
  line-height: 2;
  letter-spacing: 0.3px;
  min-width: 0px;
  padding: 0px;
  margin: 25px 0px 0px;
}
.freeBox__ciMo9 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  flex-shrink: 0;
  padding: 0% 0px 0px;
  margin: 30px 0px 0px;
}
.freeCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  left: auto;
  top: auto;
  z-index: 1;
}
.title8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 600;
  font-size: 15.4px;
  line-height: 100%;
  letter-spacing: 0.3px;
  min-width: 0;
  padding: 0px 0px 0px 13px;
  margin: 0px;
}
.freeBox__hnUqt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  flex-shrink: 0;
  padding: 0% 0px 0px;
  margin: 10px 0px 0px;
}
.premiumCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  left: auto;
  top: auto;
  z-index: 1;
}
.title10 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 600;
  font-size: 15.4px;
  line-height: 100%;
  letter-spacing: 0.3px;
  min-width: 0;
  padding: 0px 0px 0px 13px;
  margin: 0px;
}
.freeBox___7Fchv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  flex-shrink: 0;
  padding: 0% 0px 0px;
  margin: 10px 0px 0px;
}
.growthCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  left: auto;
  top: auto;
  z-index: 1;
}
.title11 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 600;
  font-size: 15.4px;
  line-height: 100%;
  letter-spacing: 0.3px;
  min-width: 0;
  padding: 0px 0px 0px 13px;
  margin: 0px;
}
.freeBox__ePgki {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  flex-shrink: 0;
  padding: 0% 0px 0px;
  margin: 10px 0px 0px;
}
.customCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  left: auto;
  top: auto;
  z-index: 1;
}
.title14 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 600;
  font-size: 15.4px;
  line-height: 100%;
  letter-spacing: 0.3px;
  min-width: 0;
  padding: 0px 0px 0px 13px;
  margin: 0px;
}
.errorText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #535353;
  font-size: 15px;
  margin: 35px 0px -30px;
}
.submitButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 57px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .submitButton:hover {
  background: #3555ffd4;
}
.root .submitButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__yal2D {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
