.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__ljAye {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__a3WKk {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__eMxKa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox___8DzWx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__d9Xwm {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__mvTfj {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__jEfHj {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__ubEmu {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__mBh5W {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__irzRu {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__wneeM {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox__aYt0U {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__aYt0U > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__aYt0U > :global(.__wab_flex-container) > *,
.freeBox__aYt0U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aYt0U > :global(.__wab_flex-container) > picture > img,
.freeBox__aYt0U
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__osBrQ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__enAvy {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__mMhhv {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__o72I {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__ixYjr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 2456px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.text__lYopC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 16.3px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  padding-bottom: 0px;
  min-width: 0;
  margin: 39px 0px 3px;
}
.freeBox__kjXvC {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__o2So1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text__yv7Rz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__ovnMj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text__yDba0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.lastNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .lastNameError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.firstNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .firstNameError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__kKpzv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___9ATf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.legalBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.legalBusinessNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .legalBusinessNameError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox___4Hz8I {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__j86RW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.displayBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.displayBusinessNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .displayBusinessNameError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__xNr6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__skWfx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.textInput {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.addressError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .addressError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.cityError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .cityError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__oi5Mx:global(.__wab_instance) {
  position: relative;
}
.option__qPqkn:global(.__wab_instance) {
  position: relative;
}
.option__diac:global(.__wab_instance) {
  position: relative;
}
.option__wHfmq:global(.__wab_instance) {
  position: relative;
}
.option__yyiJv:global(.__wab_instance) {
  position: relative;
}
.option__qN618:global(.__wab_instance) {
  position: relative;
}
.option__xkujg:global(.__wab_instance) {
  position: relative;
}
.option__g7KCh:global(.__wab_instance) {
  position: relative;
}
.option__wtWbh:global(.__wab_instance) {
  position: relative;
}
.option__oBp35:global(.__wab_instance) {
  position: relative;
}
.option__zrvWf:global(.__wab_instance) {
  position: relative;
}
.option__tCwol:global(.__wab_instance) {
  position: relative;
}
.option__mzOOt:global(.__wab_instance) {
  position: relative;
}
.option___8WPza:global(.__wab_instance) {
  position: relative;
}
.option__wcjUq:global(.__wab_instance) {
  position: relative;
}
.option__trTVd:global(.__wab_instance) {
  position: relative;
}
.option__b2Lz:global(.__wab_instance) {
  position: relative;
}
.option__qfvYq:global(.__wab_instance) {
  position: relative;
}
.option__kAf3U:global(.__wab_instance) {
  position: relative;
}
.option__npo7P:global(.__wab_instance) {
  position: relative;
}
.option__b56Hz:global(.__wab_instance) {
  position: relative;
}
.option___2PKWj:global(.__wab_instance) {
  position: relative;
}
.option__nvTnq:global(.__wab_instance) {
  position: relative;
}
.option__roB0E:global(.__wab_instance) {
  position: relative;
}
.option___2RCUx:global(.__wab_instance) {
  position: relative;
}
.option___48N1R:global(.__wab_instance) {
  position: relative;
}
.option__jvCFg:global(.__wab_instance) {
  position: relative;
}
.option__pW3Qp:global(.__wab_instance) {
  position: relative;
}
.option__cW6Jq:global(.__wab_instance) {
  position: relative;
}
.option___4G1Oh:global(.__wab_instance) {
  position: relative;
}
.option___7H9Lf:global(.__wab_instance) {
  position: relative;
}
.option__oOj:global(.__wab_instance) {
  position: relative;
}
.option__tl8IW:global(.__wab_instance) {
  position: relative;
}
.option__oXsDh:global(.__wab_instance) {
  position: relative;
}
.option__az4Vq:global(.__wab_instance) {
  position: relative;
}
.option__bpnRo:global(.__wab_instance) {
  position: relative;
}
.option__ruHaj:global(.__wab_instance) {
  position: relative;
}
.option__fYBtA:global(.__wab_instance) {
  position: relative;
}
.option___2Zffx:global(.__wab_instance) {
  position: relative;
}
.option__jzz1R:global(.__wab_instance) {
  position: relative;
}
.option__tc1Nz:global(.__wab_instance) {
  position: relative;
}
.option__hOyUe:global(.__wab_instance) {
  position: relative;
}
.option__lk8UD:global(.__wab_instance) {
  position: relative;
}
.option__shoAq:global(.__wab_instance) {
  position: relative;
}
.option__eyJq5:global(.__wab_instance) {
  position: relative;
}
.option___77W5K:global(.__wab_instance) {
  position: relative;
}
.option__djoLh:global(.__wab_instance) {
  position: relative;
}
.option__mOhTk:global(.__wab_instance) {
  position: relative;
}
.option__jhTzt:global(.__wab_instance) {
  position: relative;
}
.option__gvyqQ:global(.__wab_instance) {
  position: relative;
}
.option__fcjg1:global(.__wab_instance) {
  position: relative;
}
.option__wg6Cr:global(.__wab_instance) {
  position: relative;
}
.option__fBytv:global(.__wab_instance) {
  position: relative;
}
.option__gTtWc:global(.__wab_instance) {
  position: relative;
}
.option__s3It:global(.__wab_instance) {
  position: relative;
}
.option__lubk:global(.__wab_instance) {
  position: relative;
}
.stateError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .stateError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.zipError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .zipError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__vhFc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__pLvfg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.dobError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .dobError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__thrds {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__masZa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.selectIndustry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  margin-left: 0px;
  flex-shrink: 0;
}
.option__h9K2L:global(.__wab_instance) {
  position: relative;
}
.option__qDr8J:global(.__wab_instance) {
  position: relative;
}
.option___2Godq:global(.__wab_instance) {
  position: relative;
}
.option__wnOp2:global(.__wab_instance) {
  position: relative;
}
.option__ov2Zb:global(.__wab_instance) {
  position: relative;
}
.option__j0H4D:global(.__wab_instance) {
  position: relative;
}
.option__dqEmE:global(.__wab_instance) {
  position: relative;
}
.option__lpmVn:global(.__wab_instance) {
  position: relative;
}
.option__dgNco:global(.__wab_instance) {
  position: relative;
}
.option__jag11:global(.__wab_instance) {
  position: relative;
}
.option__kp8Ou:global(.__wab_instance) {
  position: relative;
}
.option___2IGtZ:global(.__wab_instance) {
  position: relative;
}
.option__nljtS:global(.__wab_instance) {
  position: relative;
}
.option__sy7L0:global(.__wab_instance) {
  position: relative;
}
.option__g5Rq0:global(.__wab_instance) {
  position: relative;
}
.option__kjBxq:global(.__wab_instance) {
  position: relative;
}
.option__z3DU4:global(.__wab_instance) {
  position: relative;
}
.option__esoBx:global(.__wab_instance) {
  position: relative;
}
.option___6G6Tx:global(.__wab_instance) {
  position: relative;
}
.option__f5BvG:global(.__wab_instance) {
  position: relative;
}
.option__iwMpj:global(.__wab_instance) {
  position: relative;
}
.option__z9D3:global(.__wab_instance) {
  position: relative;
}
.option___4JK52:global(.__wab_instance) {
  position: relative;
}
.option__uenDe:global(.__wab_instance) {
  position: relative;
}
.option___0FqAl:global(.__wab_instance) {
  position: relative;
}
.industryError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .industryError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__h09JF {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__cfg9P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.phoneNumber {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.phoneError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .phoneError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__qiqaR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__jgT6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.ssnError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ssnError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__sjoAn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___9KovV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.driversLicense {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox___6Qep {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__rQ9A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.driverState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 0px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__cdmwo:global(.__wab_instance) {
  position: relative;
}
.option__yOimY:global(.__wab_instance) {
  position: relative;
}
.option___8GWhd:global(.__wab_instance) {
  position: relative;
}
.option__ssLhS:global(.__wab_instance) {
  position: relative;
}
.option__cTe5G:global(.__wab_instance) {
  position: relative;
}
.option__d9OeY:global(.__wab_instance) {
  position: relative;
}
.option__yl5G9:global(.__wab_instance) {
  position: relative;
}
.option___3Mbhg:global(.__wab_instance) {
  position: relative;
}
.option__l0DIg:global(.__wab_instance) {
  position: relative;
}
.option__bnIg:global(.__wab_instance) {
  position: relative;
}
.option__kybVe:global(.__wab_instance) {
  position: relative;
}
.option__f44MG:global(.__wab_instance) {
  position: relative;
}
.option__yp5Lk:global(.__wab_instance) {
  position: relative;
}
.option__dSpoM:global(.__wab_instance) {
  position: relative;
}
.option__zp6Q3:global(.__wab_instance) {
  position: relative;
}
.option__sUwAw:global(.__wab_instance) {
  position: relative;
}
.option__rAfsY:global(.__wab_instance) {
  position: relative;
}
.option__sgg9Z:global(.__wab_instance) {
  position: relative;
}
.option__hhvHr:global(.__wab_instance) {
  position: relative;
}
.option__kTfY6:global(.__wab_instance) {
  position: relative;
}
.option__iksAr:global(.__wab_instance) {
  position: relative;
}
.option__mxZze:global(.__wab_instance) {
  position: relative;
}
.option__jU3Ff:global(.__wab_instance) {
  position: relative;
}
.option__lyWjj:global(.__wab_instance) {
  position: relative;
}
.option__orYmE:global(.__wab_instance) {
  position: relative;
}
.option__fZm2Y:global(.__wab_instance) {
  position: relative;
}
.option__ryvP9:global(.__wab_instance) {
  position: relative;
}
.option__jpaOg:global(.__wab_instance) {
  position: relative;
}
.option__khy7U:global(.__wab_instance) {
  position: relative;
}
.option__es4Zg:global(.__wab_instance) {
  position: relative;
}
.option__qjEad:global(.__wab_instance) {
  position: relative;
}
.option__qCE:global(.__wab_instance) {
  position: relative;
}
.option___4XUve:global(.__wab_instance) {
  position: relative;
}
.option__kG8Zo:global(.__wab_instance) {
  position: relative;
}
.option__qa69I:global(.__wab_instance) {
  position: relative;
}
.option__x7AkI:global(.__wab_instance) {
  position: relative;
}
.option__ncio3:global(.__wab_instance) {
  position: relative;
}
.option__w1Hqm:global(.__wab_instance) {
  position: relative;
}
.option__mxqgz:global(.__wab_instance) {
  position: relative;
}
.option__oNa6Y:global(.__wab_instance) {
  position: relative;
}
.option__ohaX2:global(.__wab_instance) {
  position: relative;
}
.option__b3YBy:global(.__wab_instance) {
  position: relative;
}
.option__sFGr:global(.__wab_instance) {
  position: relative;
}
.option___2ZfX:global(.__wab_instance) {
  position: relative;
}
.option__i67KE:global(.__wab_instance) {
  position: relative;
}
.option__rVda:global(.__wab_instance) {
  position: relative;
}
.option__cwqn3:global(.__wab_instance) {
  position: relative;
}
.option__dadAm:global(.__wab_instance) {
  position: relative;
}
.option___0XE4M:global(.__wab_instance) {
  position: relative;
}
.option___4Xng:global(.__wab_instance) {
  position: relative;
}
.option__iG6Ja:global(.__wab_instance) {
  position: relative;
}
.option__mIrH2:global(.__wab_instance) {
  position: relative;
}
.option___9I9M:global(.__wab_instance) {
  position: relative;
}
.option__w9Jr:global(.__wab_instance) {
  position: relative;
}
.option___16N1X:global(.__wab_instance) {
  position: relative;
}
.option__p2YuS:global(.__wab_instance) {
  position: relative;
}
.freeBox__d9KC {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__y6Pva {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.monthlyVolume {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.volumeError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .volumeError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__ftoOc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__j4WOn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.averageTicket {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.averageError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .averageError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__ex51Z {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__vXhGl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.highestTicket {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.highestEstimationError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .highestEstimationError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.ssnlengtherror {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ssnlengtherror {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.ageError {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ageError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.continueButton:global(.__wab_instance) {
  position: relative;
  margin-top: 51px;
  margin-bottom: 60px;
  flex-shrink: 0;
}
