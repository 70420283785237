.paymentCardContainer {
  background-color: #f5f6ff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paymentCardPreviewContainer {
  height: 90vh !important;
}

.subContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  max-width: 588px;
  width: 100%;
  border-radius: 12px;
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.paymentCardHead {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 19px;
}

.companyName {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.paymentCardCategory {
  color: var(--gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.paymentAmount {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
}

.paymentAmountHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentAmountHeadingText {
  color: var(--gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.downloadContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.downloadText {
  color: #3555ff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.paymentCardDivider {
  height: 1px;
  background: var(--gray-200, #eaecf0);
  margin: 0px;
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 4px 0px;
}

.summaryText {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.summaryMain {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.summarySectionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summarySubTextHead {
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.summarySubTextValue {
  color: var(--gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.card-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.cardContainer {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  max-width: 265px;
  min-height: 164px;
}

.cardSubcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.cardImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 32px;
  height: 32px;
  border-radius: 28px;
  border: 4px solid #0a31ff;
  background: #0a31ff;
  mix-blend-mode: multiply;
}

.cardDescription {
  color: #666b85;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.cardTitle {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.selectedCardTitleColor {
  color: #0022d6;
}

. input[type="checkbox" i]:focus-visible {
  outline-offset: 0px !important;
}

input[type="checkbox"] {
  accent-color: #0022d6;
  mix-blend-mode: multiply;
}

.checkboxView {
  width: 16px;
  height: 16px;
  border-radius: 25px;
  cursor: pointer;
}

.selectedCard {
  border-radius: 12px;
  border: 2px solid #0022d6;
  background: #f5f6ff;
  cursor: default;
}

.unselectedCard {
  border-radius: 12px;
  border: 2px solid #eaecf0;
  background: #fff;
  cursor: pointer;
}

.payNow {
  display: flex;
  padding: 11.5px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #0022d6;
  background: #3555ff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 3.7px;
  transition: background-color 0.3s;
}

.payNow:hover {
  background: #3555ffe6;
}

.payNowText {
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.paymentCardFooter {
  margin-top: 70px;
  color: #101828;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}

.paymentCardPreviewFooter {
  margin-top: 30px;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.round label:after {
  border: 1.5px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 8px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #3555ff;
  border-color: #3555ff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.paymentBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.methodTitle {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.paymentSubTitle {
  color: #3555ff;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}
.paymentBoxSubContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.paymentBoxDescription {
  color: #666b85;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.paymentBoxBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.unselectedCard:hover {
  border: 2px solid #666b85;
}

.send-main {
  border-bottom: 1px solid #c4c5ce;
}

.sendContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10%;
  height: 110px;
}

.backButtonContainer {
  display: flex;
  align-items: center;
}

.goBack {
  width: auto;
  height: auto;
  max-width: 800px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 26px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.sendButton {
  border-radius: 100px;
  background: #3555ff;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;

  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  /* margin-left: 60%; */
  min-width: 150px;
  padding: 0px 21px 0px;
  height: 47px;
}

.sendButton:hover {
  background: #3555ffe6;
}

.pointer {
  cursor: pointer;
}

.logo-color-picker-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 8px 60px;
}

.logoText {
  font-size: 16px;
  font-weight: 600;
}

.logo-uploader {
  width: 20% !important;
}

.logo-uploader .ant-upload.ant-upload-select-picture-card {
  width: 70px !important;
  height: 70px !important;
}

.logo-uploader .ant-upload-list .ant-upload-list-picture-card-container {
  width: 70px !important;
  height: 70px !important;
}

.upload-container {
  display: flex;
  align-items: center;
}

.companyLogo {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  max-width: 48px;
  max-height: 48px;
}

.colorPickerInput {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 767px) {
  .paymentCardFooter {
    margin-top: 40px;
  }
  .paymentCardContainer {
    height: auto;
  }
  .sendContainer {
    flex-direction: column;
    align-items: baseline;
  }
  .payNow {
    margin-top: 25px;
  }
  .card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
