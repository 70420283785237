.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__lkr4Y {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__rDDtp {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox___5Ydhy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 1009px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox___0Tm04 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__aQvpq {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #000000;
  flex-shrink: 0;
}
.svg__i9GpH {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #000000;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__tdqgx {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__mhQoK {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__twEIe {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg___9CiBn {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox__xfDZa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__xfDZa > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__xfDZa > :global(.__wab_flex-container) > *,
.freeBox__xfDZa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xfDZa > :global(.__wab_flex-container) > picture > img,
.freeBox__xfDZa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text___486OF {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
@media (max-width: 1370px) {
  .text___486OF {
    margin-top: calc(57px + 5px) !important;
  }
}
.text__dmgaj {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__vgmr6 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__svgNy {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
  padding-bottom: 0px;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 14px;
  min-width: 0;
  padding: 0px;
}
.freeBox__u4YbR {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  padding: 0px;
}
.freeBox__isiBh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text__bjN9Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__yPkd1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
@media (max-width: 1370px) {
  .freeBox__yPkd1 {
    align-items: flex-start;
    justify-content: center;
  }
}
.text__v2TS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.text__uGfb {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 35px;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}
.selectBusinessType:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  margin-top: 14px;
  flex-shrink: 0;
}
.option__sAj4:global(.__wab_instance) {
  position: relative;
}
.text__y1B7Z {
  padding-bottom: 0px;
}
.option__v7Wja:global(.__wab_instance) {
  position: relative;
}
.text__xr2RX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 39px 0px 6px 4px;
}
.selectIndustry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  margin-left: 0px;
  flex-shrink: 0;
}
.option__h0SNg:global(.__wab_instance) {
  position: relative;
}
.option__cZ9:global(.__wab_instance) {
  position: relative;
}
.option__q4FkP:global(.__wab_instance) {
  position: relative;
}
.option__bwhXd:global(.__wab_instance) {
  position: relative;
}
.option__oMl26:global(.__wab_instance) {
  position: relative;
}
.option__v8Iw:global(.__wab_instance) {
  position: relative;
}
.option___39B8G:global(.__wab_instance) {
  position: relative;
}
.option__akvhW:global(.__wab_instance) {
  position: relative;
}
.option__eHwZn:global(.__wab_instance) {
  position: relative;
}
.option__e4Hv:global(.__wab_instance) {
  position: relative;
}
.option__hiqRx:global(.__wab_instance) {
  position: relative;
}
.option__fJ8J:global(.__wab_instance) {
  position: relative;
}
.option___9Uqx8:global(.__wab_instance) {
  position: relative;
}
.option__nWyEo:global(.__wab_instance) {
  position: relative;
}
.option___7Jewt:global(.__wab_instance) {
  position: relative;
}
.option__xkaGk:global(.__wab_instance) {
  position: relative;
}
.option__iteue:global(.__wab_instance) {
  position: relative;
}
.option__zNXk:global(.__wab_instance) {
  position: relative;
}
.option__rmlvg:global(.__wab_instance) {
  position: relative;
}
.option__mNzbH:global(.__wab_instance) {
  position: relative;
}
.option__i3Ih:global(.__wab_instance) {
  position: relative;
}
.option__kPUa:global(.__wab_instance) {
  position: relative;
}
.option___0SwTr:global(.__wab_instance) {
  position: relative;
}
.option__z1LrL:global(.__wab_instance) {
  position: relative;
}
.option__uwjK:global(.__wab_instance) {
  position: relative;
}
.freeBox___8YuSj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___9ENfr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.monthlyVolume {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.freeBox__tgzih {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___3UeIn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.averageTicket {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.freeBox__hd3VT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.freeBox__cxRs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.slotTargetWebsiteorsocial {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  line-height: 2;
}
.website {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.continueButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 51px;
  cursor: pointer;
  margin-bottom: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .continueButton:hover {
  background: #3555ffd4;
}
.root .continueButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__aM7Ez {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
