.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__gKy6M {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__anWt {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__c2E1 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 1009px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-YDIAfzfKi1OS);
}
.freeBox__sYlrA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__y50Fc {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #000000;
  flex-shrink: 0;
}
.svg__bI6Jd {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #000000;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__qi2Ug {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__ycQqp {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__ooGiE {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__jAboa {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox__dGvK8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__dGvK8 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__dGvK8 > :global(.__wab_flex-container) > *,
.freeBox__dGvK8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dGvK8 > :global(.__wab_flex-container) > picture > img,
.freeBox__dGvK8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__nxWcK {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
@media (max-width: 1370px) {
  .text__nxWcK {
    margin-top: calc(57px + 5px) !important;
  }
}
.text__wYku1 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___6Qb1J {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__oDMsg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__zo4B {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-left: 14px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__kDdNx {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 41px;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.selectCountry:global(.__wab_instance) {
  max-width: 100%;
  width: 291px;
  margin-top: 16px;
  height: 47px;
  flex-shrink: 0;
}
.option__z7Pa:global(.__wab_instance) {
  position: relative;
}
.text__oW6U {
  padding-bottom: 0px;
}
.option__htiWp:global(.__wab_instance) {
  position: relative;
}
.option__whBoM:global(.__wab_instance) {
  position: relative;
}
.text__povkT {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 35px;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.selectBusinessType:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 291px;
  height: 47px;
  margin-top: 16px;
  flex-shrink: 0;
}
.option__sLA1:global(.__wab_instance) {
  position: relative;
}
.text__lNu1 {
  padding-bottom: 0px;
}
.option__nTmX:global(.__wab_instance) {
  position: relative;
}
.businessStructText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 41px;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.selectBusinessStructure:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 291px;
  height: 47px;
  margin-top: 16px;
  flex-shrink: 0;
}
.option__in2Jn:global(.__wab_instance) {
  position: relative;
}
.text__g37Sd {
  padding-bottom: 0px;
}
.option__v0Mw:global(.__wab_instance) {
  position: relative;
}
.text__tVeO {
  padding-bottom: 0px;
}
.option__gLvuo:global(.__wab_instance) {
  position: relative;
}
.option__mdeO5:global(.__wab_instance) {
  position: relative;
}
.option__sMoh:global(.__wab_instance) {
  position: relative;
}
.continueButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 51px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .continueButton:hover {
  background: #3555ffd4;
}
.root .continueButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__nvwBt {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
