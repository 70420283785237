.rectangle {
  background: rgba(255, 255, 255, 1);
  width: 557px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
  padding-top: 40px;
  position: relative;
  height: 840px;
  transition-property: all;
  transition-duration: 1s;
  margin-bottom: 0px;
  overflow: auto;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-radius: 8px;
}
.leftSide {
  width: 419px;
  height: auto;
  max-width: 100%;
  font-size: 12px;
}
.h4 {
  width: 424px;
  white-space: pre-wrap;
  user-select: text;
}
.span {
  font-size: 14px;
  line-height: 0px;
  white-space: pre-wrap;
  width: 348px;
}
.text__dfqoj {
  position: relative;
  width: 415px;
  height: auto;
  max-width: 100%;
  margin-bottom: 8px;
}
.appId:global(.__wab_instance) {
  max-width: 100%;
  width: 420px;
  flex-grow: 0;
  height: 40px;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  display: none;
}
.svg__jJY7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1RA {
  position: relative;
  width: 415px;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: -25px;
}
.freeBox__dClnz {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  order: 0;
  left: auto;
  top: auto;
  flex-grow: 0;
  filter: blur(0px);
  flex-shrink: 1;
  min-width: 0;
  padding: 0px 8px 8px;
  margin: auto;
}
.freeBox__dClnz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
}
.freeBox__dClnz > :global(.__wab_flex-container) > *,
.freeBox__dClnz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dClnz > :global(.__wab_flex-container) > picture > img,
.freeBox__dClnz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
}
.apiKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 420px;
  height: 40px;
  margin-left: calc(22px + 5px) !important;
  align-self: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
}
.copyKey {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.freeBox__dtwTy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 447px;
  height: auto;
  max-width: 100%;
  padding: 8px 0px;
}
.forProduction {
  position: relative;
  width: 431px;
  height: auto;
  max-width: 100%;
  padding-left: 8px;
  margin-bottom: 4px;
}
.h5__qpDiR {
  font-size: 18px;
  margin-bottom: 8px;
}
.text__tcARa {
  position: relative;
  width: 431px;
  height: auto;
  max-width: 100%;
  padding-left: 8px;
  margin-bottom: 4px;
}
.clientKey:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 420px;
  height: 40px;
  flex-shrink: 0;
}
.svg__ptWK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6Gzs7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k94F6 {
  position: relative;
  width: 423px;
  height: auto;
  max-width: 100%;
  margin-top: 13px;
  margin-bottom: 4px;
  padding-left: 4px;
}
.webHookKey:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  width: 420px;
  flex-shrink: 0;
}
.svg___0P1L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bvUbk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__mfRmY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 447px;
  height: auto;
  max-width: 100%;
  padding: 12px 0px 8px;
}
.forDevelopment {
  position: relative;
  width: 431px;
  height: auto;
  max-width: 100%;
  padding-left: 8px;
  margin-bottom: 4px;
}
.h5___9GJsT {
  height: auto;
  font-size: 18px;
  margin-bottom: 8px;
}
.text__fH4Nb {
  position: relative;
  width: 431px;
  height: auto;
  max-width: 100%;
  padding-left: 8px;
  margin-bottom: 4px;
}
.devClientKey:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 420px;
  height: 40px;
  flex-shrink: 0;
}
.svg__t0Zud {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___32Vsz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__m89O8 {
  position: relative;
  width: 423px;
  height: auto;
  max-width: 100%;
  margin-top: 13px;
  margin-bottom: 4px;
  padding-left: 4px;
}
.devWebHookKey:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  width: 420px;
  flex-shrink: 0;
}
.svg__bgxWc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__p35Yk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__iH0N {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 450px;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  flex-wrap: nowrap;
  align-content: stretch;
  padding: 8px;
  margin: 29px 73px 20px 0px;
}
.regenerateApiKey:global(.__wab_instance) {
  max-width: 100%;
  width: 190px;
  flex-shrink: 0;
}
.svg__roVOy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__x2Ep {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.addKeys:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sUiF7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fcYxt {
  padding-bottom: 0px;
}
.svg__qA0V9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
